import React, { useEffect, useState, forwardRef, useRef } from 'react';
import {
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Input,
    Form,
    ListGroup,
    ListGroupItem,
    Alert
} from 'reactstrap';
import './NewVendorEdit.css';
import { connect } from 'react-redux';
import {
    fast360Selectors,
    fast360Operations,
    fast360Actions
} from '../../fast-360/store/index';
import {
    fast360MaintenanceOperations,
    fast360MaintenanceSelectors,
    fast360MaintenanceActions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { isEmpty } from 'lodash';

const CustomInput = forwardRef((props, ref) => {
    return <Input ref={ref} {...props} />;
})

const ClientBasicInfoTab = ({
    getPrimaryContactCCFNameList,
    getPrimaryContactCCLNameList,
    primaryContactCCFNameList,
    primaryContactCCLNameList,
    resetPrimaryContactCCFList,
    resetPrimaryContactCCLList,
    getZipList,
    resetZipList,
    zipList,
    userId,
    saveClient,
    clientInfo,
    saveNewContact,
    newContactInfo,
    getNewClient,
    loadNewClientInfo,
    resetNewContactAction,
    openEditClientScreen,
    clientEditItem,
}) => {
    const [clientNameText, setClientNameText] = useState('');
    const [dbaText, setDbaText] = useState('');
    const [primaryContactFName, setPrimaryContactFName] = useState('');
    const [primaryContactLName, setPrimaryContactLName] = useState('');
    const [showFirstNameSuggestions, setShowFirstNameSuggestions] = useState(false);
    const [showLastNameSuggestions, setShowLastNameSuggestions] = useState(false);
    const [primaryContactEmail1, setPrimaryContactEmail1] = useState('');
    const [primaryContactEmail2, setPrimaryContactEmail2] = useState('');
    const [suggestionContact, setSuggestionContact] = useState('');
    const [isSendDMEUpdates, setIsSendDMEUpdates] = useState(false);
    const [isDayBeforeContact, setIsDayBeforeContact] = useState(false);
    const [isConfirmReferralClaimant, setIsConfirmReferralClaimant] = useState(false);
    const [isPayorActive, setIsPayorActive] = useState(false);
    const [isClientActive, setIsClientActive] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [inActiveReason, setInActiveReason] = useState('');
    const [isContractedActive, setIsContractedActive] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState('');
    const [terminateDate, setTerminateDate] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [termsVal, setTermsVal] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeId, setZipCodeId] = useState('');
    const [remitZipCodeId, setRemitZipCodeId] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [streetText, setStreetText] = useState('');
    const [street2Text, setStreet2Text] = useState('');
    const [websiteText, setWebsiteText] = useState('');
    const [mainPhoneNumber, setMainPhoneNumber] = useState('');
    const [phoneExt, setPhoneExt] = useState('');
    const [altPhoneExt, setAltPhoneExt] = useState('');
    const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
    const [cellPhoneNumber, setCellPhoneNumber] = useState('');
    const [faxNumber, setFaxNumber] = useState('');
    const [remitStreetText, setRemitStreetText] = useState('');
    const [remitStreet2Text, setRemitStreet2Text] = useState('');
    const [remitZipCode, setRemitZipCode] = useState('');
    const [remitCity, setRemitCity] = useState('');
    const [remitCounty, setRemitCounty] = useState('');
    const [remitState, setRemitState] = useState('');
    const [activeField, setActiveField] = useState('');
    const [notesText, setNotesText] = useState('');
    const [alertText, setAlertText] = useState('');
    const [address, setAddress] = useState({});
    const [remitAddress, setRemitAddress] = useState({});
    const [sedgwickClientIdVal, setSedgwickClientIdVal] = useState('');
    const [gpCode, setGpCode] = useState('');
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const firstNameSuggestionRef = useRef(null);
    const lastNameSuggestionRef = useRef(null);
    const [clientId, setClientId] = useState(0);
    useEffect(() => { 
        if( !isEmpty(clientEditItem) && (clientEditItem != undefined)) {
            setClientNameText(clientEditItem.name ? clientEditItem.name :'');
            setDbaText(clientEditItem.dbaName ? clientEditItem.dbaName : '');
            setPrimaryContactFName(clientEditItem.primaryContact.people.first);
            setPrimaryContactLName(clientEditItem.primaryContact.people.last);
            setPrimaryContactEmail1(clientEditItem.primaryContact.people.email1 ? clientEditItem.primaryContact.people.email1 : '');
            setPrimaryContactEmail2(clientEditItem.primaryContact.people.email2 ? clientEditItem.primaryContact.people.email2 : '');
            setIsSendDMEUpdates(clientEditItem.dmeUpdates ? clientEditItem.dmeUpdates : false);
            setIsDayBeforeContact(clientEditItem.contactDBF ? clientEditItem.contactDBF : false);
            setIsConfirmReferralClaimant(clientEditItem.confirmWClaimant ? clientEditItem.confirmWClaimant : false);
            setIsPayorActive(clientEditItem.payor ? clientEditItem.payor : false);
            setIsClientActive(clientEditItem.client ? clientEditItem.client : true);
            setIsActive(clientEditItem.active ? clientEditItem.active : true);
            setInActiveReason(clientEditItem.inactiveReason ? clientEditItem.inactiveReason : '');
            setIsContractedActive(clientEditItem.contracted ? clientEditItem.contracted : false);
            setEffectiveDate(clientEditItem.beginDate ? clientEditItem.beginDate : '');
            setTerminateDate(clientEditItem.endDate ? clientEditItem.endDate : '');
            setPaymentType(clientEditItem.paymentType ? clientEditItem.paymentType : '');
            setTermsVal(clientEditItem.terms ? clientEditItem.terms : '');
            setZipCode(clientEditItem.address.zipCode.zip ? clientEditItem.address.zipCode.zip : '');
            setZipCodeId(clientEditItem.address.zipCodeId ? clientEditItem.address.zipCodeId : '');
            setCity(clientEditItem.address.zipCode.city ? clientEditItem.address.zipCode.city : '');
            setCounty(clientEditItem.address.zipCode.county ? clientEditItem.address.zipCode.county: '');
            setState(clientEditItem.address.zipCode.state ? clientEditItem.address.zipCode.state : '');
            setStreetText(clientEditItem.address.street1 ? clientEditItem.address.street1 : '');
            setStreet2Text(clientEditItem.address.street2 ? clientEditItem.address.street2 : '');
            setWebsiteText(clientEditItem.website ? clientEditItem.website : '' );
            setMainPhoneNumber(clientEditItem.phone ? formatPhoneNumber(clientEditItem.phone) : '');
            setPhoneExt(clientEditItem.phoneExt ? clientEditItem.phoneExt :'');
            setAlternatePhoneNumber(clientEditItem.altPhone ? formatPhoneNumber(clientEditItem.altPhone) : '');
            setAltPhoneExt(clientEditItem.altPhoneExt ? clientEditItem.altPhoneExt : '');
            setCellPhoneNumber(clientEditItem.cellPhone ? formatPhoneNumber(clientEditItem.cellPhone): '');
            setFaxNumber(clientEditItem.fax ? formatPhoneNumber(clientEditItem.fax) : '');
            setRemitStreetText(clientEditItem.remitAddress.street1 ? clientEditItem.remitAddress.street1 : '');
            setRemitStreet2Text(clientEditItem.remitAddress.street2 ? clientEditItem.remitAddress.street2 : '');
            setRemitCity(clientEditItem.remitAddress.zipCode.city ? clientEditItem.remitAddress.zipCode.city : '');
            setRemitCounty(clientEditItem.remitAddress.zipCode.county ? clientEditItem.remitAddress.zipCode.county : '');
            setRemitState(clientEditItem.remitAddress.zipCode.state ? clientEditItem.remitAddress.zipCode.state : '');
            setRemitZipCode(clientEditItem.remitAddress.zipCode.zip ? clientEditItem.remitAddress.zipCode.zip : '');
            setRemitZipCodeId(clientEditItem.remitAddress.zipCodeId ? clientEditItem.remitAddress.zipCodeId : '');
            setNotesText(clientEditItem.comments ? clientEditItem.comments : '');
            setAlertText(clientEditItem.alert ? clientEditItem.alert : '');
            setAddress(clientEditItem.address.zipCode ? clientEditItem.address.zipCode : {});
            setRemitAddress(clientEditItem.remitAddress.zipCode ? clientEditItem.remitAddress.zipCode : {});
            setSedgwickClientIdVal(clientEditItem.sedgwickClientId ? clientEditItem.sedgwickClientId : '');
            setGpCode(clientEditItem.gpCode ? clientEditItem.gpCode : '');
            setClientId(clientEditItem.id ? clientEditItem.id : 0);
        }
    }, [clientEditItem])

    useEffect(() => {
        if (!isEmpty(clientInfo) && clientId == 0) {
            const payload = {
                "functionName" : "getNewClient",
                "name" :clientNameText
            }
            getNewClient(payload);
            resetNewContactAction();
        }
    },[clientInfo])

    useEffect(() => {
        if (loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo.length > 0) {
            setClientId(parseInt(loadNewClientInfo[0].id))
            clientEditItem = loadNewClientInfo[0];
            resetNewContactAction();
        }

    }, [loadNewClientInfo])

    const handleClickOutside = (event) => {
        if (!showFirstNameSuggestions) {
            const isFirstNameRef = firstNameRef.current && firstNameRef.current instanceof HTMLElement;
            const isFirstNameSuggestionRef = firstNameSuggestionRef.current && firstNameSuggestionRef.current instanceof HTMLElement;
            if (isFirstNameRef && !isFirstNameRef.current.contains(event.target) &&
                isFirstNameSuggestionRef && !isFirstNameSuggestionRef.current.contains(event.target)) {
                setShowFirstNameSuggestions(false);
                resetPrimaryContactCCFList(); // Call the reset function here
            }
        }
        if (!showLastNameSuggestions) {
            const isLastNameRef = lastNameRef.current && lastNameRef.current instanceof HTMLElement;
            const isLastNameSuggestionRef = lastNameSuggestionRef.current && lastNameSuggestionRef.current instanceof HTMLElement;
            if (isLastNameRef && !isLastNameRef.current.contains(event.target) &&
                isLastNameSuggestionRef && !isLastNameSuggestionRef.current.contains(event.target)) {
                setShowLastNameSuggestions(false);
                resetPrimaryContactCCLList();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [showLastNameSuggestions, showFirstNameSuggestions]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const handleFieldChange = (e) => {
        const name = e.target.name;
        if (name === "clientName") {
            setClientNameText(e.target.value);
        } else if (name === 'dbaName') {
            setDbaText(e.target.value);
        } else if (name === 'inactiveReason') {
            setInActiveReason(e.target.value);
        } else if (name === 'terms') {
            setTermsVal(e.target.value);
        } else if (name === 'paymentType') {
            setPaymentType(e.target.value);
        } else if (name === 'sedgwickClientId') {
            setSedgwickClientIdVal(e.target.value);
        } else if (name === 'gpCode') {
            setGpCode(e.target.value);
        } else if (name === 'notes') {
            setNotesText(e.target.value);
        } else if (name === 'alert') {
            setAlertText(e.target.value);
        } else if (name === "website") {
            setWebsiteText(e.target.value);
        } else if (name === "mainPhone") {
            //const phNum = formatPhoneNumber(e.target.value);
            setMainPhoneNumber(e.target.value)
        } else if (name === 'phoneExt') {
            setPhoneExt(e.target.value)
        } else if (name === 'alternatePhone') {
            const altPhNum = formatPhoneNumber(e.target.value);
            setAlternatePhoneNumber(altPhNum)
        }  else if (name === 'altPhoneExt') {
            setAltPhoneExt(e.target.value)
        } else if (name === 'cellPhone') {
            const cellPhNum = formatPhoneNumber(e.target.value);
            setCellPhoneNumber(cellPhNum);
        } else if (name === 'fax') {
            const faxNum = formatPhoneNumber(e.target.value);
            setFaxNumber(faxNum);
        }
    }

    const handleEffectiveDateChange = (e) => {
        setEffectiveDate(e.target.value);
    };

    const handleTerminateDateChange = (e) => {
        setTerminateDate(e.target.value);
    }

    const toggleSendDMEUpdates = (e) => {
        setIsSendDMEUpdates(e.target.checked);
    }

    const toggleDayBeforeContact = (e) => {
        setIsDayBeforeContact(e.target.checked);
    }

    const toggleConfirmReferralClaimant = (e) => {
        setIsConfirmReferralClaimant(e.target.checked);
    }

    const togglePayor = (e) => {
        setIsPayorActive(e.target.checked);
    }

    const toggleClient = (e) => {
        setIsClientActive(e.target.checked);
    }

    const toggleReason = (e) => {
        setIsActive(e.target.checked);
    };

    const toggleContracted = (e) => {
        setIsContractedActive(e.target.checked);
    };

    const handleContactEmail1Change = (e) => {
        setPrimaryContactEmail1(e.target.value);
    }
    const handleContactEmail2Change = (e) => {
        setPrimaryContactEmail2(e.target.value);
    }

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        setPrimaryContactFName(value);
        if (value.length >= 2) {
            const params = {
                "name": value
            }
            getPrimaryContactCCFNameList(params);
            setShowFirstNameSuggestions(true);
        } else {
            setShowFirstNameSuggestions(false);
        }
    };

    const handleSuggestionFNameClick = (suggestion) => {
        setPrimaryContactFName(suggestion.chr_firstname);
        setPrimaryContactLName(suggestion.chr_lastname);
        setPrimaryContactEmail1(suggestion.chr_email1);
        setPrimaryContactEmail2(suggestion.chr_email2);
        setSuggestionContact(suggestion);
        resetPrimaryContactCCFList();
        setShowFirstNameSuggestions(false);
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        setPrimaryContactLName(value);
        if (value.length >= 2) {
            const params = {
                "name": value
            }
            getPrimaryContactCCLNameList(params);
            setShowLastNameSuggestions(true);
        } else {
            setShowLastNameSuggestions(false);
        }
    };

    const handleSuggestionLNameClick = (suggestion) => {
        setPrimaryContactFName(suggestion.chr_firstname);
        setPrimaryContactLName(suggestion.chr_lastname);
        setPrimaryContactEmail1(suggestion.chr_email1);
        setPrimaryContactEmail2(suggestion.chr_email2);
        setSuggestionContact(suggestion);
        resetPrimaryContactCCLList();
        setShowLastNameSuggestions(false);
    };

    const handleTypeAheadSearch = event => {
        const name = event.target.name;
        const value = event.target.value;
        event.persist();
        setActiveField(name);

        if (name === 'zipCode') {
            setZipCode(value);
            if (value.length > 3) {
                const params = {
                    userId: userId,
                    zipCode: value,
                };
                getZipList(params);
            } else if (value.length > 0 && zipList && zipList.length) {
                resetZipList();
            }
        } else if (name === 'remitZip') {
            setRemitZipCode(value);
            if (value.length > 3) {
                const params = {
                    userId: userId,
                    zipCode: value,
                };
                getZipList(params);
            } else if (value.length > 0 && zipList.length) {
                resetZipList();
            }
        }
    };

    const handleZipCodeClick = (zip) => {
        setZipCode(zip.chr_zipcode);
        setZipCodeId(zip.code_id)
        setCity(zip.chr_city);
        setCounty(zip.chr_county);
        setState(zip.chr_state);
        setAddress(zip);
        resetZipList();
    };

    const handleRemitZipCodeClick = (zip) => {
        setRemitZipCode(zip.chr_zipcode);
        setRemitZipCodeId(zip.code_id)
        setRemitCity(zip.chr_city);
        setRemitCounty(zip.chr_county);
        setRemitState(zip.chr_state);
        setRemitAddress(zip);
        resetZipList();
    };


    const handleStreetChange = (e) => {
        const name = e.target.name;
        if (name === 'remitStreet') {
            setRemitStreetText(e.target.value);
        } else if (name === 'street') {
            setStreetText(e.target.value);
        }
    }

    const handleStreet2Change = (e) => {
        const name = e.target.name;
        if (name === 'remitStreet2') {
            setRemitStreet2Text(e.target.value);
        } else if (name === 'street2') {
            setStreet2Text(e.target.value);
        }
    }

    const handleInputBlur = (fieldType) => {
        setTimeout(() => {
            if (fieldType === 'firstname') {
                setShowFirstNameSuggestions(false);
            } else if (fieldType === 'lastname') {
                setShowLastNameSuggestions(false);
            }
        }, 150)
    }

    const formatPhoneNumber = value => {
        let phoneNumber = value;
        let formattedNumber;
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        const areaCode = phoneNumber.substr(0, 3);
        const firstPart = phoneNumber.substr(3, 3);
        const secondPart = phoneNumber.substr(6, 4);
        if (areaCode.length === 0) {
            formattedNumber = '';
        } else if (areaCode.length < 3) {
            formattedNumber = '(' + areaCode;
        } else if (areaCode.length === 3 && firstPart.length < 3) {
            formattedNumber = '(' + areaCode + ') ' + firstPart;
        } else if (areaCode.length === 3 && firstPart.length === 3) {
            formattedNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
        }

        return formattedNumber;
    };

    const unformatPhoneNumber = formattedNumber => {
        return formattedNumber.replace(/[^0-9]/g, '');
    };

    useEffect(() => {
        if ((newContactInfo !== undefined || newContactInfo > 0 ) && clientId == 0) {
            let response = {
                    "parentId": null,
                    "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": newContactInfo,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": newContactInfo
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": 0
                    },
                    "primaryContactId": 0,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": [
                        {
                            "peopleId": newContactInfo,
                            //"vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": newContactInfo
                            },
                            "contactTypeId": 59,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": 0
                        }
                    ],
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": 0,
                    "contracted": isContractedActive,
                }
            if (clientNameText === "") {
                alert("You must enter a client name.");
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
            } else {
                const params = {
                    "zebraId": userId,
                    "functionName": "saveClient",
                    "clientId": 0,
                    "id": 0,
                    "profile": "",
                    "loadFees": true,
                    "client": JSON.parse(JSON.stringify(response)),
                }
              saveClient(params);
              console.log("params", params);
            }
         } else if ((newContactInfo !== undefined || newContactInfo > 0 ) && clientId > 0 && (openEditClientScreen === false)) {
            let responseFromSave =  {
                    "parentId": null,
                    "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": newContactInfo,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": newContactInfo
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": 0
                    },
                    "primaryContactId": 0,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                   "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": [
                        {
                            "peopleId": newContactInfo,
                            //"vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": newContactInfo
                            },
                            "contactTypeId": 59,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": 0
                        }
                    ],
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": clientId,
                    "contracted": isContractedActive,
                }

            if (clientNameText === "") {
                alert("You must enter a client name.");
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
            } else {
                const params = {
                    "zebraId": userId,
                    "functionName": "saveClient",
                    "clientId": clientId,
                    "id": clientId,
                    "profile": "",
                    "loadFees": true,
                    "client": JSON.parse(JSON.stringify(responseFromSave)),
                }
              saveClient(params);
            }
        } else if ((newContactInfo !== undefined || newContactInfo > 0 ) && clientId > 0 && (openEditClientScreen === true)) {
            const newContact =  {
                "peopleId": newContactInfo,
               // "vendorContactId": 0,
                "people": {
                    "first": primaryContactFName,
                    "last": primaryContactLName,
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "id": newContactInfo
                },
                "contactTypeId": 59,
                "active": true,
                "primary": true,
                "referralReceived": false,
                "qaAssignedConfirmed": false,
                "psAssignedConfirmed": false,
                "vosAssignedConfirmed": false,
                "orderCancelledBilled": false,
                "id": 0
            }
            const updatedContactList = clientEditItem.contactList.map(contact => ({
                ...contact,
                primary: false
            }));
            updatedContactList.push(newContact);
            clientEditItem.contactList = updatedContactList;
            let responseFromEdit = {
                    "parentId": null,
                    "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.timeZone || address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": clientEditItem.address.id
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.timeZone || remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": clientEditItem.remitAddress.id
                    },
                    "addressId": clientEditItem.addressId,
                    "remitAddressId": clientEditItem.remitAddressId,
                     "primaryContact": {
                        "peopleId": newContactInfo,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": newContactInfo
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": 0
                    },
                    "primaryContactId": 0,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                   "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": updatedContactList,
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": clientId,
                    "contracted": isContractedActive,
                }
                if (clientNameText === "") {
                    alert("You must enter a client name.");
                } else if (streetText === "" || zipCode === "") {
                    alert("You must enter a complete address.");
                } else if (primaryContactFName === "" || primaryContactLName === "") {
                    alert("You must enter the primary contact's first and last name.");
                } else if (primaryContactFName === "" || primaryContactLName === "") {
                    alert("You must enter the primary contact's first and last name.");
                } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                    alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
                } else {
                    const params = {
                        "zebraId": userId,
                        "functionName": "saveClient",
                        "clientId": clientId,
                        "id": clientId,
                        "profile": "",
                        "loadFees": true,
                        "client": JSON.parse(JSON.stringify(responseFromEdit)),
                    }
                  saveClient(params);
                  console.log("params", params);
                }
        }
    },[newContactInfo])

    const onSave = async () => {
        let client = {};
        if ((suggestionContact.chr_firstname === primaryContactFName) && (suggestionContact.chr_lastname === primaryContactLName) && clientId == 0) {
            client = {
                    "parentId": null,
                    "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": suggestionContact.people_id,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": suggestionContact.people_id
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": suggestionContact.bol_referral_received || false,
                        "qaAssignedConfirmed": suggestionContact.bol_qa_assignedconfirm || false,
                        "psAssignedConfirmed":suggestionContact.bol_ps_assignedconfirm || false,
                        "vosAssignedConfirmed": suggestionContact.bol_vos_assignedconfirm || false,
                        "orderCancelledBilled": suggestionContact.bol_order_cancelled_billed || false,
                        "id": suggestionContact.contact_id
                    },
                    "primaryContactId": suggestionContact.contact_id,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": [
                        {
                            "peopleId": suggestionContact.people_id,
                           // "vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": suggestionContact.people_id
                            },
                            "contactTypeId": 59,
                            "active": true,
                            "primary": true,
                            "referralReceived": suggestionContact.bol_referral_received || false,
                            "qaAssignedConfirmed": suggestionContact.bol_qa_assignedconfirm || false,
                            "psAssignedConfirmed":suggestionContact.bol_ps_assignedconfirm || false,
                            "vosAssignedConfirmed": suggestionContact.bol_vos_assignedconfirm || false,
                            "orderCancelledBilled": suggestionContact.bol_order_cancelled_billed || false,
                            "id": suggestionContact.contact_id,
                            "contactType": suggestionContact.chr_contacttype,
                            "notificationMethod": suggestionContact.chr_notification_method,

                        }
                    ],
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": 0,
                    "contracted": isContractedActive,
                }
            if (clientNameText === "") {
                alert("You must enter a client name.");
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
            } else {
                const params = {
                    "zebraId": userId,
                    "functionName": "saveClient",
                    "clientId": 0,
                    "id": 0,
                    "profile": "",
                    "loadFees": true,
                    "client": JSON.parse(JSON.stringify(client)),
                }
              saveClient(params);
              console.log("params", params);
            }
        } else if((suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName) &&
        loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo.length > 0 &&
        (primaryContactFName == loadNewClientInfo[0].primaryContact.people.first) &&  (primaryContactLName == loadNewClientInfo[0].primaryContact.people.last) && (clientId > 0)) {
            const peopleObj = loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.people;
            client = {
                    "parentId": null,
                    "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.peopleId,
                        "people": {
                            "first": peopleObj.first,
                            "last": peopleObj.last,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.peopleId
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.id
                    },
                    "primaryContactId": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContactId,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": [
                        {
                            "peopleId": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.peopleId,
                           // "vendorContactId": 0,
                           "people": {
                            "first": peopleObj.first,
                            "last": peopleObj.last,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.peopleId
                        },
                            "contactTypeId": 59,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo[0].primaryContact.id
                        }
                    ],
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": clientId,
                    "contracted": isContractedActive,
                }
            
                if (clientNameText === "") {
                    alert("You must enter a client name.");
                } else if (streetText === "" || zipCode === "") {
                    alert("You must enter a complete address.");
                } else if (primaryContactFName === "" || primaryContactLName === "") {
                    alert("You must enter the primary contact's first and last name.");
                } else if (primaryContactFName === "" || primaryContactLName === "") {
                    alert("You must enter the primary contact's first and last name.");
                } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                    alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
                } else {

                const params = {
                    "zebraId": userId,
                    "functionName": "saveClient",
                    "clientId": clientId,
                    "id": clientId,
                    "profile": "",
                    "loadFees": true,
                    "client": JSON.parse(JSON.stringify(client)),
                }
              saveClient(params);
            }
        } else if ((openEditClientScreen === false ) && clientId > 0 && (suggestionContact.chr_firstname === primaryContactFName) && 
        (suggestionContact.chr_lastname === primaryContactLName)) {
            client = {
                    "parentId": null,
                    "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": suggestionContact.people_id,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": suggestionContact.people_id
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": suggestionContact.contact_id
                    },
                    "primaryContactId": suggestionContact.contact_id,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": [
                        {
                            "peopleId": suggestionContact.people_id,
                           // "vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": suggestionContact.people_id
                            },
                            "contactTypeId": 59,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": suggestionContact.contact_id
                        }
                    ],
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": clientId,
                    "contracted": isContractedActive,
                }
            if (clientNameText === "") {
                alert("You must enter a client name.");
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
            } else {
            const params = {
                "zebraId": userId,
                "functionName": "saveClient",
                "clientId": clientId,
                "id": clientId,
                "profile": "",
                "loadFees": true,
                "client": JSON.parse(JSON.stringify(client)),
            }
          saveClient(params);
        }
        } else if ( (openEditClientScreen === false ) && clientId > 0 && (suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName)) {
            if (clientNameText === "") {
                alert("You must enter a client name.");
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
            } else {
            let contactResponse = {}
            contactResponse.active = isActive;
            contactResponse.contactTypeId = 0;
            contactResponse.peopleId = 0;
            contactResponse.id = 0;
            contactResponse.functionName = 'saveNewContact';
            contactResponse.zebraUserId = userId;
            contactResponse = {
                first: primaryContactFName,
                last: primaryContactLName,
                email1: primaryContactEmail1,
                email2: primaryContactEmail2,
                phone: unformatPhoneNumber(mainPhoneNumber),
                phoneExt: phoneExt,
                fax: unformatPhoneNumber(faxNumber),
                id: 0,
                cellPhone: unformatPhoneNumber(cellPhoneNumber),
            }
            saveNewContact(contactResponse);
        }
            } else if((suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName) && clientId == 0) {
            let contactResponse = {}
            contactResponse.active = isActive;
            contactResponse.contactTypeId = 0;
            contactResponse.peopleId = 0;
            contactResponse.id = 0;
            contactResponse.functionName = 'saveNewContact';
            contactResponse.zebraUserId = userId;
            contactResponse = {
                first: primaryContactFName,
                last: primaryContactLName,
                email1: primaryContactEmail1,
                email2: primaryContactEmail2,
                phone: unformatPhoneNumber(mainPhoneNumber),
                phoneExt: phoneExt,
                fax: unformatPhoneNumber(faxNumber),
                id: 0,
                cellPhone: unformatPhoneNumber(cellPhoneNumber),
            }
            saveNewContact(contactResponse);
        }  else if((primaryContactFName == clientEditItem.primaryContact.people.first) &&
        (primaryContactLName == clientEditItem.primaryContact.people.last) && 
        clientId > 0 && (openEditClientScreen === true )) {
            client = {
                "parentId": null,
                 "address": {
                        "zipCodeId": zipCodeId,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.timeZone || address.chr_timezone,
                            "id": zipCodeId
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": clientEditItem.address.id || 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitZipCodeId,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.timeZone || remitAddress.chr_timezone,
                            "id": remitZipCodeId
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": clientEditItem.remitAddress.id || 0
                    },
                    "addressId": clientEditItem.addressId || 0,
                    "remitAddressId": clientEditItem.remitAddressId || 0,
                    "primaryContact": {
                        "peopleId": clientEditItem && clientEditItem.primaryContact && clientEditItem.primaryContact.peopleId,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": clientEditItem && clientEditItem.primaryContact && clientEditItem.primaryContact.peopleId
                        },
                        "contactTypeId": 59,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": clientEditItem && clientEditItem.primaryContactId
                    },
                    "primaryContactId": clientEditItem && clientEditItem.primaryContactId,
                    "level": 0,
                    "name": clientNameText,
                    "dbaName": dbaText,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "altPhoneExt": altPhoneExt,
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellphone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": primaryContactEmail1,
                    "email2": primaryContactEmail2,
                    "website": websiteText,
                    "active": isActive,
                    "client": isClientActive,
                    "payor": isPayorActive,
                    "inactiveReason": inActiveReason,
                    "comments": notesText,
                    "contactDBF": isDayBeforeContact,
                    "confirmWClaimant": isConfirmReferralClaimant,
                    "dmeUpdates": isSendDMEUpdates,
                    "sedgwickClientId": sedgwickClientIdVal,
                    "contactList": clientEditItem.contactList,
                    "serviceList": [],
                    "terms": termsVal,
                    "paymentType": paymentType,
                    "gpCode": gpCode,
                    "hasChildren": false,
                    "feeCount": 0,
                    "beginDate": effectiveDate,
                    "endDate": terminateDate,
                    "id": clientId,
                    "contracted": isContractedActive,
                }
                if (clientNameText === "") {
                    alert("You must enter a client name.");
                } else if (streetText === "" || zipCode === "") {
                    alert("You must enter a complete address.");
                } else if (primaryContactFName === "" || primaryContactLName === "") {
                    alert("You must enter the primary contact's first and last name.");
                } else if (primaryContactFName === "" || primaryContactLName === "") {
                    alert("You must enter the primary contact's first and last name.");
                } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                    alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
                } else {
                const params = {
                "zebraId": userId,
                "functionName": "saveClient",
                "clientId": clientId,
                "id": clientId,
                "profile": "",
                "loadFees": true,
                "client": JSON.parse(JSON.stringify(client)),
            }
          saveClient(params);
        }
    }   else if((suggestionContact.chr_firstname === primaryContactFName) && (suggestionContact.chr_lastname === primaryContactLName) &&
    clientId > 0 && (openEditClientScreen === true )) {
        const newContact = {
            "peopleId": suggestionContact.people_id,
            "people": {
                "first": primaryContactFName,
                "last": primaryContactLName,
                "email1": primaryContactEmail1,
                "email2": primaryContactEmail2,
                "id": suggestionContact.people_id
            },
            "contactTypeId": 59,
            "active": true,
            "primary": true,
            "referralReceived": false,
            "qaAssignedConfirmed": false,
            "psAssignedConfirmed": false,
            "vosAssignedConfirmed": false,
            "orderCancelledBilled": false,
            "id": suggestionContact.contact_id
        }
        const updatedContactList = clientEditItem.contactList.map(contact => ({
            ...contact,
            primary: false
        }));
        updatedContactList.push(newContact);
        clientEditItem.contactList = updatedContactList;
        client = {
                "parentId": null,
                "address": {
                    "zipCodeId": zipCodeId,
                    "zipCode": {
                        "city": city,
                        "state": state,
                        "zip": zipCode,
                        "county": county,
                        "timeZone": address.timeZone || address.chr_timezone,
                        "id": zipCodeId
                    },
                    "street1": streetText,
                    "street2": street2Text,
                    "active": false,
                    "permAddress": false,
                    "id": clientEditItem.address.id
                },
                "remitAddress": {
                    "zipCodeId": remitZipCodeId,
                    "zipCode": {
                        "city": remitCity,
                        "state": remitState,
                        "zip": remitZipCode,
                        "county": remitCounty,
                        "timeZone": remitAddress.timeZone || remitAddress.chr_timezone,
                        "id": remitZipCodeId
                    },
                    "street1": remitStreetText,
                    "street2": remitStreet2Text,
                    "active": false,
                    "permAddress": false,
                    "id": clientEditItem.remitAddress.id
                },
                "addressId": clientEditItem.addressId,
                "remitAddressId": clientEditItem.remitAddressId,
                "primaryContact": {
                    "peopleId": suggestionContact.people_id,
                    "people": {
                        "first": primaryContactFName,
                        "last": primaryContactLName,
                        "email1": primaryContactEmail1,
                        "email2": primaryContactEmail2,
                        "id": suggestionContact.people_id
                    },
                    "contactTypeId": 59,
                    "active": true,
                    "primary": false,
                    "referralReceived": false,
                    "qaAssignedConfirmed": false,
                    "psAssignedConfirmed": false,
                    "vosAssignedConfirmed": false,
                    "orderCancelledBilled": false,
                    "id": suggestionContact.contact_id
                },
                "primaryContactId": suggestionContact.contact_id,
                "level": 0,
                "name": clientNameText,
                "dbaName": dbaText,
                "alert": alertText,
                "phone": unformatPhoneNumber(mainPhoneNumber),
                "phoneExt": phoneExt,
                "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                "altPhoneExt": altPhoneExt,
                "fax": unformatPhoneNumber(faxNumber),
                "cellphone": unformatPhoneNumber(cellPhoneNumber),
                "email1": primaryContactEmail1,
                "email2": primaryContactEmail2,
                "website": websiteText,
                "active": isActive,
                "client": isClientActive,
                "payor": isPayorActive,
                "inactiveReason": inActiveReason,
                "comments": notesText,
                "contactDBF": isDayBeforeContact,
                "confirmWClaimant": isConfirmReferralClaimant,
                "dmeUpdates": isSendDMEUpdates,
                "sedgwickClientId": sedgwickClientIdVal,
                "contactList": updatedContactList,
                "serviceList": [],
                "terms": termsVal,
                "paymentType": paymentType,
                "gpCode": gpCode,
                "hasChildren": false,
                "feeCount": 0,
                "beginDate": effectiveDate,
                "endDate": terminateDate,
                "id": clientId,
                "contracted": isContractedActive,
            }
            if (clientNameText === "") {
                alert("You must enter a client name.");
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
                alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
            } else {
            const params = {
                "zebraId": userId,
                "functionName": "saveClient",
                "clientId": clientId,
                "id": clientId,
                "profile": "",
                "loadFees": true,
                "client": JSON.parse(JSON.stringify(client)),
            }
          saveClient(params);
        }
    }   else if((suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName) &&
    clientId > 0 && (openEditClientScreen === true )  && (primaryContactFName !== clientEditItem.primaryContact.people.first) ||
    (primaryContactLName !== clientEditItem.primaryContact.people.last)){
        if (clientNameText === "") {
            alert("You must enter a client name.");
        } else if (streetText === "" || zipCode === "") {
            alert("You must enter a complete address.");
        } else if (primaryContactFName === "" || primaryContactLName === "") {
            alert("You must enter the primary contact's first and last name.");
        } else if (primaryContactFName === "" || primaryContactLName === "") {
            alert("You must enter the primary contact's first and last name.");
        } else if (sedgwickClientIdVal !== '' && sedgwickClientIdVal.length > 4) {
            alert("Sedgwick client id it must be 4 digit numerical format only. No special characters or dashes are allowed");
        } else {
            let contactResponse = {}
            contactResponse.active = isActive;
            contactResponse.contactTypeId = 0;
            contactResponse.peopleId = 0;
            contactResponse.id = 0;
            contactResponse.functionName = 'saveNewContact';
            contactResponse.zebraUserId = userId;
            contactResponse = {
                first: primaryContactFName,
                last: primaryContactLName,
                email1: primaryContactEmail1,
                email2: primaryContactEmail2,
                phone: unformatPhoneNumber(mainPhoneNumber),
                phoneExt: phoneExt,
                fax: unformatPhoneNumber(faxNumber),
                id: 0,
                cellPhone: unformatPhoneNumber(cellPhoneNumber),
            }
            saveNewContact(contactResponse);
    }
    }
}


    return (
        <div>
            <Form className="vendor-form" onKeyPress={handleKeyPress}>
                <div className="form-section">
                    <Row form className="align-items-center">
                        <Col md={6}>
                            <h5>CLIENT DEMOGRAPHIC</h5>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button color="primary" className="save-button" onClick={onSave}>Save</Button>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="clientName">Client Name <span className='red-text'>*</span></Label>
                                <Input type="text" name="clientName" id="clientName" style={{ border: "1px solid red" }} value={clientNameText || ''}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="dbaName">DBA Name</Label>
                                <Input type="text" name="dbaName" id="dbaName" value={dbaText || ''}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup className="form-group">
                                <Label for="primaryContactFirstName">Primary Contact First Name <span className='red-text'>*</span></Label>
                                <CustomInput
                                    type="text"
                                    name="primaryContactFirstName"
                                    id="primaryContactFirstName"
                                    value={primaryContactFName || ''}
                                    onChange={handleFirstNameChange}
                                    style={{ border: "1px solid red" }}
                                    onFocus={() => setShowFirstNameSuggestions(true)}
                                    onBlur={() => handleInputBlur('firstname')}
                                    autoComplete="new-password"
                                    ref={firstNameRef}
                                />
                                {showFirstNameSuggestions && primaryContactCCFNameList && primaryContactCCFNameList.length > 0 && (
                                    <ListGroup className='suggestion-list' ref={firstNameSuggestionRef}>
                                        {primaryContactCCFNameList.map((suggestion, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onMouseDown={() => handleSuggestionFNameClick(suggestion)}
                                            >
                                                {suggestion.chr_firstname} {suggestion.chr_lastname}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup className="form-group">
                                <Label for="primaryContactLastName">Primary Contact Last Name <span className='red-text'>*</span></Label>
                                <CustomInput type="text"
                                    name="primaryContactLastName"
                                    id="primaryContactLastName"
                                    onChange={handleLastNameChange}
                                    value={primaryContactLName || ''}
                                    style={{ border: "1px solid red" }}
                                    onFocus={() => setShowLastNameSuggestions(true)}
                                    onBlur={() => handleInputBlur('lastname')}
                                    autoComplete="new-password"
                                    ref={lastNameRef}
                                />
                                {showLastNameSuggestions && primaryContactCCLNameList && primaryContactCCLNameList.length > 0 && (
                                    <ListGroup className='suggestion-list' ref={lastNameSuggestionRef}>
                                        {primaryContactCCLNameList.map((suggestion, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onMouseDown={() => handleSuggestionLNameClick(suggestion)}
                                            >
                                                {suggestion.chr_firstname} {suggestion.chr_lastname}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup check style={{ whiteSpace: 'nowrap' }}>
                                <Label check>
                                    <Input type="checkbox" name="sendDMEUpdates" checked={isSendDMEUpdates || false} onClick={toggleSendDMEUpdates} />
                                    Send DME Updates</Label>
                            </FormGroup>
                        </Col>
                        <Col md={2} style={{ marginLeft: "-100px" }}>
                            <FormGroup check style={{ whiteSpace: 'nowrap' }}>
                                <Label check>
                                    <Input type="checkbox" name="dayBeforeContact" checked={isDayBeforeContact || false} onClick={toggleDayBeforeContact} />
                                    Day Before Facilitates Contact</Label>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check style={{ whiteSpace: 'nowrap' }}>
                                <Label check>
                                    <Input type="checkbox" name="confirmClaimant" checked={isConfirmReferralClaimant || false} onClick={toggleConfirmReferralClaimant} />
                                    Confirm Referral with Claimant</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="street">Street <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="street"
                                    id="street"
                                    value={streetText || ''}
                                    onChange={handleStreetChange}
                                    style={{ border: "1px solid red" }} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="street2">Street 2</Label>
                                <Input
                                    type="text"
                                    name="street2"
                                    id="street2"
                                    value={street2Text || ''}
                                    onChange={handleStreet2Change} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="city">City <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={city || ''}
                                    style={{ border: "1px solid red" }}
                                    readOnly />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="state">St</Label>
                                <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    value={state || ''}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="zipCode">Zip Code <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    value={zipCode || ''}
                                    onChange={handleTypeAheadSearch}
                                    style={{ border: "1px solid red" }}
                                />
                                {activeField === 'zipCode' && zipCode.length > 3 && zipList && zipList.length > 0 && (
                                    <ListGroup className='suggestion-list'>
                                        {zipList.map((zip, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onClick={() => handleZipCodeClick(zip)}
                                            >
                                                {zip.zip_type_with_county}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="county">County <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="county"
                                    id="county"
                                    value={county || ''}
                                    style={{ border: "1px solid red" }}
                                    readOnly />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="payor" checked={isPayorActive || false} onClick={togglePayor} />
                                    Payor</Label>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="client" checked={isClientActive || true} onClick={toggleClient} />
                                    Client</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="contactEmail">Contact Email</Label>
                                <Input
                                    type="email"
                                    name="contactEmail"
                                    id="email1"
                                    value={primaryContactEmail1 || ''}
                                    onChange={handleContactEmail1Change}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="alternateContactEmail">Alternate Contact Email</Label>
                                <Input
                                    type="email"
                                    name="alternateContactEmail"
                                    id="email2"
                                    value={primaryContactEmail2 || ''}
                                    onChange={handleContactEmail2Change}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="website">Website</Label>
                                <Input type="text" name="website" id="website" value={websiteText || ''}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="mainPhone">Main Phone</Label>
                                <Input
                                    type="tel"
                                    name="mainPhone"
                                    id="mainPhone"
                                    value={formatPhoneNumber(mainPhoneNumber) || ''}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="phoneExt">Phone Ext.</Label>
                                <Input
                                    type="text"
                                    name="phoneExt"
                                    id="phoneExt"
                                    maxLength={10}
                                    value={phoneExt || ''}
                                    onChange={handleFieldChange}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="alternatePhone">Alternate Phone</Label>
                                <Input
                                    type="text"
                                    name="alternatePhone"
                                    id="alternatePhone"
                                    value={alternatePhoneNumber || ''}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                                    autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="altPhoneExt">Phone Ext.</Label>
                                <Input
                                    type="text"
                                    name="altPhoneExt"
                                    id="altPhoneExt"
                                    maxLength={10}
                                    value={altPhoneExt || ''}
                                    onChange={handleFieldChange}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="cellPhone">Cell Phone</Label>
                                <Input
                                    type="text"
                                    name="cellPhone"
                                    id="cellPhone"
                                    value={cellPhoneNumber || ''}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                                    autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="fax">Fax</Label>
                                <Input type="text" name="fax" id="fax"
                                    value={faxNumber || ''}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                                    autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {/* Vendor Status Section */}
                <div className="form-section">
                    <h5>CLIENT STATUS</h5>
                    <Row form>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="active" id="active" checked={isActive || ''} onClick={toggleReason} />
                                    Active
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="inactiveReason">Inactive Reason</Label>
                                <Input  type="select" name="inactiveReason" id="inactiveReason"
                                    value={inActiveReason || ''}
                                    onChange={handleFieldChange}
                                    disabled={isActive} autoComplete="new-password" >
                                    <option value='' disabled hidden>Choose</option>
                                    <option value="Out of Business">Out of Business</option>
                                    <option value="Non-payment">Non-payment</option>
                                    <option value="Account in Collection">Account in Collection</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="contracted" checked={isContractedActive || ''} onClick={toggleContracted} />
                                    contracted</Label>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="effectiveDate">Effective Date</Label>
                                <Input
                                    type="date"
                                    name="effectiveDate"
                                    id="effectiveDate"
                                    value={effectiveDate || ''}
                                    onChange={handleEffectiveDateChange}
                                    disabled={!isContractedActive} />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="terminationDate">Termination Date</Label>
                                <Input
                                    type="date"
                                    name="terminationDate"
                                    id="terminationDate"
                                    value={terminateDate || ''}
                                    onChange={handleTerminateDateChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="terms">Terms</Label>
                                <Input type="select" name="terms" id="terms" value={termsVal || ''}
                                    onChange={handleFieldChange}>
                                    <option value='' disabled hidden>Choose</option>
                                    <option value="21 MFI">21 MFI</option>
                                    <option value="EOM">EOM</option>
                                    <option value="Net 10">Net 10</option>
                                    <option value="Net 15">Net 15</option>
                                    <option value="Net 30">Net 30</option>
                                    <option value="Net 45">Net 45</option>
                                    <option value="Net 60">Net 60</option>
                                    <option value="Net 90">Net 90</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="paymentType">Payment Type</Label>
                                <Input type="select" name="paymentType" id="paymentType"
                                    value={paymentType || ''}
                                    onChange={handleFieldChange} defaultValue="">
                                    <option value="" disabled hidden>Choose</option>
                                    <option value="Check">Check</option>
                                    <option value="Direct Deposit">Direct Deposit</option>
                                    <option value="EFT">EFT</option>
                                    <option value="Other">Other</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="sedgwickClientId">Sedgwick Client Id</Label>
                                <Input type="number" name="sedgwickClientId" id="sedgwickClientId" value={sedgwickClientIdVal || ''}
                                    onChange={handleFieldChange} autoComplete="new-password"/>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="form-section">
                    <h5>ACCOUNTING</h5>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="gpCode">GP Code</Label>
                                <Input type="text" name="gpCode" id="gpCode" value={gpCode || ''}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="remitStreet">Remit Address Street</Label>
                                <Input type="text" name="remitStreet" id="remitStreet" value={remitStreetText || ''}
                                    onChange={handleStreetChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="remitStreet">Street2</Label>
                                <Input type="text" name="remitStreet2" id="remitStreet2" value={remitStreet2Text || ''}
                                    onChange={handleStreet2Change} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="remitCity">City</Label>
                                <Input type="text" name="remitCity" id="remitCity" readOnly value={remitCity || ''} />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="remitState">St</Label>
                                <Input type="text" name="remitState" id="remitState" readOnly value={remitState || ''} />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="remitZip">Zip Code</Label>
                                <Input type="text" name="remitZip" id="remitZip" value={remitZipCode || ''}
                                    onChange={handleTypeAheadSearch} />
                                {activeField === 'remitZip' && remitZipCode.length > 3 && zipList && zipList.length > 0 && (
                                    <ListGroup className='suggestion-list'>
                                        {zipList.map((zip, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onClick={() => handleRemitZipCodeClick(zip)}
                                            >
                                                {zip.zip_type_with_county}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="remitCounty">County</Label>
                                <Input type="text" name="remitCounty" id="remitCounty" readOnly value={remitCounty || ''} />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="form-section">
                    <h5>CLIENT INSTRUCTIONS</h5>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="notes">Notes</Label>
                                <Input type="textarea" name="notes" id="notes" value={notesText || ''}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="alert">Alert</Label>
                                <Input type="textarea" name="alert" id="alert" value={alertText || ''}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Form>

        </div>

    )
}

const mapStateToProps = state => {
    const initialData = fast360Selectors.getFast360InitialData(state);
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    const fast360Info = fast360Selectors.getFast360Info(state);
    const user = authSelectors.getUser(state);
    return {
        primaryContactCCFNameList: fast360MaintenacneInfo.primaryContactCCFNameList,
        primaryContactCCLNameList: fast360MaintenacneInfo.primaryContactCCLNameList,
        gpIntegrationList: initialData.gpIntegrationList,
        zipList: fast360Info.zipList,
        userId: user.id,
        newContactInfo: fast360MaintenacneInfo.newContactInfo,
        clientInfo: fast360MaintenacneInfo.clientInfo,
        loadNewClientInfo: fast360MaintenacneInfo.loadNewClientInfo
    }
}

const mapDispatchToProps = dispatch => ({
    getPrimaryContactCCFNameList: data => dispatch(fast360MaintenanceOperations.getPrimaryContactCCFNameList(data)),
    resetPrimaryContactCCFList: () =>
        dispatch(fast360MaintenanceActions.resetPrimaryContactCCFList()),
    getPrimaryContactCCLNameList: data => dispatch(fast360MaintenanceOperations.getPrimaryContactCCLNameList(data)),
    resetPrimaryContactCCLList: () =>
        dispatch(fast360MaintenanceActions.resetPrimaryContactCCLList()),
    getZipList: data => dispatch(fast360Operations.getZipList(data)),
    resetZipList: () => dispatch(fast360Actions.resetZipList()),
    saveNewContact: data => dispatch(fast360MaintenanceOperations.saveNewContact(data)),
    saveClient: data => dispatch(fast360MaintenanceOperations.saveClient(data)),
    getNewClient: data => dispatch(fast360MaintenanceOperations.getNewClient(data)),
    resetNewContactAction: () =>
        dispatch(fast360MaintenanceActions.resetNewContactAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientBasicInfoTab);