import React, { useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Input,
    Form,
    CustomInput
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    fast360MaintenanceOperations,
    fast360MaintenanceSelectors,
} from '../store/index';
import {
    fast360Selectors,
    fast360Operations,
    fast360Actions
} from '../../fast-360/store/index';
import { isEmpty } from 'lodash';
import ServicesTable from './ServicesTable';
import { authSelectors } from 'modules/auth/store';
import AddFeeModal from './AddFeeModal';
 
const ServicesTab = ({
    services,
    vendorEditItem,
    serviceType,
    feeGrid,
    parentFeeGrid,
    userId,
    loadFeeGrid,
    loadParentFeeGrid,
    saveVendorServices,
    logBreadCrumbs,
}) => {
    const [showParentFees, setShowParentFees] = useState(false);
    const [parentFeesDisabled, setParentFeesDisabled] = useState(false);
    const [addFeeDisabled, setAddFeeDisabled] = useState(false);
    const [saveChangesDisabled, setSaveChangesDisabled] = useState(true);
    const [serviceFeesData, setServiceFeesData] = useState(null);
    const [singleOrAll, setSingleOrAll] = useState('allgrids');
    const [selectedServiceType, setSelectedServiceType] = useState('');
    const [serviceFeesACCData, setServiceFeesACCData] = useState([]);
    const [serviceFeesAUDData, setServiceFeesAUDData] = useState([]);
    const [serviceFeesDTLData, setServiceFeesDTLData] = useState([]);
    const [serviceFeesDGNData, setServiceFeesDGNData] = useState([]);
    const [serviceFeesHHCData, setServiceFeesHHCData] = useState([]);
    const [serviceFeesINTData, setServiceFeesINTData] = useState([]);
    const [serviceFeesMODData, setServiceFeesMODData] = useState([]);
    const [serviceFeesMSEData, setServiceFeesMSEData] = useState([]);
    const [serviceFeesSPTData, setServiceFeesSPTData] = useState([]);
    const [serviceFeesTRNData, setServiceFeesTRNData] = useState([]);
    const [addFeeOpen, setAddFeeOpen] = useState(false);

    useEffect(() => {
        if ( !isEmpty(vendorEditItem) && (vendorEditItem != undefined)) {
            if (vendorEditItem.parentId != null && vendorEditItem.parentId > 0) {
                setParentFeesDisabled(false);
            } else {
                setParentFeesDisabled(true);
            }

            const payload = {
                functionName: 'loadFeeGrid',
                servid: '%',
                vendorid: vendorEditItem.id,
            }
            loadFeeGrid(payload);
            if (vendorEditItem.parentId != null && vendorEditItem.parentId > 0) {
                const parentLoad = {
                    functionName: 'loadParentFeeGrid',
                    servid: '%',
                    vendorId: vendorEditItem.id,
                }
                loadParentFeeGrid(parentLoad);
            }

        } else {
            setShowParentFees(false);
        }
    }, [vendorEditItem])

    //load fee grid
    useEffect(() => {
        if (feeGrid != undefined && feeGrid != null) {
            setServiceFeesData(feeGrid);
        
            if (singleOrAll === 'allgrids') {
                setServiceFeesACCData(feeGrid.filter(item => { return item.abbreviation === 'ACC'}));
                setServiceFeesAUDData(feeGrid.filter(item => { return item.abbreviation === 'AUD'}));
                setServiceFeesDTLData(feeGrid.filter(item => { return item.abbreviation === 'DTL'}));
                setServiceFeesDGNData(feeGrid.filter(item => { return item.abbreviation === 'DGN'}));
                setServiceFeesHHCData(feeGrid.filter(item => { return item.abbreviation === 'HHC'}));
                setServiceFeesINTData(feeGrid.filter(item => { return item.abbreviation === 'INT'}));
                setServiceFeesMODData(feeGrid.filter(item => { return item.abbreviation === 'MOD'}));
                setServiceFeesMSEData(feeGrid.filter(item => { return item.abbreviation === 'MSE'}));
                setServiceFeesSPTData(feeGrid.filter(item => { return item.abbreviation === 'SPT'}));
                setServiceFeesTRNData(feeGrid.filter(item => { return item.abbreviation === 'TRN'}));
            }

            if (feeGrid.length > 0) {
                setParentFeesDisabled(true);
                setShowParentFees(false);
                setAddFeeDisabled(false);
            } else {
                setParentFeesDisabled(false);
                setShowParentFees(true);
                setAddFeeDisabled(true);
            }
        }
    }, [feeGrid])

    //load parent services
    useEffect(() => {
        if ((parentFeeGrid != undefined)) {
            if (showParentFees) {
                if (singleOrAll == 'singlegrid') {
                    setServiceFeesData(parentFeeGrid);
                } else {
                    // break out and clone service types
                    setServiceFeesACCData(parentFeeGrid.filter(item => { return item.abbreviation === 'ACC'}));
                    setServiceFeesAUDData(parentFeeGrid.filter(item => { return item.abbreviation === 'AUD'}));
                    setServiceFeesDTLData(parentFeeGrid.filter(item => { return item.abbreviation === 'DTL'}));
                    setServiceFeesDGNData(parentFeeGrid.filter(item => { return item.abbreviation === 'DGN'}));
                    setServiceFeesHHCData(parentFeeGrid.filter(item => { return item.abbreviation === 'HHC'}));
                    setServiceFeesINTData(parentFeeGrid.filter(item => { return item.abbreviation === 'INT'}));
                    setServiceFeesMODData(parentFeeGrid.filter(item => { return item.abbreviation === 'MOD'}));
                    setServiceFeesMSEData(parentFeeGrid.filter(item => { return item.abbreviation === 'MSE'}));
                    setServiceFeesSPTData(parentFeeGrid.filter(item => { return item.abbreviation === 'SPT'}));
                    setServiceFeesTRNData(parentFeeGrid.filter(item => { return item.abbreviation === 'TRN'}));
                }
            }
        }
    }, [parentFeeGrid])

    const onShowAll = () => {
        setSingleOrAll('allgrids');
        const payload = {
            functionName: 'loadFeeGrid',
            servid: '%',
            vendorid: vendorEditItem.id,
        }
        loadFeeGrid(payload);
        if (vendorEditItem.parentId != null && vendorEditItem.parentId > 0) {
            const parentLoad = {
                functionName: 'loadParentFeeGrid',
                servid: '%',
                vendorId: vendorEditItem.id,
            }
            loadParentFeeGrid(parentLoad);
        }
        setSelectedServiceType('%');
    }

    const onServiceChange = (e) => {
        const target = e.target;
        const value = target.value;
        setSingleOrAll('singlegrid');
        
        const payload = {
            functionName: 'loadFeeGrid',
            servid: value,
            vendorid: vendorEditItem.id,
        }

        loadFeeGrid(payload);
        if (vendorEditItem.parentId != null && vendorEditItem.parentId > 0) {
            const parentLoad = {
                functionName: 'loadParentFeeGrid',
                servid: value,
                vendorId: vendorEditItem.id,
            }
            loadParentFeeGrid(parentLoad);
        }

        const label = e.target.options[e.target.selectedIndex].text;
        setSelectedServiceType(value);
    }

    const addFee = () => {
        setAddFeeOpen(true);
    }

    const onToggle = () => {
        setAddFeeOpen(!addFeeOpen);
    }

    const saveChanges = () => {
        // Grab all data
        setSaveChangesDisabled(true);

        var tempFeesData = serviceFeesData.map(item => item);

        if (singleOrAll == 'allgrids') {
            tempFeesData = serviceFeesACCData.map(item => item);
            tempFeesData.push(...serviceFeesAUDData);
            tempFeesData.push(...serviceFeesDTLData);
            tempFeesData.push(...serviceFeesDGNData);
            tempFeesData.push(...serviceFeesHHCData);
            tempFeesData.push(...serviceFeesINTData);
            tempFeesData.push(...serviceFeesMODData);
            tempFeesData.push(...serviceFeesMSEData);
            tempFeesData.push(...serviceFeesSPTData);
            tempFeesData.push(...serviceFeesTRNData);
        }

        const serviceList = vendorEditItem.serviceList.map(obj => {
            var newObj = obj.feeList.map(feeObj => {
                tempFeesData.forEach(gridFee => {
                    if (feeObj != null) {
                        if (gridFee.feeId == feeObj.id) {
                            feeObj.feeOrder = gridFee.feeOrder;
                            feeObj.amount = gridFee.amount;
                            feeObj.discountedFrom = gridFee.discountedfrom;
                            feeObj.percentDiscount = gridFee.percentdiscount;
                            feeObj.cpt = gridFee.cpt;
                            feeObj.modifier = gridFee.modifier;
                            feeObj.description = gridFee.description;
                            feeObj.startDate = gridFee.dat_begindate;
                            feeObj.endDate = gridFee.dat_enddate;
                            if (gridFee.zipcodeId != null) {
                                feeObj.zipCodeId = gridFee.zipcodeId;
                            }
                            return feeObj;
                        }
                    }
                })
                return feeObj;
            });
            obj.feeList = newObj;
            return obj;
        });
        
        const payload = {
            functionName: 'saveVendorServices',
            id: vendorEditItem.id,
            serviceList: JSON.parse(JSON.stringify(serviceList)),
        }

        //debugger;

        saveVendorServices(payload);

        const breadCrumb = {
            functionName: 'logBreadCrumbs',
            zebraUserId: userId,
            breadcrumbkey: 'VENDORSAVE',
            refTableId: 0,
            refId: 0,
            refTable: '',
          };
        logBreadCrumbs(breadCrumb);
    }

    const canSave = () => {
        setSaveChangesDisabled(false);
    }

    const updateServices = (services, serviceType) => {
        if (singleOrAll == 'singlegrid') {
            setServiceFeesData(services);
        } else {
            if (serviceType === 'ACC') {
                setServiceFeesACCData(services);
            } else if (serviceType === 'AUD') {
                setServiceFeesAUDData(services);
            } else if (serviceType === 'DTL') {
                setServiceFeesDTLData(services);
            } else if (serviceType === 'DGN') {
                setServiceFeesDGNData(services);
            } else if (serviceType === 'HHC') {
                setServiceFeesHHCData(services);
            } else if (serviceType === 'INT') {
                setServiceFeesINTData(services);
            } else if (serviceType === 'MSE') {
                setServiceFeesMSEData(services);
            } else if (serviceType === 'MOD') {
                setServiceFeesMODData(services);
            } else if (serviceType === 'SPT') {
                setServiceFeesSPTData(services);
            } else if (serviceType === 'TRN') {
                setServiceFeesTRNData(services);
            } else { // Shouldn't happen?
                setServiceFeesData(services);
            }
        }
    }

    const removeFee = (item) => {
        const payload = {
            functionName: 'loadFeeGrid',
            servid: '%',
            vendorid: vendorEditItem.id,
        }
        loadFeeGrid(payload);
        if (vendorEditItem.parentId != null && vendorEditItem.parentId > 0) {
            const parentLoad = {
                functionName: 'loadParentFeeGrid',
                servid: '%',
                vendorId: vendorEditItem.id,
            }
            loadParentFeeGrid(parentLoad);
        }
    }

    const getServiceFeesTable = () => {
        if (singleOrAll == 'singlegrid') {
            return (
                <div style={{marginTop: "20px"}} className='maintenanceTable'>
                    <ServicesTable
                        serviceType = ''
                        services = {serviceFeesData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                </div>
            )
        } else { // allgrids
            return (
                <div style={{marginTop: "20px"}} className='maintenanceTable'>
                    <ServicesTable
                        serviceType = 'ACC'
                        services = {serviceFeesACCData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'AUD'
                        services = {serviceFeesAUDData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'DTL'
                        services = {serviceFeesDTLData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'DGN'
                        services = {serviceFeesDGNData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'HHC'
                        services = {serviceFeesHHCData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'INT'
                        services = {serviceFeesINTData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'MOD'
                        services = {serviceFeesMODData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'MSE'
                        services = {serviceFeesMSEData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'SPT'
                        services = {serviceFeesSPTData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                    <ServicesTable
                        serviceType = 'TRN'
                        services = {serviceFeesTRNData}
                        showParentFees = {showParentFees}
                        updateServices= {updateServices}
                        canSave = {canSave}
                        removeFee = {removeFee}
                    />
                </div>
            )
        }
        
    }

    const onChangeShowParentFees = (event) => {
        const target = event.target;
        const value = target.checked;
        setShowParentFees(value);

        if (value) {
            if (singleOrAll == 'singlegrid') {
                setServiceFeesData(parentFeeGrid);
            } else {
                setServiceFeesACCData(parentFeeGrid.filter(item => { return item.abbreviation === 'ACC'}));
                setServiceFeesAUDData(parentFeeGrid.filter(item => { return item.abbreviation === 'AUD'}));
                setServiceFeesDTLData(parentFeeGrid.filter(item => { return item.abbreviation === 'DTL'}));
                setServiceFeesDGNData(parentFeeGrid.filter(item => { return item.abbreviation === 'DGN'}));
                setServiceFeesHHCData(parentFeeGrid.filter(item => { return item.abbreviation === 'HHC'}));
                setServiceFeesINTData(parentFeeGrid.filter(item => { return item.abbreviation === 'INT'}));
                setServiceFeesMODData(parentFeeGrid.filter(item => { return item.abbreviation === 'MOD'}));
                setServiceFeesMSEData(parentFeeGrid.filter(item => { return item.abbreviation === 'MSE'}));
                setServiceFeesSPTData(parentFeeGrid.filter(item => { return item.abbreviation === 'SPT'}));
                setServiceFeesTRNData(parentFeeGrid.filter(item => { return item.abbreviation === 'TRN'}));
            }
            setAddFeeDisabled(true);
        } else {
            if (singleOrAll == 'singlegrid') {
                setServiceFeesData(feeGrid);
            } else {
                setServiceFeesACCData(feeGrid.filter(item => { return item.abbreviation === 'ACC'}));
                setServiceFeesAUDData(feeGrid.filter(item => { return item.abbreviation === 'AUD'}));
                setServiceFeesDTLData(feeGrid.filter(item => { return item.abbreviation === 'DTL'}));
                setServiceFeesDGNData(feeGrid.filter(item => { return item.abbreviation === 'DGN'}));
                setServiceFeesHHCData(feeGrid.filter(item => { return item.abbreviation === 'HHC'}));
                setServiceFeesINTData(feeGrid.filter(item => { return item.abbreviation === 'INT'}));
                setServiceFeesMODData(feeGrid.filter(item => { return item.abbreviation === 'MOD'}));
                setServiceFeesMSEData(feeGrid.filter(item => { return item.abbreviation === 'MSE'}));
                setServiceFeesSPTData(feeGrid.filter(item => { return item.abbreviation === 'SPT'}));
                setServiceFeesTRNData(feeGrid.filter(item => { return item.abbreviation === 'TRN'}));
            }
            setAddFeeDisabled(false);
        }
    }
    
    return (
    <div>
        <Form className='vendorService-form'>
            <div className="form-section">
                <Row form className="align-items-center">
                    <Col md={6}>
                        <h5>SERVICE SETUP</h5>
                    </Col>
                    
                </Row>
                <Row form>
                    <Col md={2}>
                        <Label for="servicesFor">Show fees for :</Label>
                    </Col>
                    <Col md={4}>                        
                        <Input
                            type="select"
                            name="servicesFor"
                            id="servicesFor"
                            onChange={onServiceChange}
                            value={selectedServiceType}
                            >
                            <option value="%" hidden>Select a Service</option>
                            {serviceType.map((state, index) => (
                                <option key={index} value={state.id}>
                                    {state.value}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col md={4}>
                        <Button color="primary" onClick={onShowAll}>Show All</Button>
                    </Col>
                    <Col md={2}>
                        <CustomInput
                            id="showParentFees"
                            name="showParentFees"
                            checked={showParentFees}
                            label="Show Parent Fees"
                            type="checkbox"
                            onChange={onChangeShowParentFees}
                            disabled={parentFeesDisabled}
                            inline
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Button onClick={addFee} disabled={addFeeDisabled}>Add Fee</Button>
                    </Col>
                    <Col md={8}></Col>
                    <Col md={2}>
                        <Button onClick={saveChanges} disabled={saveChangesDisabled}>Save Changes</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {getServiceFeesTable()}
                    </Col>
                </Row>
            </div>
        </Form>
        <AddFeeModal
            isOpen={addFeeOpen}
            onToggle={onToggle}
            vendorEditItem={vendorEditItem}
        />
    </div>
    )
    
}

const mapStateToProps = state => {
    const fast360MaintenanceInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    const fast360VendorLookupData = fast360Selectors.getFast360VendorLookup(state);
    const user = authSelectors.getUser(state);
    return {
        feeGrid: fast360MaintenanceInfo.loadFeeGrid,
        parentFeeGrid: fast360MaintenanceInfo.loadParentFeeGrid,
        serviceType: fast360VendorLookupData.serviceType || [],
        userId: user.id,
    }
}

const mapDispatchToProps = dispatch => ({
    loadFeeGrid: data => dispatch(fast360MaintenanceOperations.loadFeeGrid(data)),
    loadParentFeeGrid: data => dispatch(fast360MaintenanceOperations.loadParentFeeGrid(data)),
    saveVendorServices: data => dispatch(fast360MaintenanceOperations.saveVendorServices(data)),
    logBreadCrumbs : data => dispatch(fast360Operations.logBreadCrumbs(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTab);