import {  
  LOAD_VENDOR_MAINTENANCE_LIST, 
  RESET_VENDOR_MAINTENANCE_LIST,
  LOAD_PRIMARY_CONTACT_FLIST,
  RESET_PRIMARY_CONTACT_FLIST,
  LOAD_PRIMARY_CONTACT_LNAME_LIST,
  RESET_PRIMARY_CONTACT_LNAME_LIST,
  LOAD_VENDOR_COUNT,
  RESET_VENDOR_COUNT,
  LOAD_VENDOR_PATH,
  RESET_VENDOR_PATH,
  SAVE_NEW_CONTACT,
  RESET_NEW_CONTACT,
  SAVE_VENDOR,
  RESET_VENDOR,
  LOAD_VENDOR_MAINTENANCE_DOCS,
  RESET_VENDOR_MAINTENANCE_DOCS,
  UPDATE_VENDOR_MAINTENANCE_DOCS,
  RESET_UPDATE_VENDOR_MAINTENANCE_DOCS,
  LOAD_VENDOR_MAINTENANCE_FILE_DATA,
  RESET_VENDOR_MAINTENANCE_FILE_DATA,
  LOAD_VENDOR_DOCTYPES,
  RESET_VENDOR_DOCTYPES,
  UPDATE_DELETE_VENDOR_DOCUMENT,
  RESET_DELETE_VENDOR_DOCUMENT,
  LOAD_NEW_VENDOR,
  RESET_NEW_VENDOR,
  LOAD_QUERY_VENDORS_EXCEPT_CHILDERN,
  RESET_QUERY_VENDORS_EXCEPT_CHILDERN,
  CHANGE_VENDOR_LEVEL_ACTION,
  RESET_CHANGE_VENDOR_LEVEL_ACTION,
  LOAD_FEE_GRID,
  LOAD_PARENT_FEE_GRID,
  RESET_FEES_GRID,
  LOAD_VENDOR_WITH_FEES,
  SAVE_VENDOR_SERVICES,
  END_VENDOR_FEE,
  LOAD_CLIENT_MAINTENANCE_LIST,
  RESET_CLIENT_MAINTENANCE_LIST,
  LOAD_CLIENT_DATA_LIST,
  RESET_CLIENT_DATA_LIST,
  LOAD_CLIENT_COUNT,
  RESET_CLIENT_COUNT,
  LOAD_PRIMARY_CONTACT_CC_FLIST,
  RESET_PRIMARY_CONTACT_CC_FLIST,
  LOAD_PRIMARY_CONTACT_CC_LNAME_LIST,
  RESET_PRIMARY_CONTACT_CC_LNAME_LIST,
  SAVE_CLIENT,
  RESET_CLIENT,
  LOAD_NEW_CLIENT,
  RESET_NEW_CLIENT,
  LOAD_VENDOR_SERVICE,
  LOAD_STATE_COUNTY_LIST,
  LOAD_ZIPCODE_LIST,
  RESET_ZIPCODE_LIST,
  LOAD_COUNTY_LIST,
  RESET_COUNTY_LIST,
  LOAD_CLIENT_TIER_GRID_DATA,
  RESET_CLIENT_TIER_GRID_DATA,
  LOAD_CLIENT_FEES_DATA,
  RESET_CLIENT_FEES_DATA,
  LOAD_CLIENT_SERVICE_DATA,
  RESET_CLIENT_SERVICE_DATA,
  SAVE_END_CLIENT_TIER,
  RESET_END_CLIENT_TIER,
  SAVE_CLIENT_TIER_GRID,
  RESET_CLIENT_TIER_GRID,
  SAVE_CLIENT_SERIVES,
  RESET_CLIENT_SERVICES,
} from './constants';

const loadVendorMaintenanceList = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_LIST,
      payload: data
    }
  }

  const resetVendorMaintenanceList = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_LIST,
    };
  };

  const loadPrimaryContactFList = data => {
    return {
      type: LOAD_PRIMARY_CONTACT_FLIST,
      payload: data
    }
  }

  const resetPrimaryContactFList = () => {
    return {
      type: RESET_PRIMARY_CONTACT_FLIST,
    };
  };

  const loadPrimaryContactLList = data => {
    return {
      type: LOAD_PRIMARY_CONTACT_LNAME_LIST,
      payload: data
    }
  }

  const resetPrimaryContactLList = () => {
    return {
      type: RESET_PRIMARY_CONTACT_LNAME_LIST,
    };
  };

  const loadVendorCount = data => {
    return {
      type: LOAD_VENDOR_COUNT,
      payload: data
    }
  }

  const resetVendorCount = () => {
    return {
      type: RESET_VENDOR_COUNT,
    };
  };

  const loadVendorPath = data => {
    return {
      type: LOAD_VENDOR_PATH,
      payload: data
    }
  }

  const resetVendorPath = () => {
    return {
      type: RESET_VENDOR_PATH,
    };
  };

  const saveNewContactAction = data => {
    return {
      type: SAVE_NEW_CONTACT,
      payload: data
    }
  }

  const resetNewContactAction = () => {
    return {
      type: RESET_NEW_CONTACT,
    };
  };

  const saveVendorAction = data => {
    return {
      type: SAVE_VENDOR,
      payload: data
    }
  }

  const resetSaveVendorAction = () => {
    return {
      type: RESET_VENDOR,
    };
  };

  
  const loadVendorMaintenanceDocAction = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_DOCS,
      payload: data
    }
  }

  const resetVendorMaintenanceDocAction = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_DOCS,
    };
  };

  const updateVendorMaintenanceDocAction = data => {
    return {
      type: UPDATE_VENDOR_MAINTENANCE_DOCS,
      payload: data
    }
  }

  const resetUpdatedVendorMaintenanceDocAction = () => {
    return {
      type: RESET_UPDATE_VENDOR_MAINTENANCE_DOCS,
    };
  };

  const loadVendorMaintenanceDocDataAction = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_FILE_DATA,
      payload: data
    }
  }

  const resetVendorMaintenanceDocDataAction = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_FILE_DATA,
    };
  };

  const loadVendorDocTypesAction = data => {
    return {
      type: LOAD_VENDOR_DOCTYPES,
      payload: data
    }
  }

  const resetVendorDocTypesAction = () => {
    return {
      type: RESET_VENDOR_DOCTYPES,
    };
  };

  const updateDeleteVendorDocument = data => {
    return {
      type: UPDATE_DELETE_VENDOR_DOCUMENT,
      payload: data,
    };
  };

  const resetDeleteVendorDocument = data => {
    return {
      type: RESET_DELETE_VENDOR_DOCUMENT,
      payload: data,
    };
  };

  const loadNewVendorAction = data => {
    return {
      type: LOAD_NEW_VENDOR,
      payload: data,
    };
  };

  const resetLoadingNewVendorAction = data => {
    return {
      type: RESET_NEW_VENDOR,
      payload: data,
    };
  };

  const loadFeeGrid = data => {
    return {
      type: LOAD_FEE_GRID,
      payload: data,
    };
  }

  const loadParentFeeGrid = data => {
    return {
      type: LOAD_PARENT_FEE_GRID,
      payload: data,
    }
  }

  const resetFeesGrid = () => {
    return {
      type: RESET_FEES_GRID,
    }
  }

  const loadVendorWithFees = data => {
    return {
      type: LOAD_VENDOR_WITH_FEES,
      payload: data,
    }
  }

  const saveVendorServicesAction = data => {
    return {
      type: SAVE_VENDOR_SERVICES,
      payload: data,
    }
  }

  const endVendorFee = data => {
    return {
      type: END_VENDOR_FEE,
      payload: data,
    }
  }

  const queryVendorsExceptChildrenAction = data => {
    return {
      type: LOAD_QUERY_VENDORS_EXCEPT_CHILDERN,
      payload: data,
    };
  };

  const resetQueryVendorsExceptChildrenAction = data => {
    return {
      type: RESET_QUERY_VENDORS_EXCEPT_CHILDERN,
      payload: data,
    };
  };

  const changeVendorLevelAction = data => {
    return {
      type: CHANGE_VENDOR_LEVEL_ACTION,
      payload: data,
    };
  };

  const resetChangeVendorLevelAction = data => {
    return {
      type: RESET_CHANGE_VENDOR_LEVEL_ACTION,
      payload: data,
    };
  };

   // Client Maintenance Actions

   const loadClientMaintenanceList = data => {
    return {
      type: LOAD_CLIENT_MAINTENANCE_LIST,
      payload: data
    }
  }

  const resetClientMaintenanceList = () => {
    return {
      type: RESET_CLIENT_MAINTENANCE_LIST,
    };
  };

  const loadClientDataList = data => {
    return {
      type: LOAD_CLIENT_DATA_LIST,
      payload: data
    }
  }

  const resetClientDataList = () => {
    return {
      type: RESET_CLIENT_DATA_LIST,
    };
  };

  const loadClientCount = data => {
    return {
      type: LOAD_CLIENT_COUNT,
      payload: data
    }
  }

  const resetClientCount = () => {
    return {
      type: RESET_CLIENT_COUNT,
    };
  };

  const loadPrimaryContactCCFList = data => {
    return {
      type: LOAD_PRIMARY_CONTACT_CC_FLIST,
      payload: data
    }
  }

  const resetPrimaryContactCCFList = () => {
    return {
      type: RESET_PRIMARY_CONTACT_CC_FLIST,
    };
  };

  const loadPrimaryContactCCLList = data => {
    return {
      type: LOAD_PRIMARY_CONTACT_CC_LNAME_LIST,
      payload: data
    }
  }

  const resetPrimaryContactCCLList = () => {
    return {
      type: RESET_PRIMARY_CONTACT_CC_LNAME_LIST,
    };
  };

  const loadVendorService = data => {
    return {
      type: LOAD_VENDOR_SERVICE,
      payload: data,
    }
  }

  const loadStateCountyList = data => {
    return {
      type: LOAD_STATE_COUNTY_LIST,
      payload: data,
    }
  }

  const loadZipcodeList = data => {
    return {
      type: LOAD_ZIPCODE_LIST,
      payload: data,
    }
  }

  const resetZipcodeList = () => {
    return {
      type: RESET_ZIPCODE_LIST,
    }
  }

  const loadCountyList = data => {
    return {
      type: LOAD_COUNTY_LIST,
      payload: data,
    }
  }

  const resetCountyList = () => {
    return {
      type: RESET_COUNTY_LIST,
    }
  }


  const saveClientAction = data => {
    return {
      type: SAVE_CLIENT,
      payload: data
    }
  }

  const resetSaveClientAction = () => {
    return {
      type: RESET_CLIENT,
    };
  };

  const loadNewClientAction = data => {
    return {
      type: LOAD_NEW_CLIENT,
      payload: data,
    };
  };

  const resetLoadingNewClientAction = data => {
    return {
      type: RESET_NEW_CLIENT,
      payload: data,
    };
  };

  const loadClientTierGridData = data => {
    return {
      type: LOAD_CLIENT_TIER_GRID_DATA,
      payload: data
    }
  }

  const resetClientTierGridData = () => {
    return {
      type: RESET_CLIENT_TIER_GRID_DATA,
    };
  };

  const loadClientWithFeesData = data => {
    return {
      type: LOAD_CLIENT_FEES_DATA,
      payload: data
    }
  }

  const resetClientWithFeesData = () => {
    return {
      type: RESET_CLIENT_FEES_DATA,
    };
  };

  const loadClientServiceData = data => {
    return {
      type: LOAD_CLIENT_SERVICE_DATA,
      payload: data
    }
  }

  const resetClientServiceData = () => {
    return {
      type: RESET_CLIENT_SERVICE_DATA,
    };
  };

  const saveEndClientTierAction = data => {
    return {
      type: SAVE_END_CLIENT_TIER,
      payload: data
    }
  }

  const resetEndClientTierAction = () => {
    return {
      type: RESET_END_CLIENT_TIER,
    };
  };

  const saveClientTierGridAction = data => {
    return {
      type: SAVE_CLIENT_TIER_GRID,
      payload: data
    }
  }

  const resetClientTierGridAction = () => {
    return {
      type: RESET_CLIENT_TIER_GRID
    };
  };

  const saveClientServicesAction = data => {
    return {
      type: SAVE_CLIENT_SERIVES,
      payload: data
    }
  }

  const resetClientServicesAction = () => {
    return {
      type: RESET_CLIENT_SERVICES
    };
  };

  export default {
    loadVendorMaintenanceList,
    resetVendorMaintenanceList,
    loadPrimaryContactFList,
    resetPrimaryContactFList,
    loadPrimaryContactLList,
    resetPrimaryContactLList,
    loadVendorCount,
    resetVendorCount,
    loadVendorPath,
    resetVendorPath,
    saveNewContactAction,
    resetNewContactAction,
    saveVendorAction,
    resetSaveVendorAction,
    loadVendorMaintenanceDocAction,
    resetVendorMaintenanceDocAction,
    updateVendorMaintenanceDocAction,
    resetUpdatedVendorMaintenanceDocAction,
    loadVendorMaintenanceDocDataAction,
    resetVendorMaintenanceDocDataAction,
    loadVendorDocTypesAction,
    resetVendorDocTypesAction,
    updateDeleteVendorDocument,
    resetDeleteVendorDocument,
    loadNewVendorAction,
    resetLoadingNewVendorAction,
    loadFeeGrid,
    loadParentFeeGrid,
    resetFeesGrid,
    loadVendorWithFees,
    saveVendorServicesAction,
    endVendorFee,
    queryVendorsExceptChildrenAction,
    resetQueryVendorsExceptChildrenAction,
    changeVendorLevelAction,
    resetChangeVendorLevelAction,
    loadClientMaintenanceList,
    resetClientMaintenanceList,
    loadClientDataList,
    resetClientDataList,
    loadClientCount,
    resetClientCount,
    loadPrimaryContactCCFList,
    resetPrimaryContactCCFList,
    loadPrimaryContactCCLList,
    resetPrimaryContactCCLList,
    saveClientAction,
    resetSaveClientAction,
    loadNewClientAction,
    resetLoadingNewClientAction,
    loadVendorService,
    loadStateCountyList,
    loadZipcodeList,
    resetZipcodeList,
    loadCountyList,
    resetCountyList,
    loadClientTierGridData,
    resetClientTierGridData,
    loadClientWithFeesData,
    resetClientWithFeesData,
    loadClientServiceData,
    resetClientServiceData,
    saveEndClientTierAction,
    resetEndClientTierAction,
    saveClientTierGridAction,
    resetClientTierGridAction,
    saveClientServicesAction,
    resetClientServicesAction,
  }