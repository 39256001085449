import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    Col,
    Form,
    FormGroup,
    Row,
    Label,
    Button,
    Input
  } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import ClientEdit from './ClientEdit';
import {
  fast360MaintenanceOperations,
  fast360MaintenanceSelectors,
  fast360MaintenanceActions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faArrowRight, faFileUpload } from '@fortawesome/free-solid-svg-icons';

const CLIENT_NAME_ID = 'CLIENT_NAME_ID';
const TELEPHONE_NUMBER = 'TELEPHONE_NUMBER';
const PRIMARY_CONTACT = 'PRIMARY_CONTACT';

  const ClientMaintenanceLMS = ({
    clientMaintenanceList,
    getClientMaintenanceList,
    getClientLoadData,
    clientDataList,
    userId,
    ClientRateBook,
    getClientCount,
    clientCount,
    resetSaveClientAction,
    resetNewContactAction,
    resetLoadingNewClientAction,
    getLoadClientWithFees,
    getLoadClientService,
    loadClientServicesData,
    getLoadClientTierGrid,
    clientTierGridData,
    resetClientWithFeesData,
    resetClientTierGridData,
    resetClientServiceData,
    resetEndClientTierAction,
  }) => {
    const options = [
        { value: CLIENT_NAME_ID, label: 'Client Name/ID' },
        { value: TELEPHONE_NUMBER, label: 'Telephone Number' },
        { value: PRIMARY_CONTACT, label: 'Primary Contact' }
      ]
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [findClientVal, setFindClientVal] = useState('');
    const [start, setStart] = useState(0);
    const [tableClientMaintenanceData, setTableClientMaintenanceData] = useState([]);
    const [isOpenClientRateBook, setIsOpenClientRateBook] = useState(false);
    const [openNewClient, setOpenNewClient] = useState(false);
    const [openEditClientScreen, setOpenEditClientScreen] = useState(false);
    const [clientEditItem, setClientEditItem] = useState({});
    const recordsPerPage = 25;

    useEffect(() => {
        const params = {
          functionName: "queryClients",
          clientid: '%',
          level: "%",
          name: findClientVal ? '%' + findClientVal + '%' : '%%',
          parentid: "%",
          start: start.toString(),
          searchby: selectedOption.label
        }
        getClientMaintenanceList(params); 
  
    }, [start])

    useEffect(() => {
      const params = {
        functionName: 'loadClientData',
        zebraUserId: userId,
      }
      getClientLoadData(params);
    }, []);

    // useEffect(() => {
    //   const params = {
    //     functionName: "getClientCount",
    //     clientid: '%',
    //     level: "0",
    //     name: findClientVal ? '%' + findClientVal + '%' : '%%',
    //     parentid: "%",
    //     start: start.toString(),
    //     searchby: null
    //   }
    //   getClientCount(params);
    // }, []);

    useEffect(() => {
      if (clientMaintenanceList && clientMaintenanceList.length > 0) {
        setTableClientMaintenanceData(clientMaintenanceList);
        const params = {
          functionName: "getClientCount",
          clientid: '%',
          level: "0",
          name: findClientVal ? '%' + findClientVal + '%' : '%%',
          parentid: "%",
          start: start.toString(),
          searchby: findClientVal ? selectedOption.label : null
        }
        getClientCount(params);
      }
    }, [clientMaintenanceList])


    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
      }

      const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setFindClientVal(value);
      }
    
      const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
          onFindHandler();
        }
      }

      const onFindHandler = () => {
       setStart(0);
       fetchClientMaintenanceData(0, findClientVal);
      }

      const fetchClientMaintenanceData = (startIndex, findClientVal) => {
        const params = {
          functionName: "queryClients",
          clientid: '%',
          level: "%",
          name: findClientVal ? '%' + findClientVal + '%' : '%%',
          parentid: "%",
          start: startIndex.toString(),
          searchby: selectedOption.label
        }
        getClientMaintenanceList(params); 
      }

      const renderFilesColumn = (item) => {
        if (item != null) {
          return (
            <span
              style={{
                marginLeft: '12px',
                cursor: 'pointer'
              }}
            >
              <FontAwesomeIcon
                icon={faFileUpload}
                onClick={() => {}}
              />
            </span>
          );
        }
        return null;
      }

      const renderFeeCountColumn = (item) => {
        let feeCountVal = '';
        if (item.feeCount == 0) {
          feeCountVal = "";
        } else {
          feeCountVal = "$";
        }
        return feeCountVal;
      }

      const renderContactColumn = (item) => {
        let contactVal = '';
        if (item != null) {
          contactVal = item.primaryContact.people.last + ", " + item.primaryContact.people.first;
        }
        return contactVal;
      }

      const renderStreetColumn = (item) => {
        let streetVal = '';
        if ((item.address && item.address.street1 != null) && (item.address && item.address.street1 != "")) {
          streetVal = item.address.street1;
          if (item.address.street2 != null && item.address.street2 != "") {
            streetVal += ", " + item.address.street2;
          }
          streetVal +=item.address.zipCode.city + ", " + item.address.zipCode.county + " County, " + item.address.zipCode.state + ", " + item.address.zipCode.zip;
        }
        return streetVal;
      }

      const renderPhoneColumn = (item) => {
        let phoneVal = '';
        if (item.phone != null && item.phone != '' && item.phone != undefined) {
          phoneVal = `(${item.phone?.toString().substr(0, 3)})${item.phone?.toString().substr(3, 3)}-${item.phone?.toString().substr(6, 4)}`;
        } else {
          phoneVal = ''
        }
        return phoneVal;
      };

      const renderLevelColumn = (item) => {
        let coulumnVal = Number(item.level);
        if (item.hasChildren) {
          return (
            <div>
              {coulumnVal} {'  '}
              <FontAwesomeIcon icon={faArrowRight} style={{ color: '#0073e6' }} onClick={() => {}} />
            </div>
          );
        } else {
          const className = 'active-green-circle';
          return (
            <div>
              {coulumnVal} {'  '}
              <FontAwesomeIcon className={className} icon={faCircle} />
            </div>
          );
        }
      };

      const getClientMaintenanceLandingTable = () => {
        return (
          <div style={{marginTop: "20px"}} className='maintenanceTable'>
            <AppTable
              columns={[
                {
                  header: 'ID',
                  field: 'id',
                },
                {
                  header: '',
                  field: 'feeCount',
                  render: item => renderFeeCountColumn(item),
                },
                {
                  header: 'Client/Location Name',
                  field: 'name',
                },
                {
                  header: 'Contact',
                  field: 'primaryContact',
                  render: item => renderContactColumn(item),
                },
                {
                  header: 'Address',
                  field: 'address',
                  render: item => renderStreetColumn(item),
                },
                {
                  header: 'Phone',
                  field: 'phone',
                  render: item => renderPhoneColumn(item)
                },
                {
                  header: 'Email',
                  field: 'email1',
                },
                {
                  header: 'Level',
                  field: 'level',
                  render: item => renderLevelColumn(item)
                },
                {
                  header: 'Files',
                  field: '',
                  render: item => renderFilesColumn(item)
                }
              ]
              }
              data={tableClientMaintenanceData || []}
                autoPagination={false}
              resizable={true}
              sortImmediately={false}
              rowDoubleClickHandler={onRowDoubleClick}
              key="client-maintenance-landing-table"
            />
          </div>
        )
      }

      const handleNext = () => {
        setStart(start + recordsPerPage);
      }
    
      const handleBack = () => {
        if (start >= recordsPerPage) {
          setStart(start - recordsPerPage);
        }
      };

      const onClientRateBookHandler = () => {
        setIsOpenClientRateBook(true);
      }

      const onCloseClientRateBookHandler = () => {
        setIsOpenClientRateBook(false);
      }

      const getClientRateBookTable = () => {
        return (
          <div style={{marginTop: "20px"}} className='maintenanceTable'>
            <AppTable
              columns={[
                {
                  header: 'Client',
                  field: 'name',
                },
                {
                  header: 'Accommodations',
                  field: 'accommodations',
                },
                {
                  header: 'Audiology',
                  field: 'audiology',
                },
                {
                  header: 'Dental',
                  field: 'dental',
                },
                {
                  header: 'Diagnostics',
                  field: 'diagnostics',
                },
                {
                  header: 'HHC',
                  field: 'hhc'
                },
                {
                  header: 'Interpretation',
                  field: 'interpretation',
                },
                {
                  header: 'Modification',
                  field: 'modification',
                },
                {
                  header: 'MSE',
                  field: 'mse',
                },
                {
                  header: 'Special Transportation',
                  field: 'specialtransportation',
                },
                {
                  header: 'Transportation',
                  field: 'transportation',
                },
                {
                  header: 'Invoice',
                  field: 'invoice',
                },
              ]
              }
              data={ClientRateBook || []}
              autoPagination={false}
              sortAble={true}
              resizable={true}
              sortImmediately={false}
             // rowDoubleClickHandler={onRowDoubleClick}
              key="client-rate-book-table"
            />
          </div>
        )
      }

      const onNewClient = () => {
        setOpenNewClient(true);
        setTableClientMaintenanceData([]);
        const params = {
          functionName: "loadClientService",
          zebraUserId: userId,
        }
        getLoadClientService(params);
      }

      const onRowDoubleClick = (item) => {
        const params = {
          functionName: "loadClientService",
          zebraUserId: userId,
        }
        getLoadClientService(params);
        setOpenNewClient(true);
        setClientEditItem(item);
        setOpenEditClientScreen(true);
      }
    

      const handleExitNewClient = () => {
        setOpenNewClient(false);
        setFindClientVal('');
        setStart(0);
        fetchClientMaintenanceData(0, '');
        resetNewContactAction();
        resetSaveClientAction();
        resetLoadingNewClientAction();
        setOpenEditClientScreen(false);
        setClientEditItem({});
        resetClientWithFeesData();
        resetClientTierGridData();
        resetClientServiceData();
        resetEndClientTierAction();
        //resetVendorPath();
        //setPath('');
        //resetFeesGrid();
        //resetChangeVendorLevelAction();
      }

      if (openNewClient) {
        return <ClientEdit openNewClient={openNewClient} onExit={handleExitNewClient} openEditClientScreen={openEditClientScreen} clientEditItem={clientEditItem}/>
      }

    

        return (
            <>  
         {isOpenClientRateBook ? (
                <>
                    <div>
                    <Button
                        type="button"
                        onClick={onCloseClientRateBookHandler}
                      >
                        CLOSE CLIENT RATE BOOK
                      </Button>
                    </div>
                    <div>
                        {getClientRateBookTable()}
                    </div>
                </>
            ) : (
              <>
                <div>
                  <Row className="align-items-right">
                  <Col lg="2">
                      <Button
                        style={{ width: '100%' }}
                        type="button"
                        onClick={onClientRateBookHandler}
                      >
                        CLIENT RATE BOOK
                      </Button>
                    </Col>
                    <Col>
                      <FormGroup style={{ width: "300px", marginLeft: "auto", position: 'relative' }}>
                        <Select
                          value={selectedOption}
                          name="vendormaintenanceFind"
                          idKey="vendormaintenanceFind"
                          onChange={handleSelectChange}
                          options={options}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                              position: 'absolute'
        
                            }),
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={2}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="name"
                          id="vendorRecords"
                          placeholder="Find Vendor Records"
                          onChange={handleInputChange}
                          onKeyPress={handleInputKeyPress}
                          value={findClientVal}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="1">
                      <Button
                        style={{ width: '100%' }}
                        type="button"
                        onClick={onFindHandler}
                      >
                        FIND
                      </Button>
                    </Col>
                  </Row>
                  <Row className="align-items-right">
                    <Col lg="2">
                      <Button
                        style={{ width: '100%' }}
                        type="button"
                        onClick={() => {}}
                        disabled
                      >
                        Level Up
                      </Button>
                    </Col>
                    <Col lg="2" style={{ marginTop: "6px" }}>
                      <span>Path</span>
        
                    </Col>
                    <Col lg="2" style={{ marginLeft: "auto" }}>
                      <Button
                        style={{ width: '100%' }}
                        type="button"
                        onClick={() => { }}
                        disabled
                      >
                        NEW CLIENT LEVEL
                      </Button>
                    </Col>
                    <Col lg="2">
                      <Button
                        style={{ width: '100%' }}
                        type="button"
                        onClick={onNewClient}
                      >
                        NEW CLIENT
                      </Button>
                    </Col>
                    <Col lg="2">
                      <Button
                        style={{ width: '100%' }}
                        type="button"
                      onClick={() => {}}
                      disabled
                      >
                        ADVANCED SEARCH
                      </Button>
                    </Col>
                  </Row>
              
                  <div>
                    {getClientMaintenanceLandingTable()}
                  </div>
                  <Row className="mt-3 justify-content-between" style={{ textAlign: "right" }}>
                    <Col lg="1">
                    <span>Result Count: {clientCount || ''}</span>
                    </Col>
            <Col lg="auto" className="d-flex justify-content-end">
              <Button
                style={{ width: '100%',marginRight: "10px" }}
                type="button"
                onClick={handleBack}
                title="previous 25 vendors"
                disabled={start === 0}
              >
                {'<'}
              </Button>
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={handleNext}
                title="next 25 vendors"
                disabled={tableClientMaintenanceData.length < recordsPerPage}
              >
                {'>'}
              </Button>
            </Col>
                  </Row>
                </div>
                </>
            )}
            </>
          );
        
        };

  
  const mapStateToProps = state => {
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
  const user = authSelectors.getUser(state);
  return {
    clientMaintenanceList: fast360MaintenacneInfo.clientMaintenanceList,
    userId: user.id,
    clientDataList: fast360MaintenacneInfo.clientDataList,
    ClientRateBook: fast360MaintenacneInfo.clientDataList && fast360MaintenacneInfo.clientDataList.ClientRateBook,
    clientCount: fast360MaintenacneInfo.clientCount,
    loadClientServicesData: fast360MaintenacneInfo.loadClientServicesData,
    clientTierGridData: fast360MaintenacneInfo.clientTierGridData
  };
};

const mapDispatchToProps = dispatch => ({
  getClientMaintenanceList: data => dispatch(fast360MaintenanceOperations.getClientMaintenanceList(data)),
  getClientLoadData: data => dispatch(fast360MaintenanceOperations.getClientLoadData(data)),
  getClientCount: data => dispatch(fast360MaintenanceOperations.getClientCount(data)),
  resetSaveClientAction: () =>
    dispatch(fast360MaintenanceActions.resetSaveClientAction()),
  resetNewContactAction: () =>
    dispatch(fast360MaintenanceActions.resetNewContactAction()),
  resetLoadingNewClientAction: () =>
    dispatch(fast360MaintenanceActions.resetLoadingNewClientAction()),
  getLoadClientWithFees: (data) => dispatch(fast360MaintenanceOperations.getLoadClientWithFees(data)),
  getLoadClientService: (data) => dispatch(fast360MaintenanceOperations.getLoadClientService(data)),
  getLoadClientTierGrid: (data) => dispatch(fast360MaintenanceOperations.getLoadClientTierGrid(data)),
  resetEndClientTierAction: () => dispatch(fast360MaintenanceActions.resetEndClientTierAction()),
  resetClientServiceData: () => dispatch(fast360MaintenanceActions.resetClientServiceData()),
  resetClientTierGridData: () => dispatch(fast360MaintenanceActions.resetClientTierGridData()),
  resetClientWithFeesData: () => dispatch(fast360MaintenanceActions.resetClientWithFeesData()),
});

  export default connect(mapStateToProps, mapDispatchToProps)(ClientMaintenanceLMS);