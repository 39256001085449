import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import AppTable from 'shared/components/table/AppTable';
import {
    Row,
    Col,
    Input,
    Button,
} from 'reactstrap';
import { fast360Operations, fast360Selectors, fast360Actions } from 'modules/fast-360/store/index';
import DatePicker from "react-datepicker";
import PopupModal from 'shared/components/PopupModal';
import Select from 'react-select';
import { assign } from 'lodash';
import {
    sortByType,
    isValidDate,
  } from 'shared/utils/shareUtils';
import './MaintenanceTable.css';
import { fast360MaintenanceOperations } from '../store/index';

class ServicesTable extends Component {
    constructor(props) {
        super(props);

        const columns = [
            {
                header: 'Order',
                render: item => this.getOrder(item),
                field: 'feeOrder',
            },
            {
                header: 'Subservice',
                render: item => this.getSubService(item),
                field: 'subservice',
                sortFunction: (item1, item2, order) => 
                    this.sortColumn(item1, item2, order, 'Subservice'),
            },
            {
                header: 'Nationwide',
                render: item => this.getNationwide(item),
                field: 'nationwide',
                sortFunction: (item1, item2, order) =>
                    this.sortColumn(item1, item2, order, 'Nationwide'),
            },
            {
                header: 'State',
                render: item => item['state'],
                field: 'state'
            },
            {
                header: 'Zip',
                render: item => this.getZip(item),
                minWidth: 50,
                field: 'zipcode'
            },
            {
                header: 'City',
                render: item => item['city'],
                field: 'city',
            },
            {
                header: 'County',
                render: item => item['county'],
                field: 'county',
            },
            {
                header: 'Fee Type',
                render: item => item['feetype'],
                field: 'feetype',
            },
            {
                header: 'Override',
                render: item => this.getOverride(item),
                field: 'lesserof',
                sortFunction: (item1, item2, order) =>
                    this.sortColumn(item1, item2, order, 'Override'),
            },
            {
                header: 'Discounted From',
                render: item => this.getDiscountedFrom(item),
                field: 'discountedfrom',
            },
            {
                header: 'Value',
                render: item => this.getValue(item),
                field: 'amount',
            },
            {
                header: 'CPT/HCPC',
                render: item => this.getCPTHCPC(item),
                field: 'cpt',
            },
            {
                header: 'Description',
                render: item => this.getDescription(item),
                field: 'description',
            },
            {
                header: 'Modifier',
                render: item => this.getModifier(item),
                field: 'modifier',
            },
            {
                header: 'Date Range',
                render: item => this.getDateRange(item),
                minWidth: 300,
                field: 'dat_begindate',
            },
            {
                header: 'Terminate',
                render: item => this.getTerminate(item),
            }
        ];
        const shortColumns = [
            {
                header: 'Subservice',
                render: item => this.getSubService(item),
                field: 'subservice',
                sortFunction: (item1, item2, order) => 
                    this.sortColumn(item1, item2, order, 'Subservice'),
            },
            {
                header: 'Nationwide',
                render: item => this.getNationwide(item),
                field: 'nationwide',
                sortFunction: (item1, item2, order) =>
                    this.sortColumn(item1, item2, order, 'Nationwide'),
            },
            {
                header: 'State',
                render: item => item['state'],
                field: 'state',
            },
            {
                header: 'Zip',
                render: item => this.getZip(item),
                minWidth: 50,
                field: 'zipcode',
            },
            {
                header: 'City',
                render: item => item['city'],
                field: 'city',
            },
            {
                header: 'County',
                render: item => item['county'],
                field: 'county',
            },
            {
                header: 'Fee Type',
                render: item => item['feetype'],
                field: 'feetype',
            },
            {
                header: 'Value',
                render: item => this.getValue(item),
                field: 'amount',
            },
            {
                header: 'Date Range',
                render: item => this.getDateRange(item),
                minWidth: 300,
                field: 'dat_begindate',
            },
            {
                header: 'Terminate',
                render: item => this.getTerminate(item),
            }
        ];

        /*
        if (this.props.serviceType === '' ||
            this.props.serviceType === 'AUD' ||
            this.props.serviceType === 'DTL' ||
            this.props.serviceType === 'DGN' ||
            this.props.serviceType === 'HHC' ||
            this.props.serviceType === 'MSE') {
            this.state = {
                columns: columns,
                showTerminate: false,
                terminateItem: null,
                terminateDate: null,
            }
        } else {
            this.state = {
                columns: shortColumns,
                showTerminate: false,
                terminateItem: null,
                terminateDate: null,
            }
        }
        */

        this.state = {
            columns: columns,
            shortColumns: shortColumns,
            showTerminate: false,
            terminateItem: null,
            terminateDate: null,
            tempOrder: null,
        }
        this.onTerminate = this.onTerminate.bind(this);
        this.closeTerminateModal = this.closeTerminateModal.bind(this);
    }

    sortColumn(item1, item2, order, field) {
        if (field == 'Subservice') {
            const value1 = item1.abbreviation + ' - ' + item1.subservice;
            const value2 = item2.abbreviation + ' - ' + item2.subservice;
            return sortByType(value1, value2, order);
        } else if (field == 'Nationwide' || field == 'Override') {
            const value1 = item1.nationwide ? 'Yes' : 'No';
            const value2 = item2.nationwide ? 'Yes' : 'No';
            return sortByType(value1, value2, order);
        }
        const value1 = item1.fields.hasOwnProperty(field)
            ? item1.fields[field] + ''
            : '';
        const value2 = item2.fields.hasOwnProperty(field)
            ? item2.fields[field] + ''
            : '';
        return sortByType(value1, value2, order);
    }

    getOrder = (item) => {
        return (
            <>
                <Input
                    onChange={e => this.handleOrderChange(item, e)}
                    onBlur={e => this.handleOrderBlur(item, e)}
                    value={item.feeOrder}
                />
            </>
        )
    }

    handleOrderChange = (item, event) => {
        const target = event.target;        
        var needsToUpdate = false;
        
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                if (obj.feeOrder != target.value) {
                    needsToUpdate = true;
                    if (this.state.tempOrder == null) {
                        this.setState({
                            tempOrder: obj.feeOrder,
                        });
                    }                    
                    item.feeOrder = target.value;
                    obj = item;
                }                
            }
            return obj;
        });
        
        if (needsToUpdate) {
            this.props.updateServices(newServices, this.props.serviceType);
        }     
    }

    handleOrderBlur = (item, event) => {
        const target = event.target;
        if (target.value < 1) {
            const newServices = this.props.services.map(obj => {
                if(obj.feeId === item.feeId) {
                    obj.feeOrder = this.state.tempOrder;
                }
                return obj;
            });
            this.props.updateServices(newServices, this.props.serviceType);
        } else {
            const newServices = this.props.services.sort((a, b) => (a.feeOrder > b.feeOrder ? 1 : -1));
            this.props.updateServices(newServices, this.props.serviceType);
            this.props.canSave();
        }
        
        this.setState({
            tempOrder: null,
        })
        
    }

    getSubService = (item) => {
        var subServiceText = '';
        if (item.subservice != '') {
            subServiceText = item.abbreviation + ' - ' + item.subservice;
            if (item.abbreviation == 'INT') {
                var checkLangTo = false;
                if (item.fromAllLanguages) {
                    subServiceText += ' (All Languages';
                    checkLangTo = true;
                } else if (item.languageFrom != null) {
                    subServiceText += ' (' + item.languageFrom;
                    checkLangTo = true;
                }
                if (checkLangTo) {
                    if (item.allLanguages) {
                        subServiceText += ' to All Languages)';
                    } else {
                        subServiceText += 'to' + item.language + ')';
                    }
                }
            }
        }        
        return <>{subServiceText}</>
    }

    getNationwide = (item) => {
        if (item.nationwide != null) {
            if (item.nationwide) {
                return "Yes";
            } else {
                return "No";
            }
        }
    }

    getZip = (item) => {
        return (
            <>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isDisabled={item.nationwide || this.props.showParentFees}
                  onChange={e =>
                    this.handleFormFields(e, item)
                  }
                  placeholder={item.zipcode}
                  menuPlacement="bottom"
                  onKeyDown={e => this.handleTypeAheadSearch(e, item)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  menuIsOpen={this.props.zipList.length}
                  value={item.zipcode}
                  closeMenuOnSelect={true}
                  options={
                    this.props.zipList.length
                      ? this.props.zipList.map(obj =>
                        assign(obj, {
                          label: obj.zip_type_with_county,
                          value: obj.code_id,
                        })
                      )
                      : []
                  }
                  name="locZip"
                  id={"locZip_" + item.feeId}
                />
            </>
        )
    }

    handleFormFields = (event, item) => {
        if (!item.nationwide && !this.props.showParentFees) {
            const selectedZip = this.props.zipList.find(obj => obj.code_id === event.value);
            if (selectedZip != null) {
                item.city = selectedZip.chr_city;
                item.state = selectedZip.chr_state;
                item.county = selectedZip.chr_county;
                item.zipcode = selectedZip.chr_zipcode;
                item.zipcodeId = selectedZip.code_id;
            }
            const newServices = this.props.services.map(obj => {
                if(obj.feeId === item.feeId) {
                    obj = item;
                }
                return obj;
            });
            this.props.updateServices(newServices, this.props.serviceType);
            this.props.canSave();
            this.props.resetZipList();
        }
    }

    handleTypeAheadSearch = (event, item) => {
        if (!item.nationwide && !this.props.showParentFees) {
            event.persist();
            if (
                event.target.value.length > 2 &&
                (event.keyCode === 13 || event.keyCode === 9)
            ) {
                const params = {
                    zipCode: event.target.value,
                };
                this.props.getZipList(params);
            } else if (event.target.value.length > 0 && this.props.zipList.length) {
                this.props.resetZipList();
            }
        }
    }

    getOverride = (item) => {
        if (item.lesserof != null) {
            if (item.lesserof) {
                return "Yes";
            } else {
                return "No";
            }
        }
    }

    getDiscountedFrom = (item) => {
        /*
        if (this.props.serviceType == 'ACC' || this.props.serviceType == 'INT' || this.props.serviceType == 'TRN' ||
            this.props.serviceType == 'MOD' || this.props.serviceType == 'SPT') {
            return null;
        }
        */
        if (!item.showVdf) {
            return null;
        }
        return (
            <>
                <Input
                    id={"discountedFrom_" + item.feeId}
                    name="discountedFrom"
                    type="select"
                    value={item.discountedfrom}
                    className="dropdown"
                    disabled={this.showParentFees}
                    onChange={(e) => this.onChangeDiscountedFrom(e, item)}
                >
                    <option value=''></option>
                    <option value='FS'>FS</option>
                    <option value='UC'>UC</option>
                    <option value='MCA'>MCA</option>
                    <option value='Bill Charges'>Bill Charges</option>
                    <option value='OWCP'>OWCP</option>
                </Input>
            </>
        )
    }

    onChangeDiscountedFrom = (event, item) => {        
        var isDuplicate = false;
        const serviceCheck = this.props.services.map(obj => {
            if (obj.dat_begindate == item.dat_begindate && obj.dat_enddate == item.dat_enddate && obj.discountedfrom == event.target.value && obj.feeTypeId == item.feeTypeId &&
                obj.listsubserviceId == item.listsubserviceId && obj.nationwide == item.nationwide) {
                if (obj.state != null && item.state != null) {
                    if (obj.zipCodeId != null && item.zipCodeId != null) {
                        if (obj.zipCodeId == item.zipCodeId) {
                            isDuplicate = true;
                        }
                    } else {
                        if (obj.state = item.state) {
                            isDuplicate = true;
                        }
                    }
                } else {
                    isDuplicate = true;
                }
                if (isDuplicate) {
                    alert('Cannot create a duplicate fee');
                    return;
                }
            }
        });
        if (isDuplicate) {
            return;
        }

        item.discountedfrom = event.target.value;

        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
        this.props.canSave();
    }

    getValue = (item) => {
        var displayData = '';
        if (item.feetype != null) {
            if (item.datatype == 'Amount') {
                displayData = '$' + item.amount;
            } else if (item.datatype == 'Percent') {
                displayData = item.amount + '%';
            } else {
                displayData = item.amount;
            }
        }
        return (
            <>
                <Input
                    onChange = {e => this.handleValueChange(item, e)}
                    value={displayData}
                />
            </>
        )
    }

    handleValueChange = (item, event) => {
        if (item.feetype != null) {
            if (item.datatype == 'Amount') {
                item.amount = event.target.value.replace('$', '');
            } else if (item.datatype == 'Percent') {
                item.amount = event.target.value.replace('%','');
            } else {
                item.amount = event.target.value;
            }
        }
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
        this.props.canSave();
    }

    getCPTHCPC = (item) => {
        return (
            <>
                <Input
                    onChange = {e => this.handleCPTHCPCChange(item, e)}
                    value={item.cpt}
                />
            </>
        )
    }

    handleCPTHCPCChange = (item, event) => {
        item.cpt = event.target.value;
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
        this.props.canSave();
    }

    getDescription = (item) => {
        return (
            <>
                <Input
                    onChange = {e => this.handleDescriptionChange(item, e)}
                    value={item.description}
                />
            </>
        )
    }

    handleDescriptionChange = (item, event) => {
        item.description = event.target.value;
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
        this.props.canSave();
    }

    getModifier = (item) => {
        return (
            <>
                <Input
                    id={"modifier_" + item.feeId}
                    name="modifier"
                    type="select"
                    value={item.modifier}
                    className="dropdown"
                    onChange={(e) => this.onChangeModifier(e, item)}
                >
                    <option value=''></option>
                    <option value='New'>New</option>
                    <option value='Rental'>Rental</option>
                    <option value='Used'>Used</option>                    
                </Input>
            </>
        )
    }

    onChangeModifier = (event, item) => {
        item.modifier = event.target.value;
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
        this.props.canSave();
    }

    getDateRange = (item) => {
        return (
            <div className='DatePicker'>
                <DatePicker
                    locale="en"
                    selected={new Date(item.dat_begindate.replace('-','/'))}
                    onChange={date => this.setStartDate(date, item)}
                />
                To
                <DatePicker
                    locale="en"
                    selected={new Date(item.dat_enddate.replace('-','/'))}
                    onChange={date => this.setEndDate(date, item)}
                />
            </div>
        )
    }

    setStartDate = (date, item) => {
        item.dat_begindate = this.dateFormat(date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
    }

    setEndDate = (date, item) => {
        item.dat_enddate = this.dateFormat(date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
        const newServices = this.props.services.map(obj => {
            if(obj.feeId === item.feeId) {
                obj = item;
            }
            return obj;
        });
        this.props.updateServices(newServices, this.props.serviceType);
    }

    getTerminate = (item) => {
        return (
            <Button
                type="button"
                id={"terminate_" + item.feeId}
                onClick={() => {this.onTerminate(item)}}
            >
                X
            </Button>
        );
    }

    onTerminate = (item) => {
        this.setState({
            showTerminate: true,
            terminateItem: item,
            terminateDate: new Date(),
        })
        /*
        this.state = {
            showTerminate: true,
            terminateItem: item,
            terminateDate: null,
            ...this.state,
        }
        */
    }

    terminateModalContent = () => {
        return (
            <>
                <span>Please select an end date for the old fee</span><br />
                <DatePicker
                    locale="en"
                    selected={this.state.terminateDate}
                    onChange={date => this.setTerminateDate(date)}
                />
            </>
        )
    }

    setTerminateDate = (date) => {
        /*
        this.state = {
            terminateDate: date,
            ...this.state,
        }
        */
       //toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}
       this.setState({
            terminateDate: date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}),
       })
    }

    closeTerminateModal = () => {
        this.setState({
            showTerminate: false,
            terminateItem: null,
            terminateDate: null,
        })
        /*
        this.state = {
            showTerminate: false,
            terminateItem: null,
            terminateDate: null,
            ...this.state,
        }
        */
    }

    confirmTerminate = () => {
        const payload = {
            functionName: "endVendorFee",
            endDate: this.dateFormat(this.state.terminateDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})),
            feeId: this.state.terminateItem?.feeId,
        }

        this.props.endVendorFee(payload);
        this.props.removeFee(this.state.terminateItem);
        this.closeTerminateModal();
    }

    dateFormat = data => {
        if (data) {
          let dos = data && data.split('/');
          if(dos.length > 1) {
            return dos[2] + '-' + dos[0] + '-' + dos[1];
          } else {
            return data;
          }
        }
        return;
    }

    getColumns = () => {
        if (this.props.serviceType === '' ||
            this.props.serviceType === 'AUD' ||
            this.props.serviceType === 'DTL' ||
            this.props.serviceType === 'DGN' ||
            this.props.serviceType === 'HHC' ||
            this.props.serviceType === 'MSE') {
            return this.state.columns;
        } else {
            return this.state.shortColumns;
        }
    }

    render() {
        if (this.props.services.length == 0) {
            return <></>;
        }
        return (
        <>
            <AppTable
                data={this.props.services}
                autoPagination={false}
                resizable={true}
                columns={this.getColumns()}
                modalHeight={250}
                sortAble={true}
            />            
            <PopupModal
                content={this.terminateModalContent()}
                title="Set End Date"
                isOpen={this.state.showTerminate}
                externalToggle={this.closeTerminateModal}
                footerContent={
                    <>
                        <Button color="secondary" onClick={() => {this.closeTerminateModal()}}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={() => {this.confirmTerminate()}}>
                            Save
                        </Button>
                    </>
                }
                size="md"
            />            
        </>
        );
    }
}

ServicesTable.propTypes = {
    serviceType: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.object).isRequired,
    showParentFees: PropTypes.bool.isRequired,
    updateServices: PropTypes.func.isRequired,
    canSave: PropTypes.func.isRequired,
    removeFee: PropTypes.func.isRequired,
}

ServicesTable.defaultProps = {
    serviceType: '',
    showParentFees: false,
}

function mapStateToProps(state) {
    const fast360Info = fast360Selectors.getFast360Info(state);
    return {
        zipList: fast360Info.zipList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getZipList: data => dispatch(fast360Operations.getZipList(data)),
        resetZipList: () => dispatch(fast360Actions.resetZipList()),
        endVendorFee: data => dispatch(fast360MaintenanceOperations.endVendorFee(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTable);