import { combineReducers } from 'redux';
import {
  LOAD_VENDOR_MAINTENANCE_LIST,
  RESET_VENDOR_MAINTENANCE_LIST,
  LOAD_PRIMARY_CONTACT_FLIST,
  RESET_PRIMARY_CONTACT_FLIST,
  LOAD_PRIMARY_CONTACT_LNAME_LIST,
  RESET_PRIMARY_CONTACT_LNAME_LIST,
  LOAD_VENDOR_COUNT,
  RESET_VENDOR_COUNT,
  LOAD_VENDOR_PATH,
  RESET_VENDOR_PATH,
  SAVE_NEW_CONTACT,
  RESET_NEW_CONTACT,
  SAVE_VENDOR,
  RESET_VENDOR,
  LOAD_VENDOR_MAINTENANCE_DOCS,
  RESET_VENDOR_MAINTENANCE_DOCS,
  UPDATE_VENDOR_MAINTENANCE_DOCS,
  RESET_UPDATE_VENDOR_MAINTENANCE_DOCS,
  LOAD_VENDOR_MAINTENANCE_FILE_DATA,
  RESET_VENDOR_MAINTENANCE_FILE_DATA,
  LOAD_VENDOR_DOCTYPES,
  RESET_VENDOR_DOCTYPES,
  UPDATE_DELETE_VENDOR_DOCUMENT,
  RESET_DELETE_VENDOR_DOCUMENT,
  LOAD_NEW_VENDOR,
  RESET_NEW_VENDOR,
  LOAD_FEE_GRID,
  LOAD_PARENT_FEE_GRID,
  RESET_FEES_GRID,
  LOAD_VENDOR_WITH_FEES,
  LOAD_QUERY_VENDORS_EXCEPT_CHILDERN,
  RESET_QUERY_VENDORS_EXCEPT_CHILDERN,
  CHANGE_VENDOR_LEVEL_ACTION,
  RESET_CHANGE_VENDOR_LEVEL_ACTION,
  LOAD_CLIENT_MAINTENANCE_LIST,
  RESET_CLIENT_MAINTENANCE_LIST,
  RESET_CLIENT_DATA_LIST,
  LOAD_CLIENT_DATA_LIST,
  LOAD_CLIENT_COUNT,
  RESET_CLIENT_COUNT,
  LOAD_PRIMARY_CONTACT_CC_FLIST,
  RESET_PRIMARY_CONTACT_CC_FLIST,
  LOAD_PRIMARY_CONTACT_CC_LNAME_LIST,
  RESET_PRIMARY_CONTACT_CC_LNAME_LIST,
  SAVE_CLIENT,
  RESET_CLIENT,
  LOAD_NEW_CLIENT,
  RESET_NEW_CLIENT,
  LOAD_VENDOR_SERVICE,
  LOAD_STATE_COUNTY_LIST,
  LOAD_ZIPCODE_LIST,
  RESET_ZIPCODE_LIST,
  LOAD_COUNTY_LIST,
  RESET_COUNTY_LIST,
  LOAD_CLIENT_TIER_GRID_DATA,
  RESET_CLIENT_TIER_GRID_DATA,
  LOAD_CLIENT_FEES_DATA,
  RESET_CLIENT_FEES_DATA,
  LOAD_CLIENT_SERVICE_DATA,
  RESET_CLIENT_SERVICE_DATA,
  SAVE_END_CLIENT_TIER,
  RESET_END_CLIENT_TIER,
  SAVE_CLIENT_TIER_GRID,
  RESET_CLIENT_TIER_GRID,
  SAVE_CLIENT_SERIVES,
  RESET_CLIENT_SERVICES,
} from './constants';

const fast360MaintenanceState = {
  vendorMaintenanceList: [],
  primaryContactFNameList: [],
  primaryContactLNameList: [],
  vendorCount: undefined,
  vendorPath: undefined,
  newContactInfo: undefined,
  vendorInfo: [],
  vendorDocsInfo: [],
  vendorSaveDocInfo: [],
  vendorFileDataInfo: [],
  docTypesInfo: [],
  deleteDocumentInfo: [],
  loadNewVendorInfo: [],
  loadFeeGrid: [],
  loadParentFeeGrid: [],
  vendorRecord: null,
  vendorsListExceptChildern: [],
  levelChangeInfo: undefined,
  clientMaintenanceList: [],
  clientDataList: [],
  clientCount: undefined,
  primaryContactCCFNameList: [],
  primaryContactCCLNameList: [],
  clientInfo: [],
  loadNewClientInfo: [],
  vendorService: null,
  loadStateCountyList: [],
  zipCodeList: [],
  countyList: [],
  clientFeesData: [],
  clientTierGridData: [],
  loadClientServicesData: [],
  endClientTierInfo: [],
  saveClientTierInfo: [],
  saveClientServicesInfo: [],
}

const fast360MaintenanceReducer = (state = fast360MaintenanceState, action) => {
  switch (action.type) {
    case LOAD_VENDOR_MAINTENANCE_LIST:
      return {
        ...state,
        vendorMaintenanceList: action.payload,
      };
    case RESET_VENDOR_MAINTENANCE_LIST:
      return {
        ...state,
        vendorMaintenanceList: [],
      };
    case LOAD_PRIMARY_CONTACT_FLIST:
      return {
        ...state,
        primaryContactFNameList: action.payload,
      };
    case RESET_PRIMARY_CONTACT_FLIST:
      return {
        ...state,
        primaryContactFNameList: [],
      };
    case LOAD_PRIMARY_CONTACT_LNAME_LIST:
      return {
        ...state,
        primaryContactLNameList: action.payload,
      };
    case RESET_PRIMARY_CONTACT_LNAME_LIST:
      return {
        ...state,
        primaryContactLNameList: [],
      };
    case LOAD_VENDOR_COUNT:
      return {
        ...state,
        vendorCount: action.payload,
      };
    case RESET_VENDOR_COUNT:
      return {
        ...state,
        vendorCount: undefined,
      };
    case LOAD_VENDOR_PATH:
      return {
        ...state,
        vendorPath: action.payload,
      };
    case RESET_VENDOR_PATH:
      return {
        ...state,
        vendorPath: undefined,
      };
    case SAVE_NEW_CONTACT:
      return {
        ...state,
        newContactInfo: action.payload,
      };
    case RESET_NEW_CONTACT:
      return {
        ...state,
        newContactInfo: undefined,
      };
    case SAVE_VENDOR:
      return {
        ...state,
        vendorInfo: action.payload,
      };
    case RESET_VENDOR:
      return {
        ...state,
        vendorInfo: [],
      };
    case LOAD_VENDOR_MAINTENANCE_DOCS:
      return {
        ...state,
        vendorDocsInfo: action.payload,
      };
    case RESET_VENDOR_MAINTENANCE_DOCS:
      return {
        ...state,
        vendorDocsInfo: [],
      };
    case UPDATE_VENDOR_MAINTENANCE_DOCS:
      return {
        ...state,
        vendorSaveDocInfo: action.payload,
      };
    case RESET_UPDATE_VENDOR_MAINTENANCE_DOCS:
      return {
        ...state,
        vendorSaveDocInfo: [],
      };
    case LOAD_VENDOR_MAINTENANCE_FILE_DATA:
      return {
        ...state,
        vendorFileDataInfo: action.payload,
      };
    case RESET_VENDOR_MAINTENANCE_FILE_DATA:
      return {
        ...state,
        vendorFileDataInfo: [],
      };
    case LOAD_VENDOR_DOCTYPES:
      return {
        ...state,
        docTypesInfo: action.payload,
      };
    case RESET_VENDOR_DOCTYPES:
      return {
        ...state,
        docTypesInfo: [],
      };
    case UPDATE_DELETE_VENDOR_DOCUMENT:
      return {
        ...state,
        deleteDocumentInfo: action.payload,
      };
    case RESET_DELETE_VENDOR_DOCUMENT:
      return {
        ...state,
        deleteDocumentInfo: [],
      };
    case LOAD_NEW_VENDOR:
      return {
        ...state,
        loadNewVendorInfo: action.payload,
      };
    case RESET_NEW_VENDOR:
      return {
        ...state,
        loadNewVendorInfo: [],
      };
    case LOAD_FEE_GRID:
      return {
        ...state,
        loadFeeGrid: action.payload,
      };
    case LOAD_PARENT_FEE_GRID:
      return {
        ...state,
        loadParentFeeGrid: action.payload,
      };
    case RESET_FEES_GRID:
      return {
        ...state,
        loadFeeGrid: [],
        loadParentFeeGrid: [],
      };
    case LOAD_VENDOR_WITH_FEES:
      return {
        ...state,
        vendorRecord: action.payload,
      };
    case LOAD_QUERY_VENDORS_EXCEPT_CHILDERN:
      return {
        ...state,
        vendorsListExceptChildern: action.payload,
      };
    case RESET_QUERY_VENDORS_EXCEPT_CHILDERN:
      return {
        ...state,
        vendorsListExceptChildern: [],
      };
    case CHANGE_VENDOR_LEVEL_ACTION:
      return {
        ...state,
        levelChangeInfo: action.payload,
      };
    case RESET_CHANGE_VENDOR_LEVEL_ACTION:
      return {
        ...state,
        levelChangeInfo: undefined,
      };
    //Client Maintenance Reducers
    case LOAD_CLIENT_MAINTENANCE_LIST:
      return {
        ...state,
        clientMaintenanceList: action.payload,
      };
    case RESET_CLIENT_MAINTENANCE_LIST:
      return {
        ...state,
        clientMaintenanceList: [],
      };
    case LOAD_CLIENT_DATA_LIST:
      return {
        ...state,
        clientDataList: action.payload,
      };
    case RESET_CLIENT_DATA_LIST:
      return {
        ...state,
        clientDataList: [],
      };
    case LOAD_CLIENT_COUNT:
      return {
        ...state,
        clientCount: action.payload,
      };
    case RESET_CLIENT_COUNT:
      return {
        ...state,
        clientCount: undefined,
      };
    case LOAD_PRIMARY_CONTACT_CC_FLIST:
      return {
        ...state,
        primaryContactCCFNameList: action.payload,
      };
    case RESET_PRIMARY_CONTACT_CC_FLIST:
      return {
        ...state,
        primaryContactCCFNameList: [],
      };
    case LOAD_PRIMARY_CONTACT_CC_LNAME_LIST:
      return {
        ...state,
        primaryContactCCLNameList: action.payload,
      };
    case RESET_PRIMARY_CONTACT_CC_LNAME_LIST:
      return {
        ...state,
        primaryContactCCLNameList: [],
      };
    case SAVE_CLIENT:
      return {
        ...state,
        clientInfo: action.payload,
      };
    case RESET_CLIENT:
      return {
        ...state,
        clientInfo: [],
      };
    case LOAD_NEW_CLIENT:
      return {
        ...state,
        loadNewClientInfo: action.payload,
      };
    case RESET_NEW_CLIENT:
      return {
        ...state,
        loadNewClientInfo: [],
      };
    case LOAD_VENDOR_SERVICE:
      return {
        ...state,
        vendorService: action.payload,
      }
    case LOAD_STATE_COUNTY_LIST:
      return {
        ...state,
        loadStateCountyList: action.payload,
      }
    case LOAD_ZIPCODE_LIST:
      return {
        ...state,
        zipCodeList: action.payload,
      }
    case RESET_ZIPCODE_LIST:
      return {
        ...state,
        zipCodeList: [],
      }
    case LOAD_COUNTY_LIST:
      return {
        ...state,
        countyList: action.payload,
      }
    case RESET_COUNTY_LIST:
      return {
        ...state,
        countyList: [],
      }
    case LOAD_CLIENT_FEES_DATA:
      return {
        ...state,
        clientFeesData: action.payload,
      };
    case RESET_CLIENT_FEES_DATA:
      return {
        ...state,
        clientFeesData: [],
      };
    case LOAD_CLIENT_TIER_GRID_DATA:
      return {
        ...state,
        clientTierGridData: action.payload,
      };
    case RESET_CLIENT_TIER_GRID_DATA:
      return {
        ...state,
        clientTierGridData: [],
      };
    case LOAD_CLIENT_SERVICE_DATA:
      return {
        ...state,
        loadClientServicesData: action.payload,
      };
    case RESET_CLIENT_SERVICE_DATA:
      return {
        ...state,
        loadClientServicesData: [],
      };
    case SAVE_END_CLIENT_TIER:
      return {
        ...state,
        endClientTierInfo: action.payload,
      };
    case RESET_END_CLIENT_TIER:
      return {
        ...state,
        endClientTierInfo: undefined,
      };
    case SAVE_CLIENT_TIER_GRID:
      return {
        ...state,
        saveClientTierInfo: action.payload,
      };
    case RESET_CLIENT_TIER_GRID:
      return {
        ...state,
        saveClientTierInfo: undefined,
      };
    case SAVE_CLIENT_SERIVES:
      return {
        ...state,
        saveClientServicesInfo: action.payload,
      };
    case RESET_CLIENT_SERVICES:
      return {
        ...state,
        saveClientServicesInfo: undefined,
      };
    default:
      return { ...state };
  }
};
const reducers = combineReducers({
  fast360MaintenanceReducer: fast360MaintenanceReducer,
});

export default reducers;