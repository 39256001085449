import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
    Row, Col, Button, Form, FormGroup, Label, Input, ListGroup,
    ListGroupItem
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    fast360MaintenanceSelectors,
} from '../store/index';
import { isEmpty } from 'lodash';
import ClientServicesTab from './ClientServicesTab';
import ClientContactsTab from './ClientContactsTab';
import ClientBasicInfoTab from './ClientBasicInfoTab';
import './NewVendorEdit.css';

const ClientEdit = ({ openNewClient,
    onExit,
    openEditClientScreen,
    loadNewClientInfo,
    clientEditItem
}) => {
    const [activeTab, setActiveTab] = useState('clientbasicInfo');
    const [clientEditItemList, setClientEditItemList] = useState(clientEditItem);

    useEffect(() => {
        if (loadNewClientInfo && loadNewClientInfo.length && loadNewClientInfo.length > 0) {
            setClientEditItemList(loadNewClientInfo[0]);
        }

    }, [loadNewClientInfo])

    const renderHeaderTabContent = () => {
        switch (activeTab) {
            case 'clientbasicInfo':
                return <ClientBasicInfoTab openNewClient={openNewClient} onExit={onExit} openEditClientScreen={openEditClientScreen} clientEditItem={clientEditItemList} />
            case 'clientservices':
                return <ClientServicesTab openNewClient={openNewClient} onExit={onExit} openEditClientScreen={openEditClientScreen} clientEditItem={clientEditItemList} />;
            case 'clientcontacts':
                return <ClientContactsTab />;
            default:
                return null;

        }
    }

    return (
        <div>
            <Row className="align-items-right" style={{ backgroundColor: "#5f6f8f", display: "flex", alignItems: "center" }}>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "5px" }}>
                    <span>Client:{loadNewClientInfo && loadNewClientInfo.length > 0 && loadNewClientInfo[0].name ||
                        clientEditItemList && clientEditItemList.name || ''}</span>
                </Col>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "5px" }}>
                    <span>Level:</span>
                </Col>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "15px" }}>
                    <span>
                        0
                    </span>
                </Col>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "5px" }}>
                    <span>Path:</span>
                </Col>
                <Col lg="1" style={{ marginLeft: "auto" }}>
                    <Button
                        style={{ width: '100%', color: "white", backgroundColor: "steelblue" }}
                        type="button"
                        onClick={onExit}

                    >
                        EXIT
                    </Button>
                </Col>
            </Row>
            <div className='tabs-container'>
                <Row className="tabs-header">
                    <Col md={1}>
                        <span
                            className={`tab-button ${activeTab === 'clientbasicInfo' ? 'active' : ''}`}
                            onClick={() => setActiveTab('clientbasicInfo')} >Basic Info</span>

                    </Col>
                    {(openEditClientScreen || (loadNewClientInfo && loadNewClientInfo.length > 0) || !isEmpty(clientEditItemList)) && (
                        <>
                            <Col md={1}>
                                <span
                                    className={`tab-button ${activeTab === 'clientservices' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('clientservices')} >Services</span>

                            </Col>
                            <Col md={1}>
                                <span
                                    className={`tab-button ${activeTab === 'clientcontacts' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('clientcontacts')} >Contacts</span>

                            </Col>
                        </>
                    )}
                </Row>
            </div>
            {renderHeaderTabContent()}
        </div>
    )

}
const mapStateToProps = state => {
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    return {
        loadNewClientInfo: fast360MaintenacneInfo.loadNewClientInfo
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit);
