import React,{ useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { 
    Form, 
    Row, 
    Col, 
    Input, 
    Button, 
    CustomInput, 
    FormGroup,
    Nav,
    NavLink,
    NavItem,
    Label,
    TabContent,
    TabPane,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import PopupModal from 'shared/components/PopupModal';
import AddFeeTable from './AddFeeTable';
import AppTable from 'shared/components/table/AppTable';
import './AddFeeModal.css';
import { 
    fast360MaintenanceActions,
    fast360MaintenanceOperations,
    fast360MaintenanceSelectors,
} from '../store/index';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { assign } from 'lodash';

const AddFeeModal = ({ 
    isOpen,
    onToggle,
    vendorEditItem,
    services,
    subserviceTypes,
    feesTypes,
    states,
    languages,
    stateCountyList,
    zipCodeList,
    countyList,
    loadStateCountyList,
    loadVendorService,
    saveVendorServices,
    loadVendorWithFees,
    loadZipcodeList,
    resetZipcodeList,
    loadCountyList,
    resetCountyList,
 }) => {
    const [chkNationwide, setChkNationwide] = useState(false);
    const [activeTab, setActiveTab] = useState('ACC');
    const [selectedServices, setSelectedServices] = useState([]);
    const [fees, setFees] = useState([]);
    const [feeColumns, setFeeColumns] = useState([]);
    const [feeTypeDropdownOpen, setFeeTypeDropdownOpen] = useState(false);
    const [feeTypeDropdownOptions, setFeeTypeDropdownOptions] = useState([]);
    const [selectedStatesList, setSelectedStatesList] = useState([]);
    const [zip, setZip]  = useState('');
    const [zipCodeId, setZipCodeId] = useState('');
    const [zipEnabled, setZipEnabled] = useState(true);
    const [zipListData, setZipListData] = useState([]);
    const [city, setCity] = useState('');
    const [cityEnabled, setCityEnabled] = useState(false);
    const [county, setCounty] = useState('');
    const [countyEnabled, setCountyEnabled] = useState(true);
    const [countyData, setCountyData] = useState([]);
    const [countyListData, setCountyListData] = useState([]);
    const [showCountyList, setShowCountyList] = useState(false);
    const [state, setState] = useState('');
    const [stateData, setStateData] = useState([]);
    const [stateEnabled, setStateEnabled] = useState(false);
    const [showStateList, setShowStateList] = useState(false);
    const [selectedAllStates, setSelectedAllStates] = useState(false);
    const [groupedFees, setGroupedFees] = useState([]);
    const [unfilteredGroupedFees, setUnfilteredGroupedFees] = useState([]);
    const [addedFees, setAddedFees] = useState([]);
    const [unfilteredAddFees, setUnfilteredAddFees] = useState([]);
    const [dateStart, setDateStart] = useState((new Date()).toISOString());
    const [dateEnd, setDateEnd] = useState((new Date(new Date().getFullYear() + '/12/31')).toISOString());
    const [applyDisabled, setApplyDisabled] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [usedCounty, setUsedCounty] = useState([]);
    const [feeGridData, setFeeGridData] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [selectedSubservice, setSelectedSubservice] = useState('');
    const [subservices, setSubservices] = useState([]);
    const [showFeeDropdown, setShowFeeDropdown] = useState(false);

    const shortColumns = [
            {
                header: 'Select',
                render: item => getSelect(item),
            },
            {
                header: 'Subservice',
                render: item => item['subservice'],
                field: 'subservice',
            },
            {
                header: 'FeeType',
                render: item => item['value'],
                field: 'value',
            },
            {
                header: 'Value',
                render: item => getValue(item),
            }
        ];
    const columns = [
        {
            header: 'Subservice',
            render: item => item['subservice'],
            field: 'subservice',
        },
        {
            header: 'FeeType',
            render: item => item['value'],
            field: 'value',
        },
        {
            header: 'Override',
            render: item => getOverride(item),
        },
        {
            header: 'Vendor Discount Type',
            render: item => item['discountedFrom'],
            field: 'discountedFrom',
        },
        {
            header: 'Value',
            render: item => getValue(item),
        },
        {
            header: 'CPT/HCPC',
            render: item => getCPT(item),
        },
        {
            header: 'Modifier',
            render: item => getModifier(item),
        },
        {
            header: 'Description',
            render: item => getDescription(item),
        },
        {
            header: 'Delete',
            render: item => getDelete(item),
        }
    ];

    const zipRef = useRef(null);
    const countyRef = useRef(null);
    
    useEffect(() => {
        const payload = {
            functionName: 'loadVendorService',
        }

        loadVendorService(payload);
        readyCheck();
    }, []);

    useEffect(() => {
        if (isOpen) {

        }
    }, [isOpen])

    useEffect(() => {
        const filteredFees = feesTypes.filter((item) => (item.subservice == selectedSubservice));
        setFees(filteredFees);
        const filteredGroup = feesTypes.filter(groupFilter);
        setGroupedFees(filteredGroup);
        setAddedFees(unfilteredAddFees.filter((item) => (item.subservice == selectedSubservice)));
    }, [feesTypes])

    const groupFilter = (item) => {
        for (const subserv of subservices) {
            if (subserv.value == item.subservice && subserv.service == selectedService) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        setSubservices(subserviceTypes);
        if (selectedService == 'ACC' || selectedService == 'INT' || selectedService == 'MOD' ||
            selectedService == 'SPT' || selectedService == 'TRN') {
            setFeeGridData(groupedFees);
        } else {
            setFeeGridData(addedFees);
        }
    }, [subserviceTypes])

    useEffect(() => {
        const tempFees = unfilteredAddFees.filter((item) => (item.subservice == selectedSubservice));
        setAddedFees(tempFees);
        if (!(selectedService == 'ACC' || selectedService == 'INT' || selectedService == 'MOD' ||
            selectedService == 'SPT' || selectedService == 'TRN')) {
            setFeeGridData(tempFees);
        }
    }, [unfilteredAddFees, selectedService, selectedSubservice])

    useEffect(() => {
        const tempFees = unfilteredGroupedFees.filter(groupFilter);
        setGroupedFees(tempFees);
        if (selectedService == 'ACC' || selectedService == 'INT' || selectedService == 'MOD' ||
            selectedService == 'SPT' || selectedService == 'TRN') {
            setFeeGridData(tempFees);
        }
    }, [unfilteredGroupedFees, selectedService])

    useEffect(() => {
        setStateData(states);
    }, [states])

    useEffect(() => {
        setZipListData(zipCodeList);
    }, [zipCodeList])

    useEffect(() => {
        setCountyListData(countyList);
    }, [countyList])

    const dateFormat = (date) => {
        if (date) {
            let tempDos = (new Date(date)).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
            let dos = tempDos && tempDos.split('/');
            if(dos.length > 1) {
                return dos[2] + '-' + dos[0] + '-' + dos[1];
            } else {
                return tempDos;
            }
        }
        return;
    }

    const updateSelectedSubservice = (subservice) => {
        const filteredFees = feesTypes.filter((item) => (item.subservice == subservice));
        setFees(filteredFees);
        setSelectedSubservice(subservice);        
    }

    const updateSelectedService = (service) => {
        const filteredSubservices = subserviceTypes.filter((item) => (item.service == service));
        setSubservices(filteredSubservices);
        if (service == 'ACC' || service == 'INT' || service == 'MOD' ||
        service == 'SPT' || service == 'TRN') {
            setFeeGridData(groupedFees);
            setShowFeeDropdown(false);
        } else {
            setFeeGridData(addedFees);
            setShowFeeDropdown(true);
        }
        setSelectedService(service);
    }

    const readyCheck = () => {
        if (chkNationwide || selectedStatesList.length > 0 || zip != '' || county != '') {
            var okToSave = false;

            for (const fee of groupedFees) {
                if (fee.selected) {
                    okToSave = true;
                    break;
                }
            }
            if (!okToSave) {
                for (const fee of addedFees) {
                    if (fee.selected) {
                        okToSave = true;
                        break;
                    }
                }
            }

            if (dateStart == '' || dateStart == null || dateEnd == '') {
                okToSave = false;
            }

            if (okToSave) {
                setApplyDisabled(false);
                setSaveDisabled(false);
            } else {
                setApplyDisabled(true);
                setSaveDisabled(true);
            }
        } else {
            setApplyDisabled(true);
            setSaveDisabled(true);
        }

        if (chkNationwide) {
            clearAllStates();
            if (zip != '') {
                setZip('');
            }
            setZipEnabled(false);
            if (city != '') {
                setCity('');
            }
            if (county != '') {
                setCounty('');
            }
            setCountyEnabled(false);
            if (state != '') {
                setState('');
            }
            if (zipCodeId != '') {
                setZipCodeId('');
            }
            if (usedCounty.length > 0) {
                setUsedCounty([]);
            }
            // Clear all selected counties
            const clearedCounty = countyData.map((item) => {item.selected = false; return item;});
            setCountyData(clearedCounty);
            
            // Clear all selected states
            const clearedStates = stateData.map((item) => {item.selected = false; return item;});
            setStateData(clearedStates);

            clearSelects();
        } else {
            if (selectedStatesList.length > 0) {
                if (zip != '') {
                    setZip('');
                }
                setZipEnabled(false);
                if (city != '') {
                    setCity('');
                }
                if (county != '') {
                    setCounty('');
                }
                setCountyEnabled(false);
                if (state != '') {
                    setState('');
                }
                if (zipCodeId != '') {
                    setZipCodeId('');
                }                
            } else {
                setZipEnabled(true);
                setCountyEnabled(true);
            }
        }
    }

    const clearAllStates = () => {
        if (selectedStatesList.length > 0) {
            setSelectedStatesList([]);
        }
    }

    const handleCancel = () => {
        // Reset to start
        setChkNationwide(false);
        setActiveTab('ACC');
        setSelectedServices([]);
        setFees([]);
        setFeeColumns([]);
        setFeeTypeDropdownOpen(false);
        setSelectedStatesList([]);
        setZip('');
        setZipCodeId('');
        setZipEnabled(true);
        setCity('');
        setCityEnabled(false);
        setCounty('');
        setCountyEnabled(true);
        setShowCountyList(false);
        setState('');
        setStateData([]);
        setStateEnabled(false);
        setShowStateList(false);
        setSelectedAllStates(false);
        setStateData(stateData.map(i => { i.selected = false; return i; }))
        //setGroupedFees([]);
        setUnfilteredGroupedFees([]);
        //setAddedFees([]);
        setUnfilteredAddFees([]);
        setDateStart((new Date()).toISOString());
        setDateEnd((new Date(new Date().getFullYear() + '/12/31')).toISOString());
        setApplyDisabled(false);
        setSaveDisabled(false);
        setUsedCounty([]);
        setFeeGridData([]);
        setSelectedService('');
        setSelectedSubservice('');
        setSubservices([]);
        for (const i of services) {
            i.selected = false;
        }
        for (const i of subserviceTypes) {
            i.selected = false;
        }
        setZipListData([]);
        setCountyListData([]);
        clearSelects();
        onToggle();
    }

    const clearSelects = () => {
        zipRef.current.select.clearValue();
        countyRef.current.select.clearValue();
    }

    const onChangeNationwide = (event) => {
        const target = event.target;
        setChkNationwide(target.checked);
    }

    useEffect(() => {
        readyCheck();
    }, [chkNationwide, selectedStatesList, usedCounty, zip, county, dateStart, dateEnd, feeGridData]);

    const toggle = (tab) => {
        if (selectedServices.includes(tab)) {
            if (activeTab !== tab) {
                changeTab(tab);
            }
        }
    }

    const changeTab = (tab) => {
        setActiveTab(tab);
        updateSelectedService(tab);
        updateSelectedSubservice('');        
    }

    const getServiceTypeClassName = (serviceType) => {
        var className = '';
        if (Array.isArray(selectedServices) && selectedServices.includes(serviceType)) {
            className += 'serviceTab';
            if (activeTab == serviceType) {
                className += 'Active';
            }
        } else {
            className += 'serviceTabDisabled';
        }
        return className;
    }

    const onSubserviceSelect = (event, item) => {
        const target = event.target;
        if (target.checked) {
            item.selected = true;
            updateSelectedSubservice(item.value);
            // We are adding a new fee
            setUnfilteredGroupedFees((ugf) => [...ugf, ...feesTypes.filter((i) => i.subservice == item.value)]);
        } else {
            // We are removing a fee  
            item.selected = false;
            deselectFees(item.value);
            updateSelectedSubservice('');
        }
    }

    const onSubserviceClick = (item) => {
        updateSelectedSubservice(item.value);
    }

    const onLanguageFromChange = (item, event) => {
        item.languageFromId = event.target.value;
        item.languageFrom = event.target.options[event.target.selectedIndex].text;
    }

    const onLanguageToChange = (item, event) => {
        item.languageId = event.target.value;
        item.languageTo = event.target.options[event.target.selectedIndex].text;
    }

    const getServiceTypeTabs = () => {
        return (
            <div className='tabs-container'>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('ACC'); }}
                            className={getServiceTypeClassName('ACC')}
                        >Accommodation</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('DGN'); }}
                            className={getServiceTypeClassName('DGN')}
                        >Diagnostic</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('HHC'); }}
                            className={getServiceTypeClassName('HHC')}
                        >HHC</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('INT'); }}
                            className={getServiceTypeClassName('INT')}
                        >Interpretation</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('MOD'); }}
                            className={getServiceTypeClassName('MOD')}
                        >Modification</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('MSE'); }}
                            className={getServiceTypeClassName('MSE')}
                        >MSE</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('SPT'); }}
                            className={getServiceTypeClassName('SPT')}
                        >Special Transportation</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            onClick={() => { toggle('TRN'); }}
                            className={getServiceTypeClassName('TRN')}
                        >Transportation</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ paddingTo: '10px'}}>
                    <TabPane tabId="ACC">
                        <AddFeeTable
                            serviceType="ACC"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                    <TabPane tabId="DGN">
                        <AddFeeTable
                            serviceType="DGN"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                    <TabPane tabId="HHC">
                        <AddFeeTable
                            serviceType="HHC"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                    <TabPane tabId="INT">
                        <AddFeeTable
                            serviceType="INT"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                            languages={languages}
                            onLanguageFromChange={onLanguageFromChange}
                            onLanguageToChange={onLanguageToChange}
                        />
                    </TabPane>
                    <TabPane tabId="MOD">
                        <AddFeeTable
                            serviceType="MOD"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                    <TabPane tabId="MSE">
                        <AddFeeTable
                            serviceType="MSE"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                    <TabPane tabId="SPT">
                        <AddFeeTable
                            serviceType="SPT"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                    <TabPane tabId="TRN">
                        <AddFeeTable
                            serviceType="TRN"
                            subserviceTypes={subservices}
                            onSubserviceSelect={onSubserviceSelect}
                            selectedServices={selectedServices}
                            onSubserviceClick={onSubserviceClick}
                        />
                    </TabPane>
                </TabContent>
            
            </div>
        );
    }

    const getServicesSelect = (item) => {
        return (
            <CustomInput
                id={"chkServiceSelect_" + item.abbreviation}
                name={"chkServiceSelect_" + item.abbreviation}
                checked={item.selected}
                type="checkbox"
                onChange={e => onChangeServicesSelect(e, item)}
                inline
            />
        )
    }

    const onChangeServicesSelect = (event, item) => {
        const target = event.target;
        
        if (target.checked) {
            item.selected = true;
            // Add to checked services
            setSelectedServices(
                (ss) => {
                    const tempArray = [item.abbreviation, ...ss]; 
                    return tempArray;
                }
            );
            if (item.abbreviation != 'AUD' && item.abbreviation != 'DTL') {
                changeTab(item.abbreviation);
            } else {
                updateSelectedService(item.abbreviation);
            }
            // Change Fee columns
            if (item.abbreviation === 'ACC' || item.abbreviation === 'INT' || item.abbreviation === 'MOD' ||
                item.abbreviation === 'SPT' || item.abbreviation === 'TRN') {
                setFeeColumns(shortColumns);
                setUnfilteredGroupedFees((ufg) => {return ufg});
            } else {
                setFeeColumns(columns);
                setUnfilteredAddFees((ufa) => { return ufa});
            }
            updateSelectedSubservice(item.value);
        } else {
            item.selected = false;
            // Remove from checked services
            setSelectedServices((ss) => ss.filter((i) => (i != item.abbreviation)));
            // Deselect subservices
            const deselectedSubservices = subserviceTypes.map((subserv) => {
                if (subserv.service == item.abbreviation) {
                    subserv.selected = false;
                    deselectFees(subserv.value);
                }
                return subserv;
            });
            const filteredSubservices = deselectedSubservices.filter((i) => (i.service == item.abbreviation));
            setSubservices(filteredSubservices);
        }
        readyCheck();
    }

    const deselectFees = (subserv) => {
        setFees((f) => f.map((i) => {
            if (i.subservice == subserv) {
                i.selected = false;
            }
            return i;
        }));
        setUnfilteredGroupedFees((ufg) =>  [...ufg.filter((i) => (i.subservice != subserv))]);
        setUnfilteredAddFees((uaf) => [...uaf.filter((i) => (i.subservice != subserv))]);
    }

    const onServicesRowClick = (item) => {
        changeTab(item.abbreviation);
        if (item.abbreviation == 'ACC' || item.abbreviation == 'INT' || item.abbreviation == 'MOD' ||
            item.abbreviation == 'SPT' || item.abbreviation == ' TRN') {
            setFeeColumns(shortColumns);
            setUnfilteredGroupedFees((ufg) => {return ufg});
        } else {
            setFeeColumns(columns);
            setUnfilteredAddFees((ufa) => { return ufa});
        }
        updateSelectedSubservice(item.value);
    }

    const onClickShowStates = () => {
        if (!showStateList) {
            setShowStateList(true);
        } else {
            setShowStateList(false);
        }
    }

    const onClickShowCounties = () => {
        if (!showCountyList) {
            setShowCountyList(true);
            getCounties();
        } else {
            setShowCountyList(false);
        }
    }

    const getSelect = (item) => {
        return (
            <CustomInput
                id={"chkServiceFee_" + item.id + item.discountedFrom}
                name={"chkServiceFee_" + item.id + item.discountedFrom}
                checked={item.selected}
                type="checkbox"
                onChange={e => onChangeServiceFee(e, item)}
            />
        )
    }

    const onChangeServiceFee = (event, item) => {
        const target = event.target;

        item.selected = target.checked;

        if (item.subservice == 'Audiology') {
            for (const subserv of subserviceTypes) {
                if (subserv.value == 'Audiology') {
                    subserv.selected = target.checked;
                    break;
                }
            }
        } else if (item.subservice == 'Dental') {
            for (const subserv of subserviceTypes) {
                if (subserv.value == 'Dental') {
                    subserv.selected = target.checked;
                    break;
                }
            }
        }

        //setFeeGridData(tempData);
    }

    const getOverride = (item) => {
        return (
            <CustomInput
                id={"chkOverride_" + item.id + item.discountedFrom}
                name={"chkOverride_" + item.id + item.discountedFrom}
                checked={item.lesserOf}
                type="checkbox"
                onChange={e => onChangeServiceOverride(e, item)}
                inline
            />
        )
    }

    const onChangeServiceOverride = (event, item) => {
        const target = event.target;

        item.lesserOf = target.checked;
    }

    const getValue = (item) => {
        var displayData = '';
        if (item.amount == null) {
            item.amount = 0;
        }
        if (item.datatype != null) {
            if (item.datatype == 'Amount') {
                displayData = '$' + item.amount;
            } else if (item.datatype == 'Percent') {
                displayData = item.amount + '%';
            } else {
                displayData = item.amount;
            }
        }
        return (
            <Input
                value={displayData}
                onChange={e => onChangeFeeValue(e, item)}
            />
        )
    }

    const onChangeFeeValue = (event, item) => {
        if (item.datatype != null) {
            if (item.datatype == 'Amount') {
                item.amount = event.target.value.replace('$', '');
            } else if (item.datatype == 'Percent') {
                item.amount = event.target.value.replace('%', '');
            } else {
                item.amount = event.target.value;
            }
        }
        if (item.showvdrdiscount) {
            setFeeGridData((fg) => fg.map(obj => { if (obj.id == item.id && obj.discountedFrom == item.discountedFrom) { obj = item; } return obj;}));
        } else {
            setFeeGridData((fg) => fg.map(obj => { if (obj.id == item.id) { obj = item; } return obj;}));
        }
    }

    const getCPT = (item) => {
        return (
            item.cpts ?
            <Input
                value={item.cpt}
                onChange={e => onChangeCPT(e, item)}
            />
            : null
        )
    }

    const onChangeCPT = (event, item) => {
        item.cpt = event.target.value;
    }

    const getModifier = (item) => {
        return (
            <Input
                id={"ssmodifier_" + item.id}
                name={"ssmodifier_" + item.id}
                value={item.modifier}
                onChange={e => onChangeModifier(e, item)}
                type="select"
                className="dropdown"
            >
                <option value=''></option>
                <option value='New'>New</option>
                <option value='Rental'>Rental</option>
                <option value='Used'>Used</option>
            </Input>
        )
    }

    const onChangeModifier = (event, item) => {
        item.modifier = event.target.value;
    }

    const getDescription = (item) => {
        return (
            <Input
                value={item.description}
                onChange={e => onDescriptionChange(item, e)}
            />
        )
    }

    const onDescriptionChange = (item, event) => {
        item.description = event.target.value;
    }

    const getDelete = (item) => {
        return (
            <Button
                type="button"
                id={"delete_" + item.id}
                onClick = {() => {onDelete(item)}}
            >X</Button>
        )
    }

    const onDelete = item => {
        const filteredAdded = unfilteredAddFees.filter(i => i != item);
        setUnfilteredAddFees(filteredAdded);
        var noMoreFees = true;
        if (selectedService == 'Audiology') {
            for (const i of filteredAdded) {
                if (i.subservice == 'Audiology') {
                    noMoreFees = false;
                }
            }
            if (noMoreFees) {
                for (const i of subserviceTypes) {
                    if (i.value == 'Audiology') {
                        i.selected = false;
                        break;
                    }
                }
            }
        } else if (selectedService == 'Dental') {
            for (const i of filteredAdded) {
                if (i.subservice == 'Dental') {
                    noMoreFees = false;
                }
            }
            if (noMoreFees) {
                for (const i of subserviceTypes) {
                    if (i.value == 'Dental') {
                        i.selected = false;
                        break;
                    }
                }
            }
        }
    }

    const onClear = () => {
        setSelectedService('ACC');
        setSelectedSubservice('');
        setActiveTab('ACC');
        setSelectedServices([]);
        for (const i of services) {
            i.selected = false;
        }
        for (const i of subserviceTypes) {
            i.selected = false;
        }
        for (const fee of fees) {
            fee.selected = false;
        }
        setUnfilteredAddFees([]);
        setUnfilteredGroupedFees([]);
        setChkNationwide(false);
        setZip('');
        setCity('');
        setCounty('');
        setState('');
        setDateStart((new Date()).toISOString());
        setDateEnd((new Date(new Date().getFullYear() + '/12/31')).toISOString());
        setUsedCounty([]);
        clearAllStates();
        setSelectedAllStates(false);
        setStateData(stateData.map(i => { i.selected = false; return i; }))
        setZipListData([]);
        setCountyListData([]);
        clearSelects();
    }

    const onApply = () => {
        addFee();
    }

    const onSave = () => {
        addFee();
        onToggle();
        handleCancel();
    }

    const addFee = () => {
        const tempFees = [...addedFees, ...groupedFees];
        var duplicateList = [];
        var tempStateList = selectedStatesList;

        for (const subserv of subserviceTypes) {
            var endLoop = false;
            var subservMatch = false;
            if (subserv.selected != null) {
                if (subserv.selected) {
                    for (const vsubserv of vendorEditItem.serviceList) {
                        if (subserv.id == vsubserv.subserviceId) {
                            subservMatch = true;
                            if (subserv.languageId != null) {
                                if (subserv.languageId != vsubserv.languageId) {
                                    subservMatch = false;
                                    if (subserv.languageId == 0 && vsubserv.allLanguages) {
                                        subservMatch = true;
                                    }
                                }
                                if (subservMatch) {
                                    if (subserv.languageFromId != vsubserv.languageFromId) {
                                        subservMatch = false;
                                        if (subserv.languageFromId == 0 && vsubserv.fromAllLanguages) {
                                            subservMatch = true;
                                        }
                                    }
                                }
                            }
                            if (subservMatch && !endLoop) {
                                endLoop = true;
                                for (const fee of tempFees) {
                                    if (fee.selected != null) {
                                        if (fee.selected) {
                                            if (fee.subservice == subserv.value) {
                                                var newFee = {};
                                                if (fee.discountedFrom != null) {
                                                    newFee.discountedFrom = fee.discountedFrom != "" ? fee.discountedFrom : null;
                                                } else {
                                                    newFee.discountedFrom = null;
                                                }
                                                newFee.feeTypeId = fee.id;
                                                newFee.nationWide = chkNationwide;
                                                newFee.percentDiscount = fee.percentdiscount;
                                                newFee.vendorId = vendorEditItem.id;
                                                newFee.cpt = fee.cpt != null && fee.cpt != "" ? fee.cpt : null;
                                                newFee.amount = fee.amount;
                                                newFee.lesserOf = fee.lesserOf;
                                                newFee.modifier = fee.modifier != null && fee.modifier != "" ? fee.modifier : null;
                                                newFee.description = fee.description != "" ? fee.description : null;
                                                newFee.zipcodeId = zipCodeId != "" ? zipCodeId : null;
                                                newFee.startDate = dateStart != "" ? dateFormat(dateStart) : null;
                                                newFee.endDate = dateEnd != "" ? dateFormat(dateEnd) : null;
                                                newFee.zipcode = zip != "" ? zip : null;
                                                newFee.city = city != "" ? city : null;
                                                newFee.county = county != "" ? county : null;
                                                newFee.stateName = state != "" ? state : null;

                                                if (state != "") {
                                                    for (const item of states) {
                                                        if (item.value == state) {
                                                            newFee.stateId = item.state_id;
                                                        }
                                                    }
                                                } else {
                                                    newFee.stateId = null;
                                                }

                                                if (usedCounty.length > 0) {
                                                    for (const countyObj of usedCounty) {
                                                        newFee.zipCodeId = countyObj.id;
                                                        newFee.zipcode = countyObj.zip;
                                                        newFee.county = countyObj.county;
                                                        newFee.city = countyObj.city;
                                                        for (const item of states) {
                                                            if (item.value == countyObj.state) {
                                                                newFee.stateId = item.state_id;
                                                                newFee.stateName = item.value;
                                                                break;
                                                            }
                                                        }
                                                        var duplicate = false;
                                                        for (const origFee of vsubserv.feeList) {
                                                            var startdt = new Date(newFee.startDate);
                                                            var ostartdt = new Date(origFee.startDate);
                                                            var enddt = new Date(newFee.endDate);
                                                            var oenddt = new Date(origFee.endDate);

                                                            if (startdt < ostartdt) {
                                                                if (enddt >= ostartdt) {
                                                                    duplicate = true;
                                                                }
                                                            } else if (startdt == ostartdt || enddt == oenddt) {
                                                                duplicate = true;
                                                            } else if (startdt > ostartdt && startdt < oenddt) {
                                                                duplicate = true;
                                                            }
                                                            if (duplicate && fee.id == origFee.feeTypeId &&
                                                                newFee.discountedFrom == origFee.discountedFrom &&
                                                                newFee.nationWide == origFee.nationWide &&
                                                                newFee.stateId == origFee.stateId &&
                                                                newFee.zipcodeId == origFee.zipcodeId &&
                                                                newFee.cpt == origFee.cpt &&
                                                                newFee.modifier == origFee.modifier) {
                                                                duplicate = true;
                                                                newFee.service = subserv.service;
                                                                newFee.subserv = subserv.value;
                                                                if (subserv.languageId != null) {
                                                                    newFee.languageTo = subserv.languageTo;
                                                                    newFee.langaugeFrom = subserv.languageFrom;
                                                                }
                                                            } else if (
                                                                fee.id == origFee.feeTypeId &&
                                                                newFee.discountedFrom == origFee.discountedFrom &&
                                                                newFee.nationWide == origFee.nationWide &&
                                                                newFee.stateId == origFee.stateId &&
                                                                newFee.zipcodeId == origFee.zipcodeId &&
                                                                newFee.cpt == origFee.cpt &&
                                                                newFee.modifeir == origFee.modifier &&
                                                                newFee.description == origFee.description &&
                                                                newFee.amount == origFee.amount &&
                                                                newFee.lesserOf == origFee.lesserOf) {
                                                                newFee.id = origFee.id;
                                                                vsubserv.feeList = vsubserv.feeList.filter(i => i != origFee);
                                                                break;
                                                            }
                                                        }

                                                        if (newFee.duplicate == null || newFee.duplicate == false) {
                                                            vsubserv.feeList.push(JSON.parse(JSON.stringify(newFee)));
                                                        } else {
                                                            duplicateList.push(JSON.parse(JSON.stringify(newFee)));
                                                            newFee.duplicate = false;
                                                        }

                                                        for (const stateObj of selectedStatesList) {
                                                            if (stateObj.value == countyObj.state) {
                                                                tempStateList = tempStateList.filter(s => s != stateObj)
                                                                break;
                                                            }
                                                        }
                                                        if (newFee == null) {
                                                            break;
                                                        }
                                                    }
                                                }
                                                if (tempStateList.length > 0) {
                                                    newFee.zipcodeId = null;
                                                    for (const selectedState of tempStateList) {
                                                        newFee.stateId = selectedState.state_id;
                                                        newFee.stateName = selectedState.value;

                                                        var duplicate= false;
                                                        for (const origFee of vsubserv.feeList) {
                                                            var startdt = new Date(newFee.startDate);
                                                            var ostartdt = new Date(origFee.startDate);
                                                            var enddt = new Date(newFee.endDate);
                                                            var oenddt = new Date(origFee.endDate);if (startdt < ostartdt) {
                                                                if (enddt >= ostartdt) {
                                                                    duplicate = true;
                                                                }
                                                            } else if (startdt == ostartdt || enddt == oenddt) {
                                                                duplicate = true;
                                                            } else if (startdt > ostartdt && startdt < oenddt) {
                                                                duplicate = true;
                                                            }
                                                            if (duplicate && fee.id == origFee.feeTypeId &&
                                                                newFee.discountedFrom == origFee.discountedFrom &&
                                                                newFee.nationWide == origFee.nationWide &&
                                                                newFee.stateId == origFee.stateId &&
                                                                newFee.zipcodeId == origFee.zipcodeId &&
                                                                newFee.cpt == origFee.cpt &&
                                                                newFee.modifier == origFee.modifier) {
                                                                duplicate = true;
                                                                newFee.service = subserv.service;
                                                                newFee.subserv = subserv.value;
                                                                if (subserv.languageId != null) {
                                                                    newFee.languageTo = subserv.languageTo;
                                                                    newFee.langaugeFrom = subserv.languageFrom;
                                                                }
                                                            } else if (
                                                                fee.id == origFee.feeTypeId &&
                                                                newFee.discountedFrom == origFee.discountedFrom &&
                                                                newFee.nationWide == origFee.nationWide &&
                                                                newFee.stateId == origFee.stateId &&
                                                                newFee.zipcodeId == origFee.zipcodeId &&
                                                                newFee.cpt == origFee.cpt &&
                                                                newFee.modifeir == origFee.modifier &&
                                                                newFee.description == origFee.description &&
                                                                newFee.amount == origFee.amount &&
                                                                newFee.lesserOf == origFee.lesserOf) {
                                                                newFee.id = origFee.id;
                                                                vsubserv.feeList = vsubserv.feeList.filter(i => i != origFee);
                                                                break;
                                                            }
                                                            if(newFee == null)  {
                                                                break;
                                                            }
                                                        }
                                                        if (newFee.duplicate == null || newFee.duplicate == false) {
                                                            vsubserv.feeList.push(JSON.parse(JSON.stringify(newFee)));
                                                        } else {
                                                            duplicateList.push(JSON.parse(JSON.stringify(newFee)));
                                                            newFee.duplicate = false;
                                                        }
                                                    }
                                                }
                                                if (usedCounty.length == 0 && tempStateList.length == 0) {
                                                    var duplicate = false;
                                                    for (const origFee of vsubserv.feeList) {
                                                        var startdt = new Date(newFee.startDate);
                                                        var ostartdt = new Date(origFee.startDate);
                                                        var enddt = new Date(newFee.endDate);
                                                        var oenddt = new Date(origFee.endDate);
    
                                                        if (startdt < ostartdt) {
                                                            if (enddt >= ostartdt) {
                                                                duplicate = true;
                                                            }
                                                        } else if (startdt == ostartdt || enddt == oenddt) {
                                                            duplicate = true;
                                                        } else if (startdt > ostartdt && startdt < oenddt) {
                                                            duplicate = true;
                                                        }
                                                        if (duplicate && fee.id == origFee.feeTypeId &&
                                                            newFee.discountedFrom == origFee.discountedFrom &&
                                                            newFee.nationWide == origFee.nationWide &&
                                                            newFee.stateId == origFee.stateId &&
                                                            newFee.zipcodeId == origFee.zipcodeId &&
                                                            newFee.cpt == origFee.cpt &&
                                                            newFee.modifier == origFee.modifier) {
                                                            duplicate = true;
                                                            newFee.service = subserv.service;
                                                            newFee.subserv = subserv.value;
                                                            if (subserv.languageId != null) {
                                                                newFee.languageTo = subserv.languageTo;
                                                                newFee.langaugeFrom = subserv.languageFrom;
                                                            }
                                                        } else if (
                                                            fee.id == origFee.feeTypeId &&
                                                            newFee.discountedFrom == origFee.discountedFrom &&
                                                            newFee.nationWide == origFee.nationWide &&
                                                            newFee.stateId == origFee.stateId &&
                                                            newFee.zipcodeId == origFee.zipcodeId &&
                                                            newFee.cpt == origFee.cpt &&
                                                            newFee.modifeir == origFee.modifier &&
                                                            newFee.description == origFee.description &&
                                                            newFee.amount == origFee.amount &&
                                                            newFee.lesserOf == origFee.lesserOf) {
                                                            newFee.id = origFee.id;
                                                            vsubserv.feeList = vsubserv.feeList.filter(i => i != origFee);
                                                            break;
                                                        }
                                                        if (newFee == null) {
                                                            break;
                                                        }
                                                    }

                                                    if (newFee.duplicate == null || newFee.duplicate == false) {
                                                        vsubserv.feeList.push(JSON.parse(JSON.stringify(newFee)));
                                                    } else {
                                                        duplicateList.push(JSON.parse(JSON.stringify(newFee)));
                                                        newFee.duplicate = false;
                                                    }
                                                }
                                            }                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (!subservMatch && !endLoop) {
                        var newService = {};
                        newService.subserviceId = subserv.id;
                        newService.vendorId = vendorEditItem.id;
                        newService.feeList = [];
                        if (subserv.languageId != null) {
                            if (subserv.languageId == 0) {
                                newService.allLanguages = true;
                            } else {
                                newService.languageId = subserv.languageId;
                                newService.allLanguages = false;
                            }
                            if (subserv.languageFromId == 0) {
                                newService.fromAllLanguages = true;
                            } else {
                                newService.languageFromId = subserv.languageFromId;
                                newService.fromAllLanguages = false;
                            }
                        }
                        for (const fee of tempFees) {
                            if (fee.selected != null) {
                                if (fee.selected) {
                                    if (fee.subservice == subserv.value) {
                                        var newFee = {};
                                        newFee.discountedFrom = (fee.discountedFrom != "" ? fee.discountedFrom : null);
                                        newFee.feeTypeId = fee.id;
                                        newFee.nationWide = chkNationwide;
                                        newFee.percentDiscount = fee.percentdiscount;
                                        newFee.vendorId = vendorEditItem.id;
                                        newFee.zipcodeId = zipCodeId;
                                        newFee.amount = fee.amount;
                                        newFee.lesserOf = fee.lesserOf;
                                        newFee.modifier = fee.modifier != "" ? fee.modifier : null;
                                        newFee.description = fee.description != "" ? fee.description : null;
                                        newFee.startDate = dateStart != "" ? dateFormat(dateStart) : null;
                                        newFee.endDate = dateEnd != "" ? dateFormat(dateEnd) : null;
                                        if (state != "") {
                                            for (const item of states) {
                                                if (item.value == state) {
                                                    newFee.stateId = item.state_id;
                                                    break;
                                                }
                                            }
                                        }
                                        if (usedCounty.length > 0) {
                                            for (const countyObj of usedCounty) {
                                                newFee.zipCodeId = countyObj.id;
                                                for (const item of states) {
                                                    if (item.value == countyObj.state) {
                                                        newFee.stateId = item.state_id;
                                                        break;
                                                    }
                                                }
                                                newService.feeList.push(JSON.parse(JSON.stringify(newFee)));

                                                for (const stateObj of selectedStatesList) {
                                                    if (stateObj.value == countyObj.state) {
                                                        tempStateList = tempStateList.filter(s => s != stateObj)
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                        if (tempStateList.length > 0) {
                                            newFee.zipcodeId = null;
                                            for (const selectedState of tempStateList) {
                                                newFee.stateId = selectedState.state_id;
                                                newService.feeList.push(JSON.parse(JSON.stringify(newFee)));
                                            }
                                        }
                                        if (usedCounty.length == 0 && tempStateList.length == 0) {
                                            newService.feeList.push(JSON.parse(JSON.stringify(newFee)));
                                        }
                                    }
                                }
                            }
                        }
                        vendorEditItem.serviceList.push(JSON.parse(JSON.stringify(newService)));
                    }
                }
            }
        }
        setOrders(-1);
    }

    const setOrders = (passedHigh) => {
        var resetHighOrder = false;
        var highestOrder = passedHigh;
        for (const subserv of vendorEditItem.serviceList) {
            for (const fee of subserv.feeList) {
                if ((!fee.hasOwnProperty("feeOrder") || fee.feeOrder == null)) {
                    resetHighOrder = true;
                    if (highestOrder != -1) {
                        highestOrder += 10;
                        fee.feeOrder = highestOrder;
                    } else {
                        getHighestOrder(subserv);
                        return;
                    }
                }
            }
            if (resetHighOrder) {
                highestOrder = -1;
            }
        }
        saveVendor();
    }

    const getHighestOrder = (subserv) => {
        var highestOrder = 0;
        for (const fee of subserv.feeList) {
            if (fee.hasOwnProperty("feeOrder") && fee.feeOrder != null) {
                highestOrder = fee.feeOrder;
            }
        }

        setOrders(highestOrder);
    }

    const saveVendor = () => {
        const payload = {
            functionName: "saveVendorServices",
            id: vendorEditItem.id,
            serviceList: vendorEditItem.serviceList,
        }

        saveVendorServices(payload);

        const reload = {
            functionName: "loadVendorWithFees",
            vendor_id: vendorEditItem.id,
        }

        loadVendorWithFees(reload);
    }

    const onChangeFieldHandler = (fieldName, event) => {
        const target = event.target;
        if (fieldName == 'zip') {
            setZip(target.value);
        } else if (fieldName == 'city') {
            setCity(target.value);
        } else if (fieldName == 'county') {
            setCounty(target.value);
        } else if (fieldName == 'state') {
            setState(target.value);
        }
    }

    const handleFormFields = (event, field, actionType) => {
        if (actionType && actionType.action === "clear") {
            setCity('');
            setCounty('');
            setState('');
            setZip('');
            setZipCodeId('');
            clearAllStates();
            resetZipcodeList();
        } else if (field == 'zip') {
            setCity(event.chr_city);
            setCounty(event.chr_county);
            setState(event.chr_state);
            setZip(event.chr_zipcode);
            setZipCodeId(event.code_id);
            
            clearAllStates();
            resetZipcodeList();
        } else if (field == 'county') {
            setCity(event.chr_city);
            setCounty(event.chr_county);
            setState(event.chr_state);
            setZip(event.chr_zipcode);
            setZipCodeId(event.code_id);
            
            clearAllStates();
            resetCountyList();
        }
        
    }

    const handleTypeAheadSearch = (event, field) => {
        if (event.length > 3) {
            if (field == 'zip') {
                const params = {
                    functionName: 'loadZipcodeList',
                    zipCode: event
                }
    
                loadZipcodeList(params);
            } else if (field == 'county') {
                const params = {
                    functionName: 'loadCountyList',
                    county: event
                }
    
                loadCountyList(params);
            }
            
        } else if (event.length > 0 && zipCodeList.length && field == 'zip') {
            resetZipcodeList();
        } else if (event.length > 0 && countyList.length && field == 'county') {
            resetCountyList();
        }
    }

    const setStartDate = (date) => {
        const formattedDate = dateFormat(date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
        setDateStart(formattedDate);
    }

    const setEndDate = (date) => {
        const formattedDate = dateFormat(date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
        setDateEnd(formattedDate);
    }

    const toggleFeeTypeDropdown = () => {
        setFeeTypeDropdownOpen(!feeTypeDropdownOpen);
        if (!feeTypeDropdownOpen) {
            setFeeTypeDropdownOptions(fees);
        }
    }

    const getNewObject = (item) => {
        const returnObj = {
            showvdrdiscount: item.showvdrdiscount,
            canduplicate: item.canduplicate,
            cpts: item.cpts,
            datatype: item.datatype,
            service: item.service,
            sortorder: item.sortorder,
            id: item.id,
            value: item.value,
            subservice: item.subservice,
            selected: true,
        }
        return returnObj;
    }

    const feeTypeDropdownSelect = (item) => {
        // as: addFeeToGrid(event:MouseEvent);
        var okToAddFee = true;
        if (!item.canduplicate) {
            for(const addedFee of addedFees) {
                if (addedFee.value == item.value) {
                    okToAddFee = false;
                    break;
                }
            }
        }
        if (okToAddFee) {
            var newFee = getNewObject(item);
            var tempAddFees = [];
            if (newFee.showvdrdiscount) {
                newFee.discountedFrom = 'FS';
                tempAddFees.push(newFee);
                newFee = getNewObject(item);
                newFee.discountedFrom = 'UC';
                tempAddFees.push(newFee);
                newFee = getNewObject(item);
                newFee.discountedFrom = 'MCA';
                tempAddFees.push(newFee);
                newFee = getNewObject(item);
                newFee.discountedFrom = 'Bill Charges';
                tempAddFees.push(newFee);
                newFee = getNewObject(item);
                newFee.discountedFrom = 'OWCP';
            }
            tempAddFees.push(newFee);
            setUnfilteredAddFees((uaf) => [...uaf, ...tempAddFees]);

            if (selectedService == 'AUD') {
                setSubservices((ss) => ss.map(i => { if (i.value == 'Audiology') { i.selected = true } return i;}))
            } else if (selectedService == 'DTL') {
                setSubservices((ss) => ss.map(i => { if (i.value == 'Dental') { i.selected = true } return i;}))
            }
        }
    }

    const getStateInclude = (item) => {
        return (
            <CustomInput
                id={"chkState" + item.state_id}
                name={"chkState" + item.state_id}
                checked={item.selected || selectedAllStates }
                disabled={chkNationwide}
                type="checkbox"
                onChange={event => { onStateSelect(event, item)}}
                inline
            />
        )
    }

    const onStateSelect = (event, item) => {
        const target = event.target
        item.selected = target.checked;
        if (target.checked) {
            setSelectedStatesList([...selectedStatesList, item]);
        } else {
            setSelectedStatesList(selectedStatesList.filter(state => state.state_id != item.state_id));
        }
    }

    const onClickSelectAllStates = () => {
        setSelectedStatesList(state)
        setSelectedAllStates(true);
        setStateData(stateData.map(i => { i.selected = true; return i; }))
    }

    const onClickDeselectAllStates = () => {
        setSelectedStatesList([]);
        setSelectedAllStates(false);
        setStateData(stateData.map(i => { i.selected = false; return i; }))
    }

    const getStateColumns = () => {
        const columns=[
            {
                header:'State',
                render: item => item['value'],
                field: 'value',
            },
            {
                header: 'Include',
                render: item => getStateInclude(item),
            }
        ];
        return columns;
    }

    useEffect(() => {
        if (showCountyList) {
            getCounties();
        }
    }, [selectedStatesList])

    const getCounties = () => {
        if (selectedStatesList.length > 0) {
            var stateString = "";
            for (const state of selectedStatesList) {
                if (stateString != "") {
                    stateString += ", ";
                }
                stateString += "\"" + state.value + "\"";
            }
            const payload = {
                functionName: 'loadStateCountyList',
                stateList : stateString,
            }

            loadStateCountyList(payload);
        }
    }

    useEffect(() => {
        setCountyData(stateCountyList);
    }, [stateCountyList])

    const getCountyInclude = (item) => {
        return (
            <CustomInput
                id={"chkCountyInclude_" + item.id}
                name={"chkCountyInclude_" + item.id}
                checked={item.selected}
                disabled={chkNationwide}
                type="checkbox"
                onChange={event => onCountySelect(event, item)}
                inline
            />
        )
    }

    const onCountySelect = (event, item) => {
        const target = event.target;
        item.selected = target.checked;
        if (target.checked) {
            setUsedCounty([...usedCounty, item]);
        } else {
            setUsedCounty(usedCounty.filter(county => county.id != item.id));
        }
    }

    const onCountyGridDoubleClick = (item) => {
        var tempCounty = [];
        const isSelected = item.selected;
        if (!isSelected) {
            for (const countyobj of countyData) {            
                if (countyobj.county == item.county && countyobj.state == item.state && !countyobj.selected) {
                    countyobj.selected = true;
                    tempCounty.push(countyobj);
                }
            }
            setUsedCounty([...usedCounty, ...tempCounty]);
        } else {
            for (const countyobj of countyData) {
                if (countyobj.county == item.county && countyobj.state == item.state && countyobj.selected) {
                    countyobj.selected = false;
                }
            }
            setUsedCounty(usedCounty.filter(county => county.county != item.county || county.state != item.state))
        }
    }

    const getContent = () => {
        return (
            <div>
            <Form>
                <Row>
                    <Col lg="4">
                        <h5>SERVICES</h5>
                        <AppTable
                            data={services}
                            autoPagination={false}
                            columns={[
                                {
                                    header: 'Select',
                                    render: item => getServicesSelect(item),
                                },
                                {
                                    header: 'Service',
                                    render: item => item['value'],
                                }
                            ]}
                            modalHeight={250}
                            rowClickHandler={onServicesRowClick}
                        />
                    </Col>
                    <Col lg="8">
                        {getServiceTypeTabs()}
                    </Col>
                </Row>
            </Form>
            <Form>
                <Row>
                    <Col>
                        <h5>FEES</h5>
                        <CustomInput
                            id="chkNationwide"
                            name="chkNationwide"
                            checked={chkNationwide}
                            onChange={onChangeNationwide}
                            type="checkbox"
                            label="Nationwide"
                            inline
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            id="showStates"
                            type="button"
                            onClick={onClickShowStates}
                        >{showStateList ? "Hide States" : "Show States"}</Button>
                    </Col>
                    <Col>
                        <Button
                            id="showCounties"
                            type="button"
                            onClick={onClickShowCounties}
                        >{showCountyList ? "Hide Counties" : "Show Counties"}</Button>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="zip">Zip</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                onChange={(e, actionType) => handleFormFields(e, 'zip', actionType)}
                                placeholder={zip}
                                menuPlacement="bottom"
                                onInputChange={e => handleTypeAheadSearch(e, 'zip')}
                                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                menuIsOpen={zipListData.length && zip === ''}
                                closeMenuOnSelect={true}
                                isDisabled={!zipEnabled}
                                options={zipListData.length
                                  ? zipListData.map(
                                    obj => assign(obj, { label: obj.zip_type_with_county, value: obj.code_id })
                                  )
                                  : []}
                                name="zip"
                                id="zip"
                                ref={zipRef}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="city">City</Label>
                            <Input
                                id="city"
                                name="city"
                                placeholder="City"
                                onChange={(e) => onChangeFieldHandler('city', e)}
                                value={city}
                                disabled={!cityEnabled}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="county">County</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                onChange={(e, actionType) => handleFormFields(e, 'county', actionType)}
                                placeholder={county}
                                menuPlacement="bottom"
                                onInputChange={e => handleTypeAheadSearch(e, 'county')}
                                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                menuIsOpen={countyListData.length && county === ''}
                                closeMenuOnSelect={true}
                                isDisabled={!countyEnabled}
                                options={countyListData.length
                                  ? countyListData.map(
                                    obj => assign(obj, { label: obj.zip_type_with_county, value: obj.code_id })
                                  )
                                  : []}
                                name="county"
                                id="county"
                                ref={countyRef}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="state">State</Label>
                            <Input
                                id="state"
                                name="state"
                                placeholder="State"
                                onChange={(e) => onChangeFieldHandler('state', e)}
                                value={state}
                                disabled={!stateEnabled}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2">
                        <div className='DatePicker'>
                            <DatePicker
                                locale="en"
                                selected={dateStart != null ? new Date(dateStart): new Date()}
                                onChange={date => setDateStart(date)}
                            />
                            To
                            <DatePicker
                                locale="en"
                                selected={dateEnd != null ? new Date(dateEnd) : new Date(new Date().getFullYear() + '/12/31')}
                                onChange={date => setDateEnd(date)}
                                minDate={new Date()}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    {showStateList && (
                    <>
                    <Col lg="3">
                        <AppTable
                            data={stateData}
                            columns={getStateColumns()}
                            autoPagination={false}
                            modalHeight={400}
                        ></AppTable>
                    </Col>
                    <Col lg="1">
                            <Button
                                id="btnSelectAllStates"
                                type="button"
                                onClick={onClickSelectAllStates}
                            >Select All States</Button>
                            <Button
                                id="btnDeselectAllStates"
                                type="button"
                                onClick={onClickDeselectAllStates}
                            >Deselect All States</Button>
                    </Col>
                    </>
                    )}
                    {showCountyList && (
                    <>
                        <Col lg="4">
                            <AppTable
                                data={countyData}
                                columns={[
                                    {
                                        header: 'County',
                                        render: item => item['county'],
                                        field: 'county',
                                    },
                                    {
                                        header: 'State',
                                        render: item => item['state'],
                                        field: 'state',
                                    },
                                    {
                                        header: 'Zip Code',
                                        render: item => item['zip'],
                                        field: 'zip',
                                    },
                                    {
                                        header: 'City',
                                        render: item => item['city'],
                                        field: 'city',
                                    },
                                    {
                                        header: 'Include',
                                        render: item => getCountyInclude(item),
                                    }
                                ]}
                                autoPagination={false}
                                modalHeight={400}
                                rowDoubleClickHandler={onCountyGridDoubleClick}
                            ></AppTable>
                        </Col>
                        <Col lg="4">
                            <AppTable
                                data={usedCounty}
                                columns={[
                                    {
                                        header: 'Used Zip Codes',
                                        render: item => item['zip'],
                                        field: 'zip',
                                    },
                                    {
                                        header: 'City',
                                        render: item=> item['city'],
                                        field: 'city',
                                    },
                                    {
                                        header: 'County',
                                        render: item=> item['county'],
                                        field: 'county',
                                    },
                                    {
                                        header: 'State',
                                        render: item=> item['state'],
                                        field: 'state',
                                    }
                                ]}
                                autoPagination={false}
                                modalHeight={400}
                            ></AppTable>
                        </Col>
                    </>
                    )}
                </Row>
            </Form>
            <Form>
                <Row>
                    <Col lg="1">
                        <h6>Fee Type</h6>
                    </Col>
                    <Col lg="11">
                        {showFeeDropdown && 
                        <div id="feeTypeDropdown">
                            <Dropdown
                                id="feeTypeDropdownObj"
                                isOpen={feeTypeDropdownOpen}
                                toggle={toggleFeeTypeDropdown}
                            >
                                <DropdownToggle>+</DropdownToggle>
                                <DropdownMenu>
                                    {feeTypeDropdownOptions.map((item, index) => {
                                        return (
                                            <span
                                                onClick={() => feeTypeDropdownSelect(item)}
                                                style={{cursor: 'pointer'}}
                                            >
                                                <DropdownItem>{item.value}</DropdownItem>
                                            </span>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>                    
                        <AppTable
                            data={feeGridData}
                            columns={feeColumns}
                            autoPagination={false}
                            modalHeight={200}
                        />
                    </Col>
                </Row>
            </Form>
            <Form>
                <Row>
                    <Col lg="10">

                    </Col>
                    <Col>
                            <Button
                                id="Clear"
                                type="button"
                                onClick={onClear}
                            >Clear</Button>
                            <Button
                                id="Apply"
                                type="button"
                                onClick={onApply}
                                disabled={applyDisabled}
                            >Apply</Button>
                            <Button
                                id="Save"
                                type="button"
                                onClick={onSave}
                                disabled={saveDisabled}
                            >Save</Button>
                    </Col>
                </Row>
            </Form>
            </div>
        )
    }

    return (
        <PopupModal
            content={getContent()}
            externalToggle={handleCancel}
            title="Add Fee"
            isOpen={isOpen}
            size="xl"
        />
    );
}

const mapStateToProps = state => {
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    const vendorService = fast360MaintenacneInfo.vendorService;
    const language = [{
        value: "All Languages",
        lang_id: 0,
    }]
    return {
        services: vendorService != null ? vendorService.ServiceType || [] : [],
        subserviceTypes: vendorService != null ? vendorService.SubserviceType || [] : [],
        feesTypes: vendorService != null ? vendorService.FeeType || [] : [],
        states: vendorService != null ? vendorService.States || [] : [],
        languages: vendorService != null ? (language.concat(vendorService.Languages)) || [] : [],
        stateCountyList: fast360MaintenacneInfo.loadStateCountyList,
        zipCodeList: fast360MaintenacneInfo.zipCodeList,
        countyList: fast360MaintenacneInfo.countyList,
    };
};

const mapDispatchToProps = dispatch => ({
    loadVendorService: data => dispatch(fast360MaintenanceOperations.loadVendorService(data)),
    loadStateCountyList: data => dispatch(fast360MaintenanceOperations.loadStateCountyList(data)),
    saveVendorServices: data => dispatch(fast360MaintenanceOperations.saveVendorServices(data)),
    loadVendorWithFees: (data) => dispatch(fast360MaintenanceOperations.loadVendorWithFees(data)),
    loadZipcodeList: (data) => dispatch(fast360MaintenanceOperations.loadZipcodeList(data)),
    resetZipcodeList: () => dispatch(fast360MaintenanceActions.resetZipcodeList()),
    loadCountyList: (data) => dispatch(fast360MaintenanceOperations.loadCountyList(data)),
    resetCountyList: () => dispatch(fast360MaintenanceActions.resetCountyList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFeeModal);