import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
  Row, Col, Button, Form, FormGroup, Label, Input, ListGroup,
  ListGroupItem
} from 'reactstrap';
import './ContactsTab.css';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  fast360Selectors,
  fast360Operations,
  fast360Actions
} from '../../fast-360/store/index';
import {
  fast360MaintenanceOperations,
  fast360MaintenanceSelectors,
  fast360MaintenanceActions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';

const CustomInput = forwardRef((props, ref) => {
  return <Input ref={ref} {...props} />;
})

const ContactsTab = ({ vendorEditItem,
  primaryContactFNameList,
  primaryContactLNameList,
  getPrimaryContactLNameList,
  getPrimaryContactFNameList,
  resetPrimaryContactFList,
  resetPrimaryContactLList,
  userId,
  saveVendor,
  vendorInfo,
  resetNewContactAction
}) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedSalutation, setSelectedSalutation] = useState('');
  const [contactFName, setContactFName] = useState('');
  const [contactLName, setContactLName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactAltEmail, setContactAltEmail] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [mainPhoneNumber, setMainPhoneNumber] = useState('');
  const [phoneExt, setPhoneExt] = useState('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [showFirstNameSuggestions, setShowFirstNameSuggestions] = useState(false);
  const [showLastNameSuggestions, setShowLastNameSuggestions] = useState(false);
  const [suggestionContact, setSuggestionContact] = useState('');
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const firstNameSuggestionRef = useRef(null);
  const lastNameSuggestionRef = useRef(null);

  const salutationList = [
    {
      "id": 54,
      "list": "salutation",
      "element": "Dr.",
      "sortOrder": 0
    },
    {
      "id": 51,
      "list": "salutation",
      "element": "Mr.",
      "sortOrder": 0
    },
    {
      "id": 52,
      "list": "salutation",
      "element": "Mrs.",
      "sortOrder": 0
    },
    {
      "id": 53,
      "list": "salutation",
      "element": "Ms.",
      "sortOrder": 0
    }
  ]

  useEffect(() => {
    if(!isEmpty(vendorInfo))
    resetNewContactAction();
  }, [vendorInfo])

  const handleSelectContact = (contact) => {
    if (contact && contact.people) {
      setSelectedContact(contact);
      setCellPhoneNumber(contact.people.cellPhone ? formatPhoneNumber(contact.people.cellPhone) : '');
      setMainPhoneNumber(contact.people.phone ? formatPhoneNumber(contact.people.phone) : '');
      setFaxNumber(contact.people.fax ? formatPhoneNumber(contact.people.fax) : '');
      setPhoneExt(contact.people.phoneExt ? contact.people.phoneExt : '');
      setContactAltEmail(contact.people.email2 ? contact.people.email2 : '');
      setContactEmail(contact.people.email1 ? contact.people.email1 : '');
      setContactFName(contact.people.first ? contact.people.first : '');
      setContactLName(contact.people.last ? contact.people.last : '');
      setIsActive(contact.active ? contact.active : false);
      const selectedSalutation = salutationList.find(salutation => salutation.element === contact.people.prefix);
      setSelectedSalutation(selectedSalutation ? selectedSalutation.id : '');
    } else {
      console.error("Invalid contact data:", contact);
    }
  };

  const handleSalutationChange = (event) => {
    setSelectedSalutation(event.target.value);
  }

  const formatPhoneNumber = value => {
    let phoneNumber = value;
    let formattedNumber;
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    const areaCode = phoneNumber.substr(0, 3);
    const firstPart = phoneNumber.substr(3, 3);
    const secondPart = phoneNumber.substr(6, 4);
    if (areaCode.length === 0) {
      formattedNumber = '';
    } else if (areaCode.length < 3) {
      formattedNumber = '(' + areaCode;
    } else if (areaCode.length === 3 && firstPart.length < 3) {
      formattedNumber = '(' + areaCode + ') ' + firstPart;
    } else if (areaCode.length === 3 && firstPart.length === 3) {
      formattedNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
    }

    return formattedNumber;
  };

  const unformatPhoneNumber = formattedNumber => {
    return formattedNumber.replace(/[^0-9]/g, '');
  };

  const handleFieldChange = (e) => {
    const name = e.target.name;
    if (name === "mainPhone") {
      //const phNum = formatPhoneNumber(e.target.value);
      setMainPhoneNumber(e.target.value)
    } else if (name === 'phoneExt') {
      setPhoneExt(e.target.value)
    } else if (name === 'cellPhone') {
      const cellPhNum = formatPhoneNumber(e.target.value);
      setCellPhoneNumber(cellPhNum);
    } else if (name === 'fax') {
      const faxNum = formatPhoneNumber(e.target.value);
      setFaxNumber(faxNum);
    }
  }

  const handleContactEmailChange = (e) => {
    const name = e.target.name;
    if (name === "contactEmail") {
      setContactEmail(e.target.value);
    } else if (name === 'altEmail') {
      setContactAltEmail(e.target.value)
    }
  }

  const onCheckboxClick = (e) => {
    setIsActive(e.target.checked);
  };

  const handleAddNewContact = () => {
    setSelectedContact(null);
    setCellPhoneNumber('');
    setContactAltEmail('');
    setContactEmail('');
    setContactFName('');
    setContactLName('');
    setIsActive(false);
    setSelectedSalutation('');
    setMainPhoneNumber('');
    setFaxNumber('');
    setPhoneExt('');
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setContactFName(value);
    if (value.length >= 2) {
      const params = {
        "name": value
      }
      getPrimaryContactFNameList(params);
      setShowFirstNameSuggestions(true);
    } else {
      setShowFirstNameSuggestions(false);
    }
  };

  const handleSuggestionFNameClick = (suggestion) => {
    setContactFName(suggestion.chr_firstname);
    setContactLName(suggestion.chr_lastname);
    setContactEmail(suggestion.chr_email1);
    setContactAltEmail(suggestion.chr_email2);
    setSuggestionContact(suggestion);
    resetPrimaryContactFList();
    setShowFirstNameSuggestions(false);
  };

  const handleInputBlur = (fieldType) => {
    setTimeout(() => {
      if (fieldType === 'firstname') {
        setShowFirstNameSuggestions(false);
      } else if (fieldType === 'lastname') {
        setShowLastNameSuggestions(false);
      }
    }, 150)
  }


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setContactLName(value);
    if (value.length >= 2) {
      const params = {
        "name": value
      }
      getPrimaryContactLNameList(params);
      setShowLastNameSuggestions(true);
    } else {
      setShowLastNameSuggestions(false);
    }
  };

  const handleSuggestionLNameClick = (suggestion) => {
    setContactFName(suggestion.chr_firstname);
    setContactLName(suggestion.chr_lastname);
    setContactEmail(suggestion.chr_email1);
    setContactAltEmail(suggestion.chr_email2);
    setSuggestionContact(suggestion);
    resetPrimaryContactLList();
    setShowLastNameSuggestions(false);
  };

  const handleClickOutside = (event) => {
    if (!showFirstNameSuggestions) {
      const isFirstNameRef = firstNameRef.current && firstNameRef.current instanceof HTMLElement;
      const isFirstNameSuggestionRef = firstNameSuggestionRef.current && firstNameSuggestionRef.current instanceof HTMLElement;
      if (isFirstNameRef && !isFirstNameRef.current.contains(event.target) &&
        isFirstNameSuggestionRef && !isFirstNameSuggestionRef.current.contains(event.target)) {
        setShowFirstNameSuggestions(false);
        resetPrimaryContactFList(); // Call the reset function here
      }
    }
    if (!showLastNameSuggestions) {
      const isLastNameRef = lastNameRef.current && lastNameRef.current instanceof HTMLElement;
      const isLastNameSuggestionRef = lastNameSuggestionRef.current && lastNameSuggestionRef.current instanceof HTMLElement;
      if (isLastNameRef && !isLastNameRef.current.contains(event.target) &&
        isLastNameSuggestionRef && !isLastNameSuggestionRef.current.contains(event.target)) {
        setShowLastNameSuggestions(false);
        resetPrimaryContactLList();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [showLastNameSuggestions, showFirstNameSuggestions]);


  const onSaveHandler = () => {

    if (vendorEditItem.id < 1 || vendorEditItem.id == null) {
      alert("You must first create and save a vendor.");
    } else {
      let saveObj = {};
      let contactObject = { people: {} };
      if (vendorEditItem.contactList == null) {
        vendorEditItem.contactList = [];
      }

      const getSalutationElement = (id) => {
        const salutationEle = salutationList.find(item => item.id == id);
        return salutationEle ? salutationEle.element : '';
      };

      if (selectedContact == null) {
        // Adding new person
        contactObject.people.first = contactFName;
        contactObject.people.last = contactLName;
        contactObject.people.email1 = contactEmail;
        contactObject.people.phone = unformatPhoneNumber(mainPhoneNumber);
        contactObject.people.phoneExt = phoneExt;
        contactObject.active = isActive;
        contactObject.people.fax = unformatPhoneNumber(faxNumber);
        contactObject.people.prefix = getSalutationElement(selectedSalutation);
        contactObject.contactTypeId = 58;
        contactObject.peopleId = suggestionContact.people_id || 0;
        contactObject.id = suggestionContact.contact_id || 0;
        contactObject.people.id = suggestionContact.people_id || 0;
        contactObject.people.cellPhone = unformatPhoneNumber(cellPhoneNumber);
        contactObject.people.email2 = contactAltEmail;
        vendorEditItem.contactList.push(contactObject);
      } else { // Editing existing contact
        let index = vendorEditItem.contactList.findIndex(contact => {
          return contact.id === (selectedContact ? selectedContact.id : null);
        });

        if (index !== -1) {
          let selectedContact = vendorEditItem.contactList[index];
          console.log("selectedContact 2", selectedContact);
        }

        if (selectedContact.primary == true) {
          vendorEditItem.primaryContact.people.first = contactFName;
          vendorEditItem.primaryContact.people.last = contactLName;
          vendorEditItem.primaryContact.people.email1 = contactEmail;
          vendorEditItem.primaryContact.people.phone = unformatPhoneNumber(mainPhoneNumber);
          vendorEditItem.primaryContact.people.phoneExt = phoneExt;
          vendorEditItem.primaryContact.active = isActive;
          vendorEditItem.primaryContact.people.fax = unformatPhoneNumber(faxNumber);
          vendorEditItem.primaryContact.people.prefix = getSalutationElement(selectedSalutation);
          vendorEditItem.primaryContact.people.cellPhone = unformatPhoneNumber(cellPhoneNumber);
          vendorEditItem.primaryContact.people.email2 = contactAltEmail;
        }

        if (selectedContact.people) {
          selectedContact.people.first = contactFName;
          selectedContact.people.last = contactLName;
          selectedContact.people.email1 = contactEmail;
          selectedContact.people.phone = unformatPhoneNumber(mainPhoneNumber);
          selectedContact.people.phoneExt = phoneExt;
          selectedContact.active = isActive;
          selectedContact.people.fax = unformatPhoneNumber(faxNumber);
          selectedContact.people.prefix = getSalutationElement(selectedSalutation);
          selectedContact.people.cellPhone = unformatPhoneNumber(cellPhoneNumber);
          selectedContact.people.email2 = contactAltEmail;
          selectedContact.people.primary = selectedContact.primary;
        }
      }
      vendorEditItem.gpVendorName = vendorEditItem.gpVendorName ? vendorEditItem.gpVendorName : '';
      vendorEditItem.gpVendorCode = vendorEditItem.gpVendorCode ? vendorEditItem.gpVendorCode : '';
      vendorEditItem.name = vendorEditItem.name ? vendorEditItem.name : '';
      vendorEditItem.dbaName = vendorEditItem.dbaName ? vendorEditItem.dbaName : '';
      vendorEditItem.taxId = vendorEditItem.taxId ? vendorEditItem.taxId : '';
      vendorEditItem.npi = vendorEditItem.npi ? vendorEditItem.npi : '';
      vendorEditItem.pgo = vendorEditItem.pgo ? vendorEditItem.pgo : false;
      vendorEditItem.mainServiceId = vendorEditItem.mainServiceId ? vendorEditItem.mainServiceId : '';
      vendorEditItem.mainService = vendorEditItem.mainService ? vendorEditItem.mainService : '';
      vendorEditItem.accountNumber = vendorEditItem.accountNumber ? vendorEditItem.accountNumber : '';
      vendorEditItem.nationwide = vendorEditItem.nationwide ? vendorEditItem.nationwide : false;
      vendorEditItem.preferred = vendorEditItem.preferred ? vendorEditItem.preferred : false;
      vendorEditItem.webSite = vendorEditItem.webSite ? vendorEditItem.webSite : '';
      vendorEditItem.active = vendorEditItem.active ? vendorEditItem.active : true;
      vendorEditItem.inactiveReason = vendorEditItem.inactiveReason ? vendorEditItem.inactiveReason : '';
      vendorEditItem.contracted = vendorEditItem.contracted ? vendorEditItem.contracted : false;
      vendorEditItem.contractedDate = vendorEditItem.contractedDate ? vendorEditItem.contractedDate : '';
      vendorEditItem.endDate = vendorEditItem.endDate ? vendorEditItem.endDate : '';
      vendorEditItem.credentialedDate = vendorEditItem.credentialedDate ? vendorEditItem.credentialedDate : '';
      vendorEditItem.recredentialedDate = vendorEditItem.recredentialedDate ? vendorEditItem.recredentialedDate : '';
      vendorEditItem.paymentType = vendorEditItem.paymentType ? vendorEditItem.paymentType : '';
      vendorEditItem.prospective = vendorEditItem.prospective ? vendorEditItem.prospective : true;
      vendorEditItem.retrospective = vendorEditItem.retrospective ? vendorEditItem.retrospective : false;
      vendorEditItem.licenseNo = vendorEditItem.licenseNo ? vendorEditItem.licenseNo : '';
      vendorEditItem.terms = vendorEditItem.terms ? vendorEditItem.terms : '';
      vendorEditItem.submissionTerms = vendorEditItem.submissionTerms ? vendorEditItem.submissionTerms : '';
      vendorEditItem.sapId = vendorEditItem.sapId ? vendorEditItem.sapId : '';
      vendorEditItem.paymentMethod = vendorEditItem.paymentMethod ? vendorEditItem.paymentMethod : '';
      vendorEditItem.achAccount = vendorEditItem.achAccount ? vendorEditItem.achAccount : '';
      vendorEditItem.notes = vendorEditItem.notes ? vendorEditItem.notes : '';
      vendorEditItem.alert = vendorEditItem.alert ? vendorEditItem.alert : '';
      vendorEditItem.phoneExt = vendorEditItem.phoneExt ? vendorEditItem.phoneExt : '';
      vendorEditItem.email1 = vendorEditItem.email1 ? vendorEditItem.email1 : '';
      vendorEditItem.email2 = vendorEditItem.email2 ? vendorEditItem.email2 : '';
      vendorEditItem.phone = vendorEditItem.phone ? vendorEditItem.phone : '';
      vendorEditItem.altPhone = vendorEditItem.altPhone ? vendorEditItem.altPhone : '';
      vendorEditItem.cellPhone = vendorEditItem.cellPhone ? vendorEditItem.cellPhone : '';
      vendorEditItem.fax = vendorEditItem.fax ? vendorEditItem.fax : '';
      vendorEditItem.gpName = vendorEditItem.gpName ? vendorEditItem.gpName : '';
      vendorEditItem.gpCode = vendorEditItem.gpCode ? vendorEditItem.gpCode : '';
      saveObj.id = vendorEditItem.id;
      saveObj.vendor = vendorEditItem;
      saveObj.zebraId = userId;
      saveObj.vendorId = vendorEditItem.id;
      saveObj.functionName = "saveVendor";
      saveVendor(saveObj);
    }
  }

  return (
    <div className="contacts-tab">
      <div className="contacts-list">
        <div className="contacts-header">
          <span>CONTACTS</span>
          <Button color="primary" size="sm" className="add-button" onClick={handleAddNewContact}>+</Button>
        </div>
        <ul className="contact-names">
          {vendorEditItem && vendorEditItem.contactList && vendorEditItem.contactList.length > 0 &&
            vendorEditItem.contactList.map((contact, index) => (
              <li key={index} className={`contact-item ${selectedContact === contact ? 'selected' : ''}`} onClick={() => handleSelectContact(contact)}>
                {contact.people.first}, {contact.people.last}
              </li>
            ))
          }
        </ul>
      </div>
      <div className='contact-info-form'>
        <div className='contact-info-header'>
          <span>CONTACT INFO</span>
          <Button color="primary" className="save-button" onClick={onSaveHandler}>Save</Button>
        </div>
        <Form>
          <Row form>
            <Col md={2}>
              <FormGroup>
                <Label for="salutation">salutation</Label>
                <Input
                  type="select"
                  name="salutation"
                  id="salutation"
                  value={selectedSalutation}
                  onChange={handleSalutationChange}>
                  <option value="default">Choose a salutation</option>
                  {salutationList.map((salutation, index) => (
                    <option key={index} value={salutation.id}>
                      {salutation.element}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <CustomInput
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={contactFName || ''}
                  onChange={handleFirstNameChange}
                  onFocus={() => setShowFirstNameSuggestions(true)}
                  onBlur={() => handleInputBlur('firstname')}
                  autoComplete="new-password"
                  ref={firstNameRef}
                />
                {showFirstNameSuggestions && primaryContactFNameList && primaryContactFNameList.length > 0 && (
                  <ListGroup className='suggestion-list' ref={firstNameSuggestionRef}>
                    {primaryContactFNameList.map((suggestion, index) => (
                      <ListGroupItem
                        key={index}
                        onMouseDown={() => handleSuggestionFNameClick(suggestion)}
                      >
                        {suggestion.chr_firstname} {suggestion.chr_lastname}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <CustomInput type="text"
                  name="lastName"
                  id="lastName"
                  onChange={handleLastNameChange}
                  value={contactLName || ''}
                  onFocus={() => setShowLastNameSuggestions(true)}
                  onBlur={() => handleInputBlur('lastname')}
                  autoComplete="new-password"
                  ref={lastNameRef}
                />
                {showLastNameSuggestions && primaryContactLNameList && primaryContactLNameList.length > 0 && (
                  <ListGroup className='suggestion-list' ref={lastNameSuggestionRef}>
                    {primaryContactLNameList.map((suggestion, index) => (
                      <ListGroupItem
                        key={index}
                        onMouseDown={() => handleSuggestionLNameClick(suggestion)}
                      >
                        {suggestion.chr_firstname} {suggestion.chr_lastname}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="contactEmail">Email</Label>
                <Input
                  type="email"
                  name="contactEmail"
                  id="contactEmail"
                  value={contactEmail || ''}
                  onChange={handleContactEmailChange}
                  autoComplete="new-password"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="altEmail">Alternate Email</Label>
                <Input
                  type="email"
                  name="altEmail"
                  id="altEmail"
                  value={contactAltEmail || ''}
                  onChange={handleContactEmailChange}
                  autoComplete="new-password"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="mainPhone">Main Phone</Label>
                <Input
                  type="tel"
                  name="mainPhone"
                  id="mainPhone"
                  value={formatPhoneNumber(mainPhoneNumber) || ''}
                  onChange={handleFieldChange}
                  placeholder="(111) 111-1111"
                  pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                  autoComplete="new-password" />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label for="phoneExt">Ext</Label>
                <Input
                  type="text"
                  name="phoneExt"
                  id="phoneExt"
                  maxLength={10}
                  value={phoneExt || ''}
                  onChange={handleFieldChange}
                  autoComplete="new-password"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="cellPhone">Cell Phone</Label>
                <Input
                  type="text"
                  name="cellPhone"
                  id="cellPhone"
                  value={cellPhoneNumber || ''}
                  onChange={handleFieldChange}
                  placeholder="(111) 111-1111"
                  pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                  autoComplete="new-password" />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="fax">Fax</Label>
                <Input type="text" name="fax" id="fax"
                  value={faxNumber || ''}
                  onChange={handleFieldChange}
                  placeholder="(111) 111-1111"
                  pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                  autoComplete="new-password" />
              </FormGroup>
            </Col>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" name="active" id="active" checked={isActive || ''} onClick={onCheckboxClick} />
                Active
              </Label>
            </FormGroup>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const initialData = fast360Selectors.getFast360InitialData(state);
  const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  return {
    primaryContactFNameList: fast360MaintenacneInfo.primaryContactFNameList,
    primaryContactLNameList: fast360MaintenacneInfo.primaryContactLNameList,
    gpIntegrationList: initialData.gpIntegrationList,
    zipList: fast360Info.zipList,
    userId: user.id,
    newContactInfo: fast360MaintenacneInfo.newContactInfo,
    vendorInfo: fast360MaintenacneInfo.vendorInfo,
    loadNewVendorInfo: fast360MaintenacneInfo.loadNewVendorInfo
  }
}

const mapDispatchToProps = dispatch => ({
  getPrimaryContactFNameList: data => dispatch(fast360MaintenanceOperations.getPrimaryContactFNameList(data)),
  resetPrimaryContactFList: () =>
    dispatch(fast360MaintenanceActions.resetPrimaryContactFList()),
  getPrimaryContactLNameList: data => dispatch(fast360MaintenanceOperations.getPrimaryContactLNameList(data)),
  resetPrimaryContactLList: () =>
    dispatch(fast360MaintenanceActions.resetPrimaryContactLList()),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  saveNewContact: data => dispatch(fast360MaintenanceOperations.saveNewContact(data)),
  saveVendor: data => dispatch(fast360MaintenanceOperations.saveVendor(data)),
  getNewVendor: data => dispatch(fast360MaintenanceOperations.getNewVendor(data)),
  resetNewContactAction: () =>
    dispatch(fast360MaintenanceActions.resetNewContactAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactsTab);