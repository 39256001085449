import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    FormGroup,
    Row,
    Button,
    Form,
    Input,
    CustomInput
} from 'reactstrap';
import AppDateInput from 'shared/components/input/AppDateInput';
import './NewVendorEdit.css';
import {
    fast360MaintenanceOperations,
    fast360MaintenanceSelectors,
    fast360MaintenanceActions,
} from '../store/index';
import AppTable from 'shared/components/table/AppTable';
import { authSelectors } from 'modules/auth/store';
import PopupModal from 'shared/components/PopupModal';
import { isEmpty } from 'lodash';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const ClientServicesTab = ({
    clientEditItem,
    getLoadClientWithFees,
    clientFeesData,
    clientTierGridData,
    loadClientServicesData,
    saveEndClientTier,
    endClientTierInfo,
    getLoadClientService,
    saveClientTierGrid,
    getLoadClientTierGrid,
    saveClientTierInfo,
    userId,
}) => {
    const [tableServicesData, setTableServicesData] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(yesterday);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedTier, setSelectedTier] = useState(null);
    const [previousTierData, setPreviousTierData] = useState(null);
    const [isSaveChangesEnabled, setIsSaveChangesEnabled] = useState(false);

    useEffect(() => {
        if (!isEmpty(clientTierGridData)) {
            setTableServicesData(clientTierGridData);
        }

    }, [clientTierGridData])

    useEffect(() => {
        if (!isEmpty(saveClientTierInfo)) {
            const payload = {
                functionName: "loadClientTierGrid",
                clientid: clientEditItem.id
              }
              getLoadClientTierGrid(payload);
        }

    }, [saveClientTierInfo])

    useEffect(() => {
        if (!isEmpty(clientEditItem)) {
            // const params = {
            //     functionName: "loadClientWithFees",
            //     client_id: clientEditItem.id,
            // }
            // getLoadClientWithFees(params);
            const payload = {
                functionName: "loadClientTierGrid",
                clientid: clientEditItem.id
              }
              getLoadClientTierGrid(payload);
        }

    }, [clientEditItem])

    useEffect(() => {
        console.log("props data", clientTierGridData);
        console.log("updated data", tableServicesData);
    }, [clientTierGridData, tableServicesData]);

    useEffect(() => {
        if (isEmpty(tableServicesData) && !isEmpty(clientTierGridData)) {
            setTableServicesData(clientTierGridData)
        }
    }, [clientTierGridData])

    const getSelectActionColumn = item => {
        return (
            <div className="select-action">
                <CustomInput
                    id={item.service}
                    name={item.service}
                    type="checkbox"
                    checked={item.selected}
                    disabled={true}
                // //   onChange={event => {
                // //     selectCheckBoxHandler(item, event);
                //   }}
                />
            </div>
        );
    };

    const getTierOptions = (service) => {
        if (service === 'Accommodation') {
            return loadClientServicesData.ACCTiers || [];
        } else if (service === 'Interpretation') {
            return loadClientServicesData.INTTiers || [];
        } else if (service === 'Audiology') {
            return loadClientServicesData.AUDTiers || [];
        } else if (service === 'Dental') {
            return loadClientServicesData.DTLTiers || [];
        } else if (service === 'Diagnostic') {
            return loadClientServicesData.DGNTiers || [];
        } else if (service === 'HHC') {
            return loadClientServicesData.HHCTiers || [];
        } else if (service === 'Modification') {
            return loadClientServicesData.MODTiers || [];
        } else if (service === 'MSE') {
            return loadClientServicesData.MSETiers || [];
        } else if (service === 'Special Transport') {
            return loadClientServicesData.SPTTiers || [];
        } else if (service === 'Transportation') {
            return loadClientServicesData.TRNTiers || [];
        }
        return [];
    }

    const getTierDropdown = (item) => {
        const tierOptions = getTierOptions(item.service);

        return (
            <select
                value={item.tier || ''}
                onChange={(e) => handleTierChange(item, e.target.value)}
                style={{ width: '100%', padding: '5px' }}
            >
                <option value="" disabled hidden>Choose...</option>
                {tierOptions.map((tier) => (
                    <option key={tier.id} value={tier.value}>
                        {tier.value}
                    </option>
                ))}
            </select>
        );
    };

    const handleTierChange = (item, selectedTier) => {
        const tierOptions = getTierOptions(item.service);
        const selectedTierDetails = tierOptions.find(tier => tier.value === selectedTier);
        if (!item.tier || item.tier === '' || item.tier === undefined || item.hasTier == undefined || item.hasTier === false) {
            setTableServicesData(prevData =>
                prevData.map(service =>
                    service.service === item.service
                        ? {
                            ...service,
                            tier: selectedTier,
                            startdate: selectedTierDetails ? selectedTierDetails.startdate : service.startdate,
                            enddate: selectedTierDetails ? selectedTierDetails.enddate : service.enddate,
                            tierid: selectedTierDetails ? selectedTierDetails.id : service.tierid
                        }
                        : service
                )
            );
        } else {
            const previousTierData = {
                tier: item.tier,
                startdate: item.startdate,
                enddate: item.enddate,
                tierid: item.tierid
            };
            setSelectedItem(item);
            setSelectedTier(selectedTier);
            setPreviousTierData(previousTierData);
            setShowConfirmationModal(true);

        };
        setIsSaveChangesEnabled(true);
    }

    const getInvoiceTypeDropdwon = (item) => {
        const invoiceTypeOptions = loadClientServicesData.InvoiceType || [];
        const currentBillingTypeValue = invoiceTypeOptions.find(
            (type) => type.id === item.billingTypeId
        )?.value || '';


        return (
            <select
                value={item.billingTypeId || ''}
                onChange={(e) => handleInvoiceTypeChange(item, parseInt(e.target.value, 10))}
                style={{ width: '100%', padding: '5px' }}
            >
                <option value="" disabled hidden>Choose...</option>
                {invoiceTypeOptions.map((type) => (
                    <option key={type.id} value={type.id}>
                        {type.value}
                    </option>
                ))}
            </select>
        )
    }

    const handleInvoiceTypeChange = (item, selectedInvoiceTypeId) => {
        setTableServicesData(prevData =>
            prevData.map(service =>
                service.service === item.service
                    ? {
                        ...service,
                        billingTypeId: selectedInvoiceTypeId,
                    }
                    : service
            )
        );
        setIsSaveChangesEnabled(true);
    }

    const handleDateChange = (index, key, value) => {
        setTableServicesData(prevData =>
            prevData.map((service, i) =>
                i === index
                    ? {
                        ...service,
                        [key]: value,
                    }
                    : service
            )
        )
        setIsSaveChangesEnabled(true);
    }

    const getDateRangeRender = (item, index) => {
        const defaultEndDate = "2022-12-31";
        const effectiveEndDate = item.tier ? item.enddate : defaultEndDate;

        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Input
                    type="date"
                    value={item.startdate || ''}
                    onChange={(e) => handleDateChange(index, 'startdate', e.target.value)}
                    style={{
                        width: '120px',
                        padding: '5px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                    }}
                />
                <span>to</span>
                <Input
                    type="date"
                    value={effectiveEndDate || ''}
                    onChange={(e) => handleDateChange(index, 'enddate', e.target.value)}
                    style={{
                        width: '120px',
                        padding: '5px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                    }}
                />

            </div>

        )
    }



    const getClientMaintenanceServicesTable = () => {
        return (
            <div style={{ marginTop: "20px" }} className='maintenanceTable'>
                <AppTable
                    columns={[
                        {
                            header: 'Has Fees',
                            field: 'selected',
                            render: item => getSelectActionColumn(item),
                        },
                        {
                            header: 'Service',
                            field: 'service',
                            //render: item => renderFeeCountColumn(item),
                        },
                        {
                            header: 'Invoice Type',
                            field: 'billingTypeId',
                            render: item => getInvoiceTypeDropdwon(item),
                        },
                        {
                            header: 'Tier',
                            field: 'tier',
                            render: item => getTierDropdown(item),
                        },
                        {
                            header: 'Date Range',
                            field: 'dateRange',
                            render: (item, index) => getDateRangeRender(item, index),
                        }
                    ]
                    }
                    data={tableServicesData || []}
                    autoPagination={false}
                    resizable={true}
                    sortImmediately={false}
                    // rowDoubleClickHandler={onRowDoubleClick}
                    key="client-maintenance-service-table"
                />
            </div>
        )
    }

    const dateChangeHandler = (date) => {
        setSelectedEndDate(date);
    }

    const confirmationModalContent = () => {
        return (
            <div>
                <span>
                    Please select an end date for the old tier.
                </span>
              
                <AppDateInput
                    onChange={dateChangeHandler}
                    selected={selectedEndDate}
                    //value={selectedEndDate || new Date()}
                    dateFormat="MM/dd/yyyy"
                    style={{ marginTop: "10px" }}
                    maxDate={yesterday}
                />
            </div>
        );
    };

    const confirmSavehandler = () => {
        const tierOptions = getTierOptions(selectedItem.service);
        const selectedTierDetails = tierOptions.find(tier => tier.value === selectedTier);

        setTableServicesData(prevData =>
            prevData.map(service =>
                service.service === selectedItem.service
                    ? {
                        ...service,
                        tier: selectedTier,
                        startdate: selectedTierDetails ? selectedTierDetails.startdate : service.startdate,
                        enddate: selectedTierDetails ? selectedTierDetails.enddate : service.enddate,
                        tierid: selectedTierDetails ? selectedTierDetails.id : service.tierid,
                    }
                    : service
            )
        );
        const dateString = selectedEndDate;
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        const params = {
            functionName: "endClientTier",
            endDate: formattedDate,
            clientId: clientEditItem.id,
            serviceId: selectedItem.serviceid

        }
        saveEndClientTier(params)

        setShowConfirmationModal(false);
    };

    const cancelEndDate = () => {
        setTableServicesData(prevData =>
            prevData.map(service =>
                service.service === selectedItem.service
                    ? {
                        ...service,
                        tier: previousTierData.tier,
                        startdate: previousTierData.startdate,
                        enddate: previousTierData.enddate,
                        tierid: previousTierData.tierid
                    }
                    : service
            )
        );
        setShowConfirmationModal(false);
    }

    const handleSaveChanges = () => {
        const tiers = [];
        const updatedData = tableServicesData.map((item) => {
            const hasTier = !!item.tier && !!item.startdate && !!item.enddate;
            const tierObj = {
                tierId: item.hasOwnProperty("tierid") ? parseInt(item.tierid) : null,
                serviceId: parseInt(item.serviceid),
                startdate: item.hasOwnProperty("startdate") ? item.startdate : null,
                enddate: item.hasOwnProperty("enddate") ? item.enddate : null,
                billingTypeId: item.hasOwnProperty("billingTypeId") ? (item.billingTypeId !== 0 ? item.billingTypeId : null) : null,
                //hasTier: hasTier,
                selected: hasTier || item.selected || false,
            };
            tiers.push(tierObj);
        });
        const params = {
            functionName: "saveClientTierGrid",
            tiers: tiers,
            clientId: clientEditItem.id,
        }
        saveClientTierGrid(params);
        setIsSaveChangesEnabled(false);
    }



    return (
        <div>
            <Form className="vendor-form">
                <div className="form-section">
                    <Row form className="align-items-center">
                        <Col md={6}>
                            <h5>SERVICE SETUP</h5>
                        </Col>
                    </Row>
                    <div>
                        {getClientMaintenanceServicesTable()}
                    </div>
                    <div style={{marginBottom: "70px", display: "flex", justifyContent: "flex-end"}}>
                    <Button color="primary" onClick={() => handleSaveChanges()} disabled={!isSaveChangesEnabled}>
                        Save Changes
                        </Button>
                    </div>
                </div>
            </Form>
            <PopupModal
                content={confirmationModalContent()}
                title="Select End Date"
                isOpen={showConfirmationModal}
                externalToggle={cancelEndDate}
                footerContent={
                    <>
                        <Button color="primary" onClick={confirmSavehandler}>
                            Save
                        </Button>
                    </>
                }
                size="md"
            />
        </div>
    )
}

const mapStateToProps = state => {
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    const user = authSelectors.getUser(state);
    return {
        userId: user.id,
        clientFeesData: fast360MaintenacneInfo.clientFeesData,
        loadClientServicesData: fast360MaintenacneInfo.loadClientServicesData,
        clientTierGridData: fast360MaintenacneInfo.clientTierGridData,
        endClientTierInfo: fast360MaintenacneInfo.endClientTierInfo,
        saveClientTierInfo: fast360MaintenacneInfo.saveClientTierInfo
    }
}

const mapDispatchToProps = dispatch => ({
    getLoadClientWithFees: (data) => dispatch(fast360MaintenanceOperations.getLoadClientWithFees(data)),
    saveEndClientTier: (data) => dispatch(fast360MaintenanceOperations.saveEndClientTier(data)),
    saveClientTierGrid: (data) => dispatch(fast360MaintenanceOperations.saveClientTierGrid(data)),
    getLoadClientTierGrid: (data) => dispatch(fast360MaintenanceOperations.getLoadClientTierGrid(data)),
    getLoadClientService: (data) => dispatch(fast360MaintenanceOperations.getLoadClientService(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientServicesTab);