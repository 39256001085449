import React, { useState, useEffect } from 'react';
import {
    Col,
    Row,
    Button,
} from 'reactstrap';
import './NewVendorEdit.css';
import { connect } from 'react-redux';
import {
    fast360MaintenanceSelectors,
} from '../store/index';
import ServicesTab from './ServicesTab';
import ContactsTab from './ContactsTab';
import BasicInfoTab from './BasicInfoTab';
import ChangeLevel from './ChangeLevel';
import { isEmpty } from 'lodash';

const VendorEdit = ({openNewVendor,
    onExit,
    services,
    vendorEditItem,
    openEditVendorScreen,
    pathVal,
    levelChangeInfo,
    loadNewVendorInfo
}) => {
    const [activeTab, setActiveTab] = useState('basicInfo');
    const [isOpenChangeLevelModal, setIsOpenChangeLevelModal] = useState(false);
    const [vendorEditItemList, setVendorEditItemList] = useState(vendorEditItem);

    useEffect(() => {
        if (loadNewVendorInfo && loadNewVendorInfo.length && loadNewVendorInfo.length > 0) {
            setVendorEditItemList(loadNewVendorInfo[0]);
        }

    }, [loadNewVendorInfo])

    const renderHeaderTabContent = () => {
        switch (activeTab) {
            case 'basicInfo':
                return <BasicInfoTab openNewVendor={openNewVendor} onExit={onExit} services={services} vendorEditItem={vendorEditItemList} openEditVendorScreen={openEditVendorScreen} />
            case 'services':
                return <ServicesTab services={services} vendorEditItem={vendorEditItem}/>;
            case 'contacts':
                return <ContactsTab vendorEditItem={vendorEditItemList}/>;
            default:
                return null;

        }
    }

    const onClickChangeLevel = () => {
        setIsOpenChangeLevelModal(!isOpenChangeLevelModal);
    }

        return (
            <div>
            <Row className="align-items-right" style={{ backgroundColor: "#5f6f8f", display: "flex", alignItems: "center" }}>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "5px" }}>
                    <span>Level:</span>
                </Col>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "15px" }}>
                    <span>
                        {levelChangeInfo
                            ? (vendorEditItem && vendorEditItem.level === 0
                                ? 1
                                : (vendorEditItem && vendorEditItem.level))
                            : (vendorEditItem && vendorEditItem.level ? vendorEditItem.level : 0)}
                    </span>
                </Col>
                <Col lg="auto" style={{ marginTop: "6px", color: "white", paddingRight: "5px" }}>
                    <span>Path:</span>
                </Col>
                <Col lg="auto" style={{ marginTop: "6px", color: "white" }}>
                    <span>{pathVal}</span>

                </Col>
                <Col lg="1" style={{ marginLeft: "auto" }}>
                    <Button
                        style={{ width: '100%', color: "white", backgroundColor: "steelblue" }}
                        type="button"
                        onClick={onExit}

                    >
                        EXIT
                    </Button>
                </Col>
            </Row>
            <div className='tabs-container'>
            <Row className="tabs-header">
                    <Col md={1}>
                        <span
                            className={`tab-button ${activeTab === 'basicInfo' ? 'active' : ''}`}
                            onClick={() => setActiveTab('basicInfo')} >Basic Info</span>

                    </Col>
                    <Col md={1}>
                        <span
                            className={`tab-button ${activeTab === 'services' ? 'active' : ''}`}
                            onClick={() => setActiveTab('services')} >Services</span>

                    </Col>
                    {(openEditVendorScreen || (loadNewVendorInfo && loadNewVendorInfo.length > 0) || !isEmpty(vendorEditItemList)) && (
                        <>
                         <Col md={1}>
                        <span
                            className={`tab-button ${activeTab === 'contacts' ? 'active' : ''}`}
                            onClick={() => setActiveTab('contacts')} >Contacts</span>

                    </Col>
                            <Col md={{ span: 1, offset: 7 }} className="text-right">
                                <button
                                    className='btn btn-primary'
                                    onClick={onClickChangeLevel}
                                >
                                    CHANGE LEVEL
                                </button>
                            </Col>
                            <Col md={1} className="text-right">
                                <button
                                    className={`btn btn-primary ${activeTab === 'attachments' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('attachments')}
                                >
                                    ATTACHMENTS
                                </button>
                            </Col>
                        </>
                    )}
            </Row>
            {renderHeaderTabContent()}
        </div>
        <ChangeLevel isOpen={isOpenChangeLevelModal}
                onClose={onClickChangeLevel} vendorEditItem={vendorEditItem} />
        </div>
        )
}

const mapStateToProps = state => {
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    return {
        levelChangeInfo: fast360MaintenacneInfo.levelChangeInfo,
        loadNewVendorInfo: fast360MaintenacneInfo.loadNewVendorInfo
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorEdit);