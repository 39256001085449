import { ServiceExecutor } from 'core/services/serviceExecutor';
import { commonActions } from 'common/store';
import service from '../services/service';
import actions from './actions';

const getVendorMaintenanceList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getVendorMaintenanceList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceList(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading vendor Maintenance data')
      .build()
      .execute();
  };
};

const advancedQueryVendor = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getVendorMaintenanceList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceList(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading vendor Maintenance data')
      .build()
      .execute();
  };
};

const getPrimaryContactFNameList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPrimaryContactFNameList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadPrimaryContactFList(responseData));
      })
      .withFailureAlert('An error occurred while loading primary contact firstname data')
      .build()
      .execute();
  };
};

const getPrimaryContactLNameList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPrimaryContactLNameList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadPrimaryContactLList(responseData));
      })
      .withFailureAlert('An error occurred while loading primary contact lastname data')
      .build()
      .execute();
  };
};

const getVendorCount = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getVendorCount(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorCount(responseData));
      })
      .withFailureAlert('An error occurred while loading vendor count.')
      .build()
      .execute();
  };
};

const getVendorPath = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getVendorPath(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorPath(responseData));
      })
      .withFailureAlert('An error occurred while loading vendor path.')
      .build()
      .execute();
  };
};

const saveNewContact = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveNewContact(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveNewContactAction(responseData));
      })
      .withFailureAlert('An error occurred while saving new contact data')
      .build()
      .execute();
  };
};

const saveVendor = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveVendor(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveVendorAction(responseData));
      })
      .withSuccessAlert('Successfully Saved Vendor.')
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
};

const loadVendorMaintenanceDocs = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorMiantenanceDocs(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceDocAction(responseData));
      })
      .withFailureAlert('An error occurred while loading docs data')
      .build()
      .execute();
  };
};

const updateVendorMaintenanceDocs = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.updateVendorMiantenanceDocs(data))
      .withResponseHandler(responseData => {
        if (responseData == 'The file has been uploaded successfully') {
        dispatch(actions.updateVendorMaintenanceDocAction(responseData));
      } else {
        dispatch(commonActions.addAlert(new Date().getTime(),'Unable to upload file' , 'danger'));
      }
  })
      .build()
      .execute();
  };
};

const loadVendorMaintenanceDocData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorMiantenanceDocData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceDocDataAction(responseData));
      })
      .withFailureAlert('An error occurred while loading the file data')
      .build()
      .execute();
  };
};

const loadVendorDocTypes = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorDocTypes(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorDocTypesAction(responseData));
      })
      .withFailureAlert('An error occurred while loading the doctypes data')
      .build()
      .execute();
  };
};

const deleteVendorDocument = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deleteVendorDocument(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDeleteVendorDocument(responseData));
      })
      .withFailureAlert('An error occurred while deleting the Documents')
      .build()
      .execute();
  };
};

const getNewVendor = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getNewVendor(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadNewVendorAction(responseData));
      })
      .withFailureAlert('An error occurred while loading new vendor')
      .build()
      .execute();
  };
};

const loadFeeGrid = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadFeeGrid(responseData));
      })
      .withFailureAlert('An error occurred while loading fee data')
      .build()
      .execute();
  };
};

const loadParentFeeGrid = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadParentFeeGrid(responseData));
      })
      .withFailureAlert('An error occurred while loading parent fee data')
      .build()
      .execute();
  };
};

const loadVendorWithFees = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorWithFees(responseData));
      })
      .withFailureAlert('An error occurred while loading parent fee data')
      .build()
      .execute();
  };
}

const saveVendorServices = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericSave(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveVendorServicesAction(responseData));
        const payload = {
          functionName: 'loadVendorWithFees',
          vendor_id: data.id,
        }
        dispatch(loadVendorWithFees(payload));
      })
      .withSuccessAlert('Successfully Saved Vendor.')
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
};

const endVendorFee = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericSave(data))
      .withResponseHandler(responseData => {
        dispatch(actions.endVendorFee(responseData));
      })
      .withSuccessAlert('Successfully Ended Vendor Fee.')
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
};

const queryVendorsExceptChildren = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.queryVendorsExceptChildren(data))
      .withResponseHandler(responseData => {
        dispatch(actions.queryVendorsExceptChildrenAction(responseData));
      })
      .withFailureAlert('An error occurred while loading vendor list except children')
      .build()
      .execute();
  };
};

const changeVendorLevel = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.changeVendorLevel(data))
      .withResponseHandler(responseData => {
        dispatch(actions.changeVendorLevelAction(responseData));
      })
      .withFailureAlert('An error occurred while saving vendor level')
      .build()
      .execute();
  };
};

//Client Maintenance Operations

const getClientMaintenanceList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClientMaintenanceList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientMaintenanceList(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading client Maintenance data')
      .build()
      .execute();
  };
};

const getClientLoadData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClientLoadData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientDataList(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading client data')
      .build()
      .execute();
  };
};

const getClientCount = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClientCount(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientCount(responseData));
      })
      .withFailureAlert('An error occurred while loading client count.')
      .build()
      .execute();
  };
};



const loadVendorService = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorService(responseData));
      })
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
};

const loadStateCountyList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadStateCountyList(responseData));
      })
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
};

const loadZipcodeList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadZipcodeList(responseData));
      })
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
}

const loadCountyList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoadArray(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadCountyList(responseData));
      })
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
}

const getPrimaryContactCCFNameList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPrimaryContactCCFNameList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadPrimaryContactCCFList(responseData));
      })
      .withFailureAlert('An error occurred while loading primary contact firstname data')
      .build()
      .execute();
  };
};

const getPrimaryContactCCLNameList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPrimaryContactCCLNameList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadPrimaryContactCCLList(responseData));
      })
      .withFailureAlert('An error occurred while loading primary contact lastname data')
      .build()
      .execute();
  };
};

const saveClient = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveClient(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveClientAction(responseData));
      })
      .withSuccessAlert('Successfully Saved Client.')
      .withFailureAlert('An error occurred while saving client data')
      .build()
      .execute();
  };
};

const getNewClient = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getNewClient(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadNewClientAction(responseData));
      })
      .withFailureAlert('An error occurred while loading new client')
      .build()
      .execute();
  };
};

const getLoadClientTierGrid = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadClientTierGrid(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientTierGridData(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading client tier grid data')
      .build()
      .execute();
  };
};

const getLoadClientWithFees = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadClientWithFees(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientWithFeesData(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading client with fees data')
      .build()
      .execute();
  };
};

const getLoadClientService = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadClientService(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientServiceData(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading client service data')
      .build()
      .execute();
  };
};


const saveEndClientTier = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveEndClientTier(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveEndClientTierAction(responseData));
      })
      .withSuccessAlert('Successfully Saved End Client Tier.')
      .withFailureAlert('An error occurred while saving end client tier data')
      .build()
      .execute();
  };
};


const saveClientTierGrid = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveClientTierGrid(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveClientTierGridAction(responseData));
      })
      .withSuccessAlert('Successfully Saved Client Tier Grid.')
      .withFailureAlert('An error occurred while saving client tier grid data')
      .build()
      .execute();
  };
};

const saveClientServices = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveClientServices(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveClientServicesAction(responseData));
      })
      .withSuccessAlert('Successfully Saved Client services.')
      .withFailureAlert('An error occurred while saving client services data')
      .build()
      .execute();
  };
};

export default {
  getVendorMaintenanceList,
  advancedQueryVendor,
  getPrimaryContactFNameList,
  getPrimaryContactLNameList,
  getVendorCount,
  getVendorPath,
  saveNewContact,
  saveVendor,
  loadVendorMaintenanceDocs,
  updateVendorMaintenanceDocs,
  loadVendorMaintenanceDocData,
  loadVendorDocTypes,
  deleteVendorDocument,
  getNewVendor,
  loadFeeGrid,
  loadParentFeeGrid,
  loadVendorWithFees,
  saveVendorServices,
  endVendorFee,
  queryVendorsExceptChildren,
  changeVendorLevel,
  getClientMaintenanceList,
  getClientLoadData,
  getClientCount,
  getPrimaryContactCCFNameList,
  getPrimaryContactCCLNameList,
  saveClient,
  getNewClient,
  loadVendorService,
  loadStateCountyList,
  loadZipcodeList,
  loadCountyList,
  getLoadClientTierGrid,
  getLoadClientWithFees,
  getLoadClientService,
  saveEndClientTier,
  saveClientTierGrid,
  saveClientServices,
};