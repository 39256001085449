import helper from 'helper/helper';

const getVendorMaintenanceList = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getPrimaryContactFNameList = async data => {
  const response = await helper.request.post(`/loadFNameList`, data);
  const responseData = response.data ? response.data : [];
  return responseData;
};

const getPrimaryContactLNameList = async data => {
  const response = await helper.request.post(`/loadLNameList`, data);
  const responseData = response.data ? response.data : [];
  return responseData;
};

const getVendorCount = async data => {
  const response = await helper.request.post(`/LoadArray`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getVendorPath = async data => {
  const response = await helper.request.post(`/LoadArray`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const saveNewContact = async data => {
  const response = await helper.request.post(`/saveNewContact`, data);
  const responseData = response.data ? response.data : [];
  return responseData;
};

const saveVendor = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const loadVendorMiantenanceDocs = async data => {
  const response = await helper.request.post(`/LoadArray`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const updateVendorMiantenanceDocs = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const loadVendorMiantenanceDocData = async data => {
  const response = await helper.request.post(`/LoadArray`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const loadVendorDocTypes = async data => {
  const response = await helper.request.post(`/LoadArray`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const deleteVendorDocument = async data => {
  const response = await helper.request.post('/updateDocumentFiles', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getNewVendor = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const genericLoadArray = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData
};

const genericSave = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const queryVendorsExceptChildren = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const changeVendorLevel = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};


// Client Maintenance Services


const getClientMaintenanceList = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};


const getClientLoadData = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getClientCount = async data => {
  const response = await helper.request.post(`/LoadArray`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getPrimaryContactCCFNameList = async data => {
  const response = await helper.request.post(`/loadCCFNameList`, data);
  const responseData = response.data ? response.data : [];
  return responseData;
};

const getPrimaryContactCCLNameList = async data => {
  const response = await helper.request.post(`/loadCCLNameList`, data);
  const responseData = response.data ? response.data : [];
  return responseData;
};

const saveClient = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getNewClient = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadClientTierGrid = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadClientWithFees = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadClientService = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveEndClientTier = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveClientTierGrid = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveClientServices = async data => {
  const response = await helper.request.post(`/SaveObject`, data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};



export default {
  getVendorMaintenanceList,
  getPrimaryContactFNameList,
  getPrimaryContactLNameList,
  getVendorCount,
  getVendorPath,
  saveNewContact,
  saveVendor,
  loadVendorMiantenanceDocs,
  updateVendorMiantenanceDocs,
  loadVendorMiantenanceDocData,
  loadVendorDocTypes,
  deleteVendorDocument,
  getNewVendor,
  queryVendorsExceptChildren,
  changeVendorLevel,
  genericLoadArray,
  genericSave,
  getClientMaintenanceList,
  getClientLoadData,
  getClientCount,
  getPrimaryContactCCFNameList,
  getPrimaryContactCCLNameList,
  saveClient,
  getNewClient,
  getLoadClientTierGrid,
  getLoadClientWithFees,
  getLoadClientService,
  saveEndClientTier,
  saveClientTierGrid,
  saveClientServices,
};