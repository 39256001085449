import React, { useEffect, useState } from 'react';
import { CustomInput, Input } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

const AddFeeTable = ({ 
        serviceType,
        subserviceTypes,
        onSubserviceSelect,
        selectedServices,
        onSubserviceClick,
        languages,
        onLanguageFromChange,
        onLanguageToChange,
    }) => {
    
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (selectedServices.includes(serviceType)){
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }        
    }, [selectedServices])
    
    const filteredData = subserviceTypes.filter(item => { return item.service === serviceType});
    const columns = [
        {
            header: 'Select',
            render: item => getSelect(item),
        },
        {
            header: 'Sub Service',
            render: item => item['value'],
            field: 'value',
        }
    ];
    const intColumns = [
        {
            header: 'Select',
            render: item => getSelect(item),
        },
        {
            header: 'Sub Service',
            render: item => item['value'],
            field: 'value',
        },
        {
            header: 'Source Language',
            render: item => getSourceLanguage(item),
        },
        {
            header: 'Target Language',
            render: item => getTargetLanguage(item),
        }
    ];

    const getSelect = (item) => {
        return (
            <CustomInput
                id={"chkSubService" + item.id}
                name={"chkSubService" + item.id}
                checked={item.selected}
                type="checkbox"
                onChange={event => { onSubserviceSelect(event, item) }}
                inline
            />
        )
    };

    const getSourceLanguage = (item) => {
        return (
            <Input
                id={"langFrom" + item.id }
                name={"langFrom" + item.id }
                type="select"
                onChange={(e) => onLanguageFromChange(item, e)}
                className="dropdown"
                value={item.languageFromId}
            >
            {languages.map((language, index) => (
                <option key={index} value={language.lang_id}>
                    {language.value}
                </option>
            ))}
            </Input>
        )
    }

    const getTargetLanguage = (item) => {
        return (
            <Input
                id={"langTo" + item.id }
                name={"langTo" + item.id }
                type="select"
                onChange={(e) => onLanguageToChange(item, e)}
                className="dropdown"
                value={item.languageId}
            >
            {languages.map((language, index) => (
                <option key={index} value={language.lang_id}>
                    {language.value}
                </option>
            ))}
            </Input>
        )
    }

    return (
        <div>
            {!isDisabled && 
            <AppTable
                data={filteredData}
                columns={serviceType == 'INT' ? intColumns : columns}
                autoPagination={false}
                modalHeight={250}
                rowClickHandler={onSubserviceClick}
            >
                
            </AppTable>
            }
        </div>
    );
}

export default AddFeeTable;