import React, { useEffect, useState } from 'react';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import {
    fast360MaintenanceOperations,
    fast360MaintenanceSelectors,
    fast360MaintenanceActions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';

const ChangeLevel = ({ isOpen, onClose, vendorEditItem,
    queryVendorsExceptChildren,
    vendorsListExceptChildern,
    resetQueryVendorsExceptChildrenAction,
    changeVendorLevel,
    resetChangeVendorLevelAction,
    userId,
    levelChangeInfo,
    getVendorPath,
    pathInfo,
}) => {
    const [isOpenSearchClient, setIsOpenSearchClient] = useState(false);
    const [parentNameField, setParentNameField] = useState('');
    const [start, setStart] = useState(0);
    const [isActiveOkButton, setIsActiveOkButton] = useState(true);
    const [selectedItem, setSelectedItem] = useState({});
    const recordsPerPage = 25;

    useEffect(() => {
        if (isOpen) {
            setIsOpenSearchClient(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            const params = {
                functionName: "queryVendorsExceptChildren",
                vendorid: vendorEditItem ? vendorEditItem.id : '',
                name: '%' + parentNameField + '%',
                start: start.toString(),
            }
            queryVendorsExceptChildren(params);
        }

    }, [start])

    useEffect(() => {
        if(levelChangeInfo != '' && levelChangeInfo != undefined) {
            const pathParam = {
                functionName: "getPath",
                parentid: selectedItem ? selectedItem.id : '',
              }
              getVendorPath(pathParam);
              searchVendorToggle();
        }

    }, [levelChangeInfo])

    const onFormSubmit = e => {
        e.preventDefault();
        const params = {
            functionName: "queryVendorsExceptChildren",
            vendorid: vendorEditItem ? vendorEditItem.id : '',
            name: '%' + parentNameField + '%',
            start: start.toString(),
        }
        queryVendorsExceptChildren(params)

    };

    const searchVendorToggle = () => {
        setIsOpenSearchClient(false);
        onClose();
        resetQueryVendorsExceptChildrenAction();
        setStart(0);
        setParentNameField('');
        setIsActiveOkButton(true);
        setSelectedItem({});
    };

    const handleNext = () => {
        setStart(start + recordsPerPage);
    }

    const handleBack = () => {
        if (start >= recordsPerPage) {
            setStart(start - recordsPerPage);
        }
    };

    const renderAddressColumn = (item) => {
        if (!item || !item.address) {
            return "";
        }

        let addressTxt = "";

        const { street1, street2, zipCode } = item.address;

        if (street1) {
            addressTxt += street1;
            if (street2) {
                addressTxt += ", " + street2;
            }
            addressTxt += " ";
        }

        if (zipCode) {
            const { city, county, state, zip } = zipCode;
            addressTxt += `${city}, ${county} County, ${state}, ${zip}`;
        }

        return addressTxt;
    };

    const onRowClick = (item) => {
     setIsActiveOkButton(false);
     setSelectedItem(item);
    }

    const renderLevelColumn = (item) => {
        let levelVal = '';
        if (item.level == 0) {
            levelVal = "0";
        } else {
            levelVal = item.level;
        }
        return levelVal;
      }

    const getVendorListTable = () => {
        return (
            <div>
                <AppTable
                    modalHeight={400}
                    columns={[
                        {
                            header: 'Name',
                            field: 'name',
                        },
                        {
                            header: 'Level',
                            field: 'level',
                            render: (item) => renderLevelColumn(item)
                        },
                        {
                            header: 'Address',
                            field: '',
                            render: (item) => renderAddressColumn(item)
                        }
                    ]}
                    data={vendorsListExceptChildern || []}
                    autoPagination={true}
                    sortAble={true}
                    resizable={true}
                    rowClickHandler={onRowClick}
                    sortImmediately={false}
                    key="vendor-search-table"
                />
            </div>
        );
    };

    const onParentNameHandler = (event) => {
        const target = event.target;
        let value = target.value;
        setParentNameField(value);
    }

    const onClickOkHandler = () => {
        const params = {
        functionName: "changeVendorLevel",
        vendorId: vendorEditItem ? vendorEditItem.id : '',
        parentId: vendorEditItem ? vendorEditItem.parentId : '',
        newParentId: selectedItem ? selectedItem.id : '',
        newParentLevel: selectedItem ? selectedItem.level : ''
        }
        changeVendorLevel(params);
        setIsOpenSearchClient(false);
    }

    return (
        <>
            <Modal size="lg" isOpen={isOpenSearchClient}>
                <ModalHeader toggle={searchVendorToggle}>Vendor Parent Lookup</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={3}>
                            <Row style={{ marginLeft: '90px' }}>
                                <Label>Vendor Name:</Label>
                            </Row>
                        </Col>
                        <Col xs={4}>
                            <Row>
                                <span>{vendorEditItem.name ? vendorEditItem.name : ''}</span>
                            </Row>
                        </Col>
                        {/* <Col xs={{ span: 2, offset: 2 }} className="text-right">
                            <Button type="button"
                                onClick={searchVendorToggle}
                            >Move to top</Button>
                        </Col> */}
                    </Row>
                    <br>
                    </br>
                    <Row>
                        <Col xs={3}>
                            <Row style={{ marginLeft: '90px' }}>
                                <Label>Vendor Level:</Label>
                            </Row>
                        </Col>
                        <Col xs={3}>
                            <Row>
                                <span>{vendorEditItem.level ? vendorEditItem.level : vendorEditItem.level == 0 ? 0 : ''}</span>
                            </Row>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col xs={3}>
                            <Row style={{ marginLeft: '90px' }}>
                                <Label>Parent Name:</Label>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <Input
                                    value={parentNameField || ''}
                                    type="text"
                                    placeholder="Enter parent name"
                                    onChange={e => onParentNameHandler(e)}
                                />
                            </Row>
                        </Col>
                        <Col xs={2} style={{ marginLeft: "10px" }}>
                            <Row>
                                <Button type='submit' style={{ width: '157px' }} onClick={onFormSubmit}>Search
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    <br>
                    </br>
                    <Row>
                        <Col xs={3}>
                            <Row style={{ marginLeft: '90px' }}>
                                <Label>Results:</Label>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    {getVendorListTable()}
                </ModalBody>
                <ModalFooter className="d-flex flex-column align-items-end">
                    <Row className="w-100 d-flex justify-content-end mb-2">
                        <Col lg="auto" className="d-flex justify-content-end">
                            <Button
                                style={{ marginRight: "10px" }}
                                type="button"
                                onClick={handleBack}
                                title="previous 25 vendors"
                                disabled={start === 0}
                            >
                                {'<'}
                            </Button>
                            <Button
                                type="button"
                                onClick={handleNext}
                                title="next 25 vendors"
                                disabled={vendorsListExceptChildern.length < recordsPerPage}
                            >
                                {'>'}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="w-100 d-flex justify-content-between">
                        <Col lg="auto">
                            <Button
                                type="button"
                                onClick={onClickOkHandler}
                                disabled={isActiveOkButton}
                            >
                                OK
                            </Button>
                        </Col>
                        <Col lg="auto">
                            <Button
                                type="button"
                                onClick={searchVendorToggle}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );

}
const mapStateToProps = state => {
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    const user = authSelectors.getUser(state);
    return {
        userId: user.id,
        vendorsListExceptChildern: fast360MaintenacneInfo.vendorsListExceptChildern,
        levelChangeInfo: fast360MaintenacneInfo.levelChangeInfo,
        pathInfo: fast360MaintenacneInfo.vendorPath,
    };
}

const mapDispatchToProps = dispatch => ({
    queryVendorsExceptChildren: (data) => dispatch(fast360MaintenanceOperations.queryVendorsExceptChildren(data)),
    resetQueryVendorsExceptChildrenAction: () => dispatch(fast360MaintenanceActions.resetQueryVendorsExceptChildrenAction()),
    changeVendorLevel: (data) => dispatch(fast360MaintenanceOperations.changeVendorLevel(data)),
    getVendorPath: data => dispatch(fast360MaintenanceOperations.getVendorPath(data)),
    resetChangeVendorLevelAction: () => dispatch(fast360MaintenanceActions.resetChangeVendorLevelAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLevel);