import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ResendWCEDIModal = ({
    modalOpen,
    resendWCEDI,
    onClose,
}) => {
    /*
    const resendWCEDI = () => {
        const payload = {
            functionName: 'setServiceForWCEDI',
            serviceId: serviceId,
            table: getTableName(abbr),
            followupType: followup,
            followupTypeId: followupId,
            servAbbrv: abbr,
        }

        setServiceForWCEDI(payload);
        onClose();
    }

    const getTableName = (abbr) => {
        if (abbr == 'ACC') {
            return "accommodation";
        } else if (abbr == 'AUD') {
            return "audiology";
        } else if (abbr == 'DTL') {
            return "dental";
        } else if (abbr == 'DGN') {
            return "diagnostics";
        } else if (abbr == 'INT') {
            return "interpretation";
        } else if (abbr == 'TRN') {
            return "transportation";
        } else if (abbr == 'SPT') {
            return "special_transp";
        } else if (abbr == 'MSE' || abbr == 'HHC') {
            return abbr.toLowerCase();
        } else if (abbr == 'MOD') {
            return "modifications";
        }
        return "";
    }
    */

    return (
        <Modal isOpen={modalOpen} size="md" backdrop="static">
            <ModalHeader>Resend for WCEDI</ModalHeader>
            <ModalBody>
                <div>Do you wish to resend to WCEDI?</div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => resendWCEDI()}>Yes</Button>
                <Button color="secondary" onClick={() => onClose()}>No</Button>
            </ModalFooter>
        </Modal>
    );
}

export default ResendWCEDIModal;