export const MODULE_NAME = 'FAST360 MAINTENANCE';
export const LOAD_VENDOR_MAINTENANCE_LIST = 'LOAD_VENDOR_MAINTENANCE_LIST';
export const RESET_VENDOR_MAINTENANCE_LIST = 'RESET_VENDOR_MAINTENANCE_LIST';
export const LOAD_PRIMARY_CONTACT_FLIST = 'LOAD_PRIMARY_CONTACT_FLIST';
export const RESET_PRIMARY_CONTACT_FLIST = 'RESET_PRIMARY_CONTACT_FLIST';
export const LOAD_PRIMARY_CONTACT_LNAME_LIST = 'LOAD_PRIMARY_CONTACT_LNAME_LIST';
export const RESET_PRIMARY_CONTACT_LNAME_LIST = 'RESET_PRIMARY_CONTACT_LNAME_LIST';
export const LOAD_VENDOR_COUNT = 'LOAD_VENDOR_COUNT';
export const RESET_VENDOR_COUNT = 'RESET_VENDOR_COUNT';
export const LOAD_VENDOR_PATH = 'LOAD_VENDOR_PATH';
export const RESET_VENDOR_PATH = 'RESET_VENDOR_PATH';
export const SAVE_NEW_CONTACT = 'SAVE_NEW_CONTACT';
export const RESET_NEW_CONTACT = 'RESET_NEW_CONTACT';
export const SAVE_VENDOR = 'SAVE_VENDOR';
export const RESET_VENDOR = 'RESET_VENDOR';
export const LOAD_VENDOR_MAINTENANCE_DOCS = 'LOAD_VENDOR_MAINTENANCE_DOCS';
export const RESET_VENDOR_MAINTENANCE_DOCS = 'RESET_VENDOR_MAINTENANCE_DOCS';
export const LOAD_VENDOR_MAINTENANCE_FILE_DATA = 'LOAD_VENDOR_MAINTENANCE_FILE_DATA';
export const RESET_UPDATE_VENDOR_MAINTENANCE_DOCS = 'RESET_UPDATE_VENDOR_MAINTENANCE_DOCS';
export const RESET_VENDOR_MAINTENANCE_FILE_DATA = 'RESET_VENDOR_MAINTENANCE_FILE_DATA';
export const UPDATE_VENDOR_MAINTENANCE_DOCS = 'UPDATE_VENDOR_MAINTENANCE_DOCS';
export const LOAD_VENDOR_DOCTYPES = 'LOAD_VENDOR_DOCTYPES';
export const RESET_VENDOR_DOCTYPES = 'RESET_VENDOR_DOCTYPES';
export const RESET_DELETE_VENDOR_DOCUMENT = 'RESET_DELETE_VENDOR_DOCUMENT';
export const UPDATE_DELETE_VENDOR_DOCUMENT = 'UPDATE_DELETE_VENDOR_DOCUMENT';
export const LOAD_NEW_VENDOR = 'LOAD_NEW_VENDOR';
export const RESET_NEW_VENDOR = 'RESET_NEW_VENDOR';
export const LOAD_FEE_GRID = 'LOAD_FEE_GRID';
export const LOAD_PARENT_FEE_GRID = 'LOAD_PARENT_FEE_GRID';
export const RESET_FEES_GRID = 'RESET_FEES_GRID';
export const LOAD_VENDOR_WITH_FEES = 'LOAD_VENDOR_WITH_FEES';
export const SAVE_VENDOR_SERVICES = 'SAVE_VENDOR_SERVICES';
export const END_VENDOR_FEE = 'END_VENDOR_FEE';
export const LOAD_QUERY_VENDORS_EXCEPT_CHILDERN = 'LOAD_QUERY_VENDORS_EXCEPT_CHILDERN';
export const RESET_QUERY_VENDORS_EXCEPT_CHILDERN = 'RESET_QUERY_VENDORS_EXCEPT_CHILDERN';
export const CHANGE_VENDOR_LEVEL_ACTION = 'CHANGE_VENDOR_LEVEL_ACTION';
export const RESET_CHANGE_VENDOR_LEVEL_ACTION = 'RESET_CHANGE_VENDOR_LEVEL_ACTION';
// Client Maintenance constants
export const LOAD_CLIENT_MAINTENANCE_LIST = "LOAD_CLIENT_MAINTENANCE_LIST";
export const RESET_CLIENT_MAINTENANCE_LIST = "RESET_CLIENT_MAINTENANCE_LIST";
export const LOAD_CLIENT_DATA_LIST = "LOAD_CLIENT_DATA_LIST";
export const RESET_CLIENT_DATA_LIST = "RESET_CLIENT_DATA_LIST";
export const LOAD_CLIENT_COUNT = "LOAD_CLIENT_COUNT";
export const RESET_CLIENT_COUNT = "RESET_CLIENT_COUNT";
export const LOAD_PRIMARY_CONTACT_CC_FLIST = "LOAD_PRIMARY_CONTACT_CC_FLIST";
export const RESET_PRIMARY_CONTACT_CC_FLIST = "RESET_PRIMARY_CONTACT_CC_FLIST";
export const LOAD_PRIMARY_CONTACT_CC_LNAME_LIST = "LOAD_PRIMARY_CONTACT_CC_LNAME_LIST";
export const RESET_PRIMARY_CONTACT_CC_LNAME_LIST = "RESET_PRIMARY_CONTACT_CC_LNAME_LIST";
export const SAVE_CLIENT = "SAVE_CLIENT";
export const RESET_CLIENT = "RESET_CLIENT";
export const LOAD_NEW_CLIENT = "LOAD_NEW_CLIENT";
export const RESET_NEW_CLIENT = "RESET_NEW_CLIENT";
export const LOAD_VENDOR_SERVICE = 'LOAD_VENDOR_SERVICE';
export const LOAD_STATE_COUNTY_LIST = 'LOAD_STATE_COUNTY_LIST';
export const LOAD_ZIPCODE_LIST = 'LOAD_ZIPCODE_LIST';
export const RESET_ZIPCODE_LIST = 'RESET_ZIPCODE_LIST';
export const LOAD_COUNTY_LIST = 'LOAD_COUNTY_LIST';
export const RESET_COUNTY_LIST = 'RESET_COUNTY_LIST';
export const LOAD_CLIENT_FEES_DATA = "LOAD_CLIENT_FEES_DATA";
export const RESET_CLIENT_FEES_DATA = "RESET_CLIENT_FEES_DATA";
export const LOAD_CLIENT_TIER_GRID_DATA = "LOAD_CLIENT_TIER_GRID_DATA";
export const RESET_CLIENT_TIER_GRID_DATA = "RESET_CLIENT_TIER_GRID_DATA";
export const LOAD_CLIENT_SERVICE_DATA = "LOAD_CLIENT_SERVICE_DATA";
export const RESET_CLIENT_SERVICE_DATA = "RESET_CLIENT_SERVICE_DATA";
export const SAVE_END_CLIENT_TIER = "SAVE_END_CLIENT_TIER";
export const RESET_END_CLIENT_TIER = "RESET_END_CLIENT_TIER";
export const SAVE_CLIENT_TIER_GRID = "SAVE_CLIENT_TIER_GRID";
export const RESET_CLIENT_TIER_GRID = "RESET_CLIENT_TIER_GRID";
export const SAVE_CLIENT_SERIVES = "SAVE_CLIENT_SERIVES";
export const RESET_CLIENT_SERVICES = "RESET_CLIENT_SERVICES";

export default {
    MODULE_NAME,
  };