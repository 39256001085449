import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  fast360Operations, fast360Actions, fast360Selectors
} from '../store';
import TaskProgressModal from './TaskProgressModal';
import Fast360SaveTask from './Fast360SaveTask';
import Fast360Reason from './Fast360Reason';
import AppDateInput from 'shared/components/input/AppDateInput';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';
import ResendWCEDIModal from './ResendWCEDIModal';

const Fast360EditButtons = ({
  currentTaskData,
  currentTaskId,
  groupProgressData,
  currentUserId,
  worklistData,
  callDynamicLoad,
  loadExistingTasks,
  groupTasksProgression,
  saveFollowUp,
  followupData,
  fromWorklist,
  outcomes,
  setFollowupData,
  setFollowupDate360,
  setFhrs360,
  setFmin360,
  setTimeZ360,
  cmbBillReason,
  cmbAltBillReason,
  cmbMissedBillReason,
  setReasonList,
  reasonList,
  data,
  chrReferenceTable,
  intReferenceTableId,
  referralId,
  onWorklistExit,
  refreshCall,
  alertData,
  rushText,
  getCurrentTaskId,
  selectedItem,
  setOutcomeParent,
  lmsUserId,
  currentWorkList,
  setSelectedItem,
  readyforBilling,
  setReadyForBilling,
  show,
  setShow,
  updateFollowUpDates,
  followUpData,
  followupChangeEvent,
  followupDateEvent,
  setServiceForWCEDI,
}) => {
  const [openTaskModal, setopenTaskModal] = useState(false);
  const [openSaveTaskModal, setopenSaveTaskModal] = useState(false);
  const [openFast360Reason, setOpenFast360Reason] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [taskIncrement, settaskIncrement] = useState(0);
  const [followupType, setFollowupType] = useState('');
  const [followupDate, setFollowupDate] = useState('');
  const [fHrs, setFhrs] = useState('');
  const [fMin, setFmin] = useState('');
  const [timeZ, setTimeZ] = useState('');
  // const [reasonList, setReasonList] = useState(reasonListSet);
  const [currentOutcomeDesc, setCurrentOutcomeDesc] = useState('');
  const [intCurrentOutcome, setCurrentOutcome] = useState(0);
  const [intCurrentReason, setCurrentReason] = useState(0);
  const [openAlertModal, setOpenAlertModal] = useState(true);
  const [assignedUser, setAssignedUser] = useState('');
  const [openResendWCEDI, setOpenResendWCEDI] = useState(false);
  const [followupId, setFollowupId] = useState('');


  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      let assignedUserName = selectedItem?.assignedUser;
      if (assignedUserName === '' || assignedUserName === undefined) {
        if (selectedItem?.currentTaskId === 11 || currentTaskId === 11) {
          // unbilled and unassigned
          assignedUserName = 'Unbilled/Unassigned';
          setShow(true);
        } else if(selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050) {
          // unassigned
          assignedUserName = '';
          setShow(false);
        } else {
          // unassigned
          assignedUserName = 'Unassigned';
          setShow(false);
        }
      }
      setAssignedUser(assignedUserName);
    }
  }, [selectedItem, currentTaskId]);

  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      if(selectedItem.outcomeId === 52) {
        setReasonList(cmbAltBillReason);
      } else {
        setReasonList(reasonList);
      }
    }
  }, [selectedItem])

  useEffect(() => {
    let fTime = followupData?.followupDate?.split(" ")[1];
    let Hrs = fTime?.split(":")[0];
    let fMin = fTime?.split(":")[1];
    let timeZ = Number(Hrs) < 12 ? 'AM' : 'PM';
    let fHrs = Number(Hrs) > 12 ? Number(Hrs) - 12 : Number(Hrs);
    if (Number(Hrs) === 0) {
      fHrs = 12;
      timeZ = 'AM'
    }
    setFhrs(fHrs ? fHrs : 0);
    setFhrs360(fHrs ? fHrs : 0);
    setFmin(fMin ? Number(fMin) : 0);
    setFmin360(fMin ? Number(fMin) : 0);
    setTimeZ((fHrs && fMin) ? timeZ : '');
    setTimeZ360((fHrs && fMin) ? timeZ : '');
    setFollowupType(followupData.followupType);
    setFollowupData(followupData.followupType);
    if (followupData.followupDate) {
      setFollowupDate(new Date(followupData.followupDate));
      setFollowupDate360(new Date(followupData.followupDate));
    }

    let taskPayload = {
      functionName: 'loadExistingTasks',
      zebraUserId: currentUserId,
      referralId: referralId,
      tableAbbrv: chrReferenceTable,
      serviceId: intReferenceTableId,
      from: 'FromEditTasks',
    };
    if (referralId > 0) {
      loadExistingTasks(taskPayload)
    }


  }, [followupData.followupDate ]);

  useEffect(() => {
    let fHr = fHrs;
    fHr = timeZ == 'PM' && Number(fHr) !== 12 ? Number(fHr) + 12 : fHr;
    if (timeZ == 'AM' && Number(fHr) === 12) {
      fHr = 0;
    }
    let hrs = Number(fHr) > 9 ? fHr : `0${fHr}`;
    let min = Number(fMin) > 9 ? fMin : `0${fMin}`;
    let timeStamp = `${hrs}:${min}:00.0`;
    let serviceTypeMap = {
      acc: 'accommodation',
      accommodation: 'accommodation',
      aud: 'audiology',
      audiology: 'audiology',
      dtl: 'dental',
      dental: 'dental',
      dgn: 'diagnostics',
      diagnostics: 'diagnostics',
      int: 'interpretation',
      interpretation: 'interpretation',
      trn: 'transportation',
      transportation: 'transportation',
      spt: 'special_transp',
      'special transport': 'special_transp',
      mse: 'mse',
      hhc: 'hhc',
      mod: 'modifications',
      modification: 'modifications'
    }
    let followUpdate2 = followupDate ? moment(followupDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    let saveFollowupPayload = {
      functionName: 'saveFollowup',
      zebraUserId: currentUserId,
      serviceId: intReferenceTableId,
      table: chrReferenceTable && serviceTypeMap[chrReferenceTable.toLowerCase()] ? serviceTypeMap[chrReferenceTable.toLowerCase()] : '',
      followupDate: `${followUpdate2} ${timeStamp}`,
      // "followupDate": followupData.followupDate,
      followupType: followupType,
      servAbbrv: chrReferenceTable,
      lmsUserId: lmsUserId,
    };
    if (intReferenceTableId > 0 || saveFollowupPayload.table != "") {
      saveFollowUp(saveFollowupPayload);
    }
  }, [followupDate, followupType, fHrs,fMin, timeZ ]);

  const setConfData = () => {
    groupProgressData.forEach(function (item, index) {
      if (currentTaskData[currentTaskId - 1].serviceId === Number(item.serviceId)) {

        let ref = item.confNo.split('-')
        //  setchrReferenceTable(ref[1]);
        //  setintReferenceTableId(ref[2]);
        //  setreferralId(ref[0]);
      }
    });

  }

  const openTaskProgress = (buttonTask) => {

    if ((currentTaskId + taskIncrement > 0) && currentTaskId != undefined) {
      // setConfData();
      if (buttonTask > 4 && (selectedItem.vendor.id == undefined || selectedItem.vendor.id <= 0)) {
        alert("Must have vendor assigned to progress task beyond this point.")
      } else {
        if (buttonTask > 10 && (selectedItem.outcomeId == undefined || selectedItem.outcomeId <= 39)) {
          alert('Please select an outcome before proceeding');
        } else {
          if (currentTaskId /*+ taskIncrement*/ >= buttonTask) {
            setopenTaskModal(!openTaskModal);
          } else if (currentTaskId /*+ taskIncrement*/ + 1 === buttonTask) {
            if (currentTaskId /*+ taskIncrement*/ < 10) {
              setopenSaveTaskModal(!openSaveTaskModal);
            }
          } else if (currentTaskId /*+ taskIncrement*/ + 1 < buttonTask) {
            alert(currentTaskData[currentTaskId /*+ taskIncrement*/].fulldescription + ' is needed to progress tasks.');
          }
        }
      }
    };
  };

  const onChangeOutcome = event => {
    const target = event.target;
    if (((currentTaskId  >= 10) && ((target.value ==="53") || (target.value === "183") || (target.value === "1052") ) ) || ((target.value != "53") && (target.value  != "183") && (target.value != "1052") )) {
      setCurrentOutcomeDesc(target.options[target.selectedIndex].text)
      setOutcomeParent((target.options[target.selectedIndex].text))
      selectedItem.outcomeId = target.value;
      setCurrentOutcome(target.value);
      if (target.value == 52) {
        setReasonList(cmbAltBillReason);
      } else if (target.value == 1050) {
        setReasonList(cmbMissedBillReason);
      } else {
        setReasonList(cmbBillReason);
      }
      setOpenFast360Reason(!openFast360Reason);
      setSelectedItem(selectedItem);
    }else{
      alert('Tasks still need to be completed before progressing this service to the Invoice Coordinator');
    }
  }

  const readyForBill = event => {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    selectedItem.readyForGp = target.checked;
    setReadyForBilling(value)
    setSelectedItem(selectedItem);

  }
  const onChangeOutcomeDefault = event => {
    const target = event.target;
    setCurrentOutcome(target.value);
    if (target.selectedIndex > 0) {
      setCurrentOutcomeDesc(target.options[target.selectedIndex].text)
    } else {
      setCurrentOutcomeDesc('');
    }
  }

  const onChangeReason = event => {
    const target = event.target;
    setCurrentReason(target.value);
    selectedItem.reasonId = target.value;
    setSelectedItem(selectedItem);

  }

  const openSaveTaskProgress = () => {
    if (openTaskModal) {
      setopenTaskModal(!openTaskModal);
    }

    let taskPayload = {
      loadCurrentTaskId:true,
      loadGroupTaskProgression: true,
      loadExistingTasks: true,
      loadReferralAlerts: false,
      loadFollowUpdInfo : false,
      zebraUserId: currentUserId,
      table: '',
      referralId: referralId,
      tableAbbrv: chrReferenceTable,
      serviceId: intReferenceTableId,
      currentTask: currentTaskId + 1,
      referenceTable: chrReferenceTable,
      claimNo: worklistData?.claim?.claimNo,
      userid: currentUserId,
      breadcrumbkey: 'VIEWSERVICE',
      breadcrumbrefTableId: intReferenceTableId,
      breadcrumbrefId: referralId,
      breadcrumbrefTable: chrReferenceTable,
    };
    setTimeout(() => {      
      callDynamicLoad(taskPayload);
    }, 2000)
    /*let taskPayload = {
      "functionName": "loadExistingTasks",
      "zebraUserId": currentUserId,
      "referralId": referralId,
      "tableAbbrv": chrReferenceTable,
      "serviceId": intReferenceTableId

    }
    let groupProgressPayload = {
      "functionName": "groupTasksProgression",
      "zebraUserId": currentUserId,
      "currentTask": currentTaskId  + 1,
      "referenceTable": chrReferenceTable,
      "claimNo": worklistData?.claim?.claimNo,
      "serviceId": intReferenceTableId,
      "where": 'EditButtons'
    }
    let getTaskIdPayload = {
      "functionName": 'getCurrentTaskId',
      "zebraUserId": currentUserId,
      "tableAbbrv": chrReferenceTable,
      "serviceId": intReferenceTableId,
    };*/


    settaskIncrement(taskIncrement + 1);
    /*setTimeout(() => {
      loadExistingTasks(taskPayload);
    }, 2000);
    setTimeout(() => {
      groupTasksProgression(groupProgressPayload)
    }, 1250);
    setTimeout(() => {
      getCurrentTaskId(getTaskIdPayload);
    }, 1750);*/


    if (openSaveTaskModal) {
      setopenSaveTaskModal(!openSaveTaskModal);
    }
  };
  const renderButtonColor = (buttonTask) => {
    if ((currentTaskId /*+ taskIncrement*/ === 0) || currentTaskId === undefined) {
      if (1 === buttonTask) {
        return ({ height: '100%', width: '100%', background: '#F8AB00' })
      } else {
        return ({ height: '100%', width: '100%' })
      }
    } else {
      if (currentTaskId == 13) {
        return ({ height: '100%', width: '100%', background: '#78B28D' })
      } else {
        if (currentTaskId/*+ taskIncrement*/ === buttonTask) {
          return ({ height: '100%', width: '100%', background: '#F8AB00' })
        } else if (currentTaskId /*+ taskIncrement*/ > buttonTask) {
          return ({ height: '100%', width: '100%', background: '#78B28D' })
        } else if ((selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050) && buttonTask === 13) {
          return ({ height: '100%', width: '100%', background: '#78B28D' })  
        } else {
          return ({ height: '100%', width: '100%' })
        }
      }
    }
  }
  const getCompletedDateTime = (arrayNum) => {
    if ((currentTaskId /*+ taskIncrement*/ === 0) || currentTaskId === undefined) {
      return '';
    }
    if (currentTaskData.length > 0) {
      if (currentTaskData[arrayNum]?.dateCompleted === undefined) {
        return '';
      } else {
        return moment(currentTaskData[arrayNum]?.dateCompleted || '').format('MM/DD/YYYY h:mm a');
      }
    } else {
      return '';
    }
  }
  const followupReasonChange = (e) => {
    setFollowupType(e);
    setFollowupData(e);
    followupChangeEvent(true);
    saveFollwUp();
    for (const f of followupData.followupReasons) {
      if (f.chr_description == e) {
        // We found the follow up reason check if we need to resend
        if (f.PK_listelement__state_id == 27 || f.PK_listelement__state_id == 54)
        {
          // Open modal
          setFollowupId(f.PK_listelement__state_id);
          setOpenResendWCEDI(true);          
        }
      }
    }
  }

  const resendWCEDIModalOnClose = () => {
    setOpenResendWCEDI(false);
  }

  const resendWCEDI = () => {
    const payload = {
      functionName: 'setServiceForWCEDI',
      serviceId: intReferenceTableId,
      table: getTableName(chrReferenceTable),
      followupType: followupType,
      followupTypeId: followupId,
      servAbbrv: chrReferenceTable,
    }

    setServiceForWCEDI(payload);
    setOpenResendWCEDI(false);
  }

  const getTableName = (abbr) => {
    if (abbr == 'ACC') {
        return "accommodation";
    } else if (abbr == 'AUD') {
        return "audiology";
    } else if (abbr == 'DTL') {
        return "dental";
    } else if (abbr == 'DGN') {
        return "diagnostics";
    } else if (abbr == 'INT') {
        return "interpretation";
    } else if (abbr == 'TRN') {
        return "transportation";
    } else if (abbr == 'SPT') {
        return "special_transp";
    } else if (abbr == 'MSE' || abbr == 'HHC') {
        return abbr.toLowerCase();
    } else if (abbr == 'MOD') {
        return "modifications";
    }
    return "";
}

  const handleDateChange = (date) => {
    setFollowupDate(date);
    setFollowupDate360(date);
    followupDateEvent(true);
    saveFollwUp();
   
  }
  const handleHrChange = (e) => {
    setFhrs(e.target.value);
    setFhrs360(e.target.value);
    followupDateEvent(true);
    saveFollwUp();
  }
  const handleMinChange = (e) => {
    setFmin(e.target.value);
    setFmin360(e.target.value);
    followupDateEvent(true);
    saveFollwUp();
  }

  const handleRadioChange = (data) => {
    setTimeZ(data);
    setTimeZ360(data);
    followupDateEvent(true);
    saveFollwUp();
  }

  const saveFollwUp=() =>{
    let fHr = fHrs;
    fHr = timeZ == 'PM' && Number(fHr) !== 12 ? Number(fHr) + 12 : fHr;
    if (timeZ == 'AM' && Number(fHr) === 12) {
      fHr = 0;
    }
    let hrs = Number(fHr) > 9 ? fHr : `0${fHr}`;
    let min = Number(fMin) > 9 ? fMin : `0${fMin}`;
    let timeStamp = `${hrs}:${min}:00.0`;
    let serviceTypeMap = {
      acc: 'accommodation',
      accommodation: 'accommodation',
      aud: 'audiology',
      audiology: 'audiology',
      dtl: 'dental',
      dental: 'dental',
      dgn: 'diagnostics',
      diagnostics: 'diagnostics',
      int: 'interpretation',
      interpretation: 'interpretation',
      trn: 'transportation',
      transportation: 'transportation',
      spt: 'special_transp',
      'special transport': 'special_transp',
      mse: 'mse',
      hhc: 'hhc',
      MOD: 'modifications',
      modification: 'modifications'
    }
    let followUpdate2 = followupDate ? moment(followupDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    let saveFollowupPayload = {
      functionName: 'saveFollowup',
      zebraUserId: currentUserId,
      serviceId: intReferenceTableId,
      table: chrReferenceTable && serviceTypeMap[chrReferenceTable.toLowerCase()] ? serviceTypeMap[chrReferenceTable.toLowerCase()] : '',
      followupDate: `${followUpdate2} ${timeStamp}`,
      // "followupDate": followupData.followupDate,
      followupType: followupType,
      servAbbrv: chrReferenceTable,
      lmsUserId: lmsUserId,
    };
    if (intReferenceTableId > 0 || saveFollowupPayload.table != "") {
      saveFollowUp(saveFollowupPayload);
    }
  }

  useEffect(() => {
    if (updateFollowUpDates?.follow) {
      setFollowupDate(new Date(updateFollowUpDates.follow))
      setFollowupDate360(new Date(updateFollowUpDates.follow));
      setFhrs(updateFollowUpDates.stateData.fHrs);
      setFhrs360(updateFollowUpDates.stateData.fHrs);
      setFmin(updateFollowUpDates.stateData.fMin);
      setFmin360(updateFollowUpDates.stateData.fMin);
      setTimeZ(updateFollowUpDates.stateData.timeZ);
      setFollowupType(updateFollowUpDates.type);
      setFollowupData(updateFollowUpDates.type);
    }

  }, [updateFollowUpDates])


  return (
    <div>
      <Row>
        <Col sm="2" style={{ marginTop: '15px' }}>
          <Label className="text-danger" ><b>{rushText}</b> </Label>
        </Col>
      </Row>
      {show &&
        <Row>
          <Col sm="5" style={{ marginTop: '2px', marginLeft: '26px' }}>
            <Label check>
              <Input
                type="checkbox"
                name="readyForBilling"
                id="readyForBilling"
                onChange={readyForBill}
                checked={readyforBilling}
              />
              {' '}
              {'Ready For Billing'}

            </Label>
          </Col>
        </Row>}
      <Row>

        {fromWorklist && <Col sm="3">
          <Label>Follow Up: </Label>{' '}
          <Input
            id="followUpReasonUI"
            type="select"
            placeholder="Select a Followup reason"
            value={followupType}
            onChange={e => followupReasonChange(e.target.value)}
          >
            <option value="" disabled={true}>Select a Followup reason</option>
            {followupData && followupData.followupReasons &&
              followupData.followupReasons.map((item, index) => (
                <option key={index} value={item.chr_description}>{item.chr_description}</option>
              ))}
          </Input>


        </Col>}
        {/* <FormGroup row> */}
        {fromWorklist && <Col sm="1">
          <Label>Follow UP Date: </Label>
          <AppDateInput
            id="FollowupDate"
            onChange={date => handleDateChange(date)}
            selected={followupDate
              ? followupDate
              : new Date()}
          //minDate={moment().toDate()}
          />
        </Col>}
        {fromWorklist && <Col sm="1" style={{ marginTop: '26px' }}>
          <Input
            type="number"
            // min={min}
            // max={max}
            min={1}
            max={12}
            value={Number(fHrs)}
            onChange={(e) => handleHrChange(e)}
          >
          </Input>
        </Col>}
        {fromWorklist && <Col sm="1" style={{ marginTop: '26px' }}>
          <Input
            type="number"
            min={0}
            max={59}
            value={Number(fMin)}
            onChange={(e) => handleMinChange(e)}
          >
          </Input>
        </Col>}
        {fromWorklist && <Col sm="1" style={{ marginTop: '30px' }}> <FormGroup check>
          <Label check>
            <Input type="radio" name="radio2" checked={timeZ === 'AM' ? true : false}
              onChange={() => handleRadioChange('AM')} />  AM </Label>
        </FormGroup></Col>}
        {fromWorklist && <Col sm="1" style={{ marginTop: '30px', marginLeft: '-70px' }}>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio2" checked={timeZ === 'PM' ? true : false}
                onChange={() => handleRadioChange('PM')} /> PM
            </Label>
          </FormGroup>
        </Col>}
        {/* </FormGroup> */}
        {fromWorklist && <Col sm={fromWorklist ? "1" : "3"}>
          <Label>Outcome: </Label>{' '}
          <Input
            id="outcomeUI"
            type="select"
            onChange={e => onChangeOutcome(e)}
            option={{
              value: 'status_id',
              displayValue: 'value',
            }}
            placeholder="status.."
            value={selectedItem.outcomeId}
            data={outcomes}
            disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}
          >
            <option value="default">Status...</option>
            {outcomes.map((id, index) => (
              <option key={index} value={id.status_id}>
                {id.value}
              </option>
            ))}
          </Input>
        </Col>}
        {fromWorklist && <Col sm={fromWorklist ? "1" : "3"}>
          <Label>Reason: </Label>
          <Input id="Reason US"
            type="select"
            onChange={e => onChangeReason(e)}
            option={{
              value: 'status_id',
              displayValue: 'value',
            }}
            placeholder="Reason"
            value={selectedItem.reasonId || ""}
            data={reasonList}
            disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}
          >
            <option value="default">Reason</option>
            {reasonList.map((id, index) => (
              <option key={index} value={id.status_id}>
                {id.value}
              </option>
            ))}
          </Input>
        </Col>}
        {fromWorklist && <Col sm={fromWorklist ? "2" : "3"} style={{ marginTop: '26px' }}>
          <Label>Assigned User: {assignedUser} </Label>
        </Col>}
      </Row>
      <br></br>
      <Row>
        <Col>
          <Button type="button" style={renderButtonColor(1)} onClick={() => openTaskProgress(1)} 
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            New Order Received <br />
            {getCompletedDateTime(0)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(2)} onClick={() => openTaskProgress(2)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Intro email to Client <br />
            {getCompletedDateTime(1)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(3)} onClick={() => openTaskProgress(3)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Initial Patient Contact <br />
            {getCompletedDateTime(2)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(4)} onClick={() => openTaskProgress(4)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Identify provider <br />
            {getCompletedDateTime(3)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(5)} onClick={() => openTaskProgress(5)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Obtain written auth <br />
            {getCompletedDateTime(4)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(6)} onClick={() => openTaskProgress(6)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Submit auth to provider <br />
            {getCompletedDateTime(5)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(7)} onClick={() => openTaskProgress(7)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Confirm w Client & Patient <br />
            {getCompletedDateTime(6)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(8)} onClick={() => openTaskProgress(8)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Reminder call to Provider & Patient <br />
            {getCompletedDateTime(7)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(9)} onClick={() => openTaskProgress(9)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Verify services rendered <br />
            {getCompletedDateTime(8)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(10)} onClick={() => openTaskProgress(10)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Attach notes, inv, to billing <br />
            {getCompletedDateTime(9)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(11)} onClick={() => openTaskProgress(11)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Unbilled <br />
            {getCompletedDateTime(10)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(12)} onClick={() => openTaskProgress(12)}
          disabled={selectedItem.outcomeId === 52 || selectedItem.outcomeId === 1050}>
            Billed <br />
            {getCompletedDateTime(11)}
          </Button>
        </Col>
        <Col>
          <Button type="button" style={renderButtonColor(13)} onClick={() => openTaskProgress(13)}>
            Closed <br />
            {getCompletedDateTime(12)}
          </Button>
        </Col>
      </Row>


      <TaskProgressModal
        isOpen={openTaskModal}
        onClose={openSaveTaskProgress}
        currentTaskData={currentTaskData}
        selectedTaskData={currentTaskData[currentTaskId /*+ taskIncrement*/ - 1]}
        groupProgressData={groupProgressData}
        currentTaskId={currentTaskId /*+ taskIncrement*/}
        currentUserId={currentUserId}
        worklistData={worklistData}
        selectedItem={selectedItem}
        currentOutcomeDesc={currentOutcomeDesc}
        followupReasonChange={followupReasonChange}
        followupData={followupData}
        followupType={followupType}
        handleRadioChange={handleRadioChange}
        handleDateChange={handleDateChange}
        handleHrChange={handleHrChange}
        handleMinChange={handleMinChange}
        followupDate={followupDate}
        fHrs={fHrs}
        fMin={fMin}
        timeZ={timeZ}
        lmsUserId={lmsUserId}


      />
      {openResendWCEDI && <ResendWCEDIModal
        modalOpen={openResendWCEDI}
        onClose={resendWCEDIModalOnClose}
        resendWCEDI={resendWCEDI}
      />
      }
      <Fast360SaveTask

        services={selectedServices}
        isOpen={openSaveTaskModal}
        onClose={openSaveTaskProgress}
        groupProgressData={groupProgressData}
        currentTaskId={currentTaskId /*+ taskIncrement*/}
        currentTaskData={currentTaskData[currentTaskId /*+ taskIncrement*/ - 1]}
        worklistData={worklistData}
        currentUserId={currentUserId}

      //selectedTaskData = {currentTaskData[currentTaskId - 1]}
      />

      <Fast360Reason isOpen={openFast360Reason} reasonList={reasonList} setOpen={setOpenFast360Reason} selectedOutcome={intCurrentOutcome} propsPayload={data} onChangeOutcomeDefault={onChangeOutcomeDefault}
        worklistData={data} onWorklistExit={onWorklistExit} refreshCall={refreshCall} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
    </div>);
};

Fast360SaveTask.propTypes = {
  loadExistingTasks: PropTypes.func,
  groupTasksProgression: PropTypes.func,
}


const mapStateToProps = state => {
  // const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    followUpData: state.FAST360.fast360Reducer.followupData,
    updateFollowUpDates: state.FAST360.fast360Reducer.updateFollowUpDates,
    lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    currentTaskData: state.FAST360.fast360Reducer.updateloadExistingTasks ? state.FAST360.fast360Reducer.updateloadExistingTasks : [],
    currentTaskId: state.FAST360.fast360Reducer.updateloadCurrentTaskId ? state.FAST360.fast360Reducer.updateloadCurrentTaskId : [],
    alertData: state.FAST360.fast360Reducer.updateloadReferralAlerts ? state.FAST360.fast360Reducer.updateloadReferralAlerts : [],
    groupProgressData: state.FAST360.fast360Reducer.updateloadGroupProgression ? state.FAST360.fast360Reducer.updateloadGroupProgression : [],
    followupData: state.FAST360.fast360Reducer.followupData ? state.FAST360.fast360Reducer.followupData : {},
    outcomes: state.FAST360.initialData.outcomes ? state.FAST360.initialData.outcomes : [],
    cmbBillReason: state.FAST360.initialData.cmbBillReason ? state.FAST360.initialData.cmbBillReason : [],
    cmbAltBillReason: state.FAST360.initialData.cmbAltBillReason ? state.FAST360.initialData.cmbAltBillReason : [],
    cmbMissedBillReason: state.FAST360.initialData.cmbMissedBillReason ? state.FAST360.initialData.cmbMissedBillReason : [],
    alertData: state.FAST360.fast360Reducer.updateloadReferralAlerts
      ? state.FAST360.fast360Reducer.updateloadReferralAlerts
      : [],
    currentWorkList: fast360Info.currentWorkListItem,

  };
};

const mapDispatchToProps = dispatch => ({
  loadExistingTasks: (data) => dispatch(fast360Operations.loadExistingTasks(data)),
  getCurrentTaskId: (data) => dispatch(fast360Operations.getCurrentTaskId(data)),
  callDynamicLoad:(data) => dispatch(fast360Operations.callDynamicLoad(data)),
  groupTasksProgression: (data) => dispatch(fast360Operations.groupTasksProgression(data)),
  updateGetFollowupData: (data) => dispatch(fast360Actions.updateGetFollowupData(data)),
  followupChangeEvent: (data) => dispatch(fast360Actions.followupChangeEvent(data)),
  followupDateEvent: (data) => dispatch(fast360Actions.followupDateEvent(data)),
  saveFollowUp: data => dispatch(fast360Operations.saveFollowUp(data)),
  setServiceForWCEDI: data => dispatch(fast360Operations.setServiceForWCEDI(data)),

});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360EditButtons);
