import React, { useEffect, useState } from 'react';

const ClientContactsTab = ({
}) => {

    return (
        <div>
            Contacts!!!
        </div>
    )
}

export default ClientContactsTab;